import React, { PropsWithChildren, Reducer, useReducer } from "react";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import {
  IDialogProps,
  getUTCDateValue,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  roundToNearestInterval,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { ServiceTransactionRow } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import ManageCareInterventionSupport from "../CareInterventionSupport/ManageCareInterventionSupport";
import AddShiftActivityFormContent from "../CareTask/AddShiftActivityFormContent";
import AddShiftFormContent from "../CareTask/AddShiftFormContent";
import { RAFButtonConstant } from "../../../constants/Common/Constants";
import { RAFServiceTransactionType } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";

const careShiftHeaderTab: {
  title: string;
  id: string;
}[] = [
  {
    title: "From Support Plan",
    id: "FromSupportPlan",
  },
  {
    title: "Generic Routines",
    id: "GenericRoutines",
  },
];

interface IProps {
  careRecipientUID: string;
  careRecipientName: string;
  careShiftLogs: any[];
  allForms: FormLibraryRow[];
  formLibrary: FormLibraryRow;
  selectedServiceTransactionRow: ServiceTransactionRow;
  selectedCalenderStartTime?: Date;
}

interface IState {
  showAddActivityBtn: boolean;
  showHeaderCareShiftTab: boolean;
  selectedHeaderTabValue: "GenericRoutines" | "FromSupportPlan";
  selectedFormType: "Activity" | "Form";
}

function AddCareShiftLog({
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showAddActivityBtn: true,
      showHeaderCareShiftTab: true,
      selectedHeaderTabValue: "FromSupportPlan",
      selectedFormType: "Form",
    }
  );

  const onCloseDialogClick = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const onHeaderTabSelected = (selectedHeaderTabValue) => {
    setState({ selectedHeaderTabValue });
  };

  const onGenericTabSelected = (
    selectedFormType?: "Activity" | "Form",
    selectedHeaderTabValue?: "GenericRoutines" | "FromSupportPlan"
  ) => {
    setState({
      selectedFormType,
      selectedHeaderTabValue,
      showHeaderCareShiftTab: true,
    });
  };

  const onUpdateActiveTabIndex = (activeTabIndex: number) => {
    if (activeTabIndex === 0) {
      setState({ showHeaderCareShiftTab: true, showAddActivityBtn: true });
    } else {
      setState({ showHeaderCareShiftTab: false, showAddActivityBtn: false });
    }
  };

  function manageActivityContent() {
    const shiftActivityInitialValues = getShiftActivityInitialValues();
    return (
      <ManageCareInterventionSupport
        serviceTransactionRow={props.selectedServiceTransactionRow}
        onSave={props.onSave.bind(this)}
        onClose={props.onClose.bind(this)}
        onUpdateActiveTabIndex={onUpdateActiveTabIndex}
        shiftActivityFormValue={shiftActivityInitialValues}
        allForms={props.allForms}
        formLibrary={props.formLibrary}
        isActive
      />
    );
  }

  const getShiftFormInitialValues = () => {
    const {
      formLibrary,
      allForms,
      careRecipientUID,
      selectedServiceTransactionRow,
    } = props;
    let initialValues = {};

    if (
      isNotNullAndUndefined(careRecipientUID) &&
      isNotNullAndUndefined(props.careRecipientName)
    ) {
      initialValues["CareRecipientUID"] = careRecipientUID;
      initialValues["CareRecipient"] = props.careRecipientName;
      //initialValues["StartDate"] = careEventRow.TaskDate;
      // initialValues["StartTime"] = careEventRow.TaskDate;
      // initialValues["EndTime"] = careEventRow.EndDate;
      initialValues["Task"] = selectedServiceTransactionRow.Title;
      initialValues["TaskUID"] = selectedServiceTransactionRow.UID;
      initialValues["Assignee"] = selectedServiceTransactionRow.Assignee;
      initialValues["AssigneeUID"] = selectedServiceTransactionRow.AssigneeUID;
      initialValues["FormLibrary"] =
        isNotNullAndUndefined(formLibrary) && formLibrary.Title;
      initialValues["FormLibraryUID"] =
        isNotNullAndUndefined(formLibrary) && formLibrary.UID;
      initialValues["Status"] = "Done";
      initialValues["StartDate"] =
        props.selectedCalenderStartTime ??
        selectedServiceTransactionRow.StartDate;

      // const lastCompletedItem = getLastCompletedShiftItem(
      //     props.careShiftLogs
      // );
      // if (
      //     isNotNullAndUndefined(lastCompletedItem) &&
      //     isNotNullAndUndefined(lastCompletedItem.EndDate)
      // ) {
      //     const startDate = lastCompletedItem.EndDate;
      //     const endNext1Hr = new Date(startDate);
      //     endNext1Hr.setHours(endNext1Hr.getHours() + 1);

      //     const endDate = isNotNullAndUndefined(selectedServiceTransactionRow.EndDate)
      //         ? new Date(selectedServiceTransactionRow.EndDate)
      //         : //? new Date(careEventRow.EndDate + "Z")
      //         null;

      //     const endDateTime =
      //         isNotNullAndUndefined(endDate) &&
      //             endNext1Hr.getTime() < endDate.getTime()
      //             ? endNext1Hr
      //             : endDate;

      //     initialValues["StartDate"] = lastCompletedItem.EndDate;
      //     //initialValues["EndDate"] = endDateTime;
      //     // initialValues["Duration"] = getTimeDifferenceInMinutes(
      //     //   lastCompletedItem.EndDate,
      //     //   endDateTime
      //     // );
      // }
    }

    const minTimeValue = isNotNullAndUndefined(initialValues)
      ? getUTCDateValue(initialValues["StartDate"])
      : null;
    const maxTimeValue = isNotNullAndUndefined(initialValues)
      ? getUTCDateValue(initialValues["EndDate"])
      : null;

    const moduleName = CareEsioEntity.CareShiftActivity.EntityName;
    let filteredForms: FormLibraryRow[] = [];
    if (isNotEmptyArray(allForms)) {
      filteredForms = allForms.filter(
        (x) =>
          x.Entity !== CareEsioEntity.ClientNote.EntityName &&
          x.Entity !== CareEsioEntity.CareCompleteShift.EntityName
        //x.Entity !== "suggestion_form" &&
        //x.Entity !== "shift_log_form" &&
        //x.Entity !== "concern_form"
      );
      let shiftActivityIndex = filteredForms.findIndex(
        (x) => x.Entity === moduleName
      );

      if (shiftActivityIndex !== -1) {
        let shiftActivity = filteredForms.splice(shiftActivityIndex, 1)[0];
        filteredForms.unshift(shiftActivity);
      }
    }

    return initialValues;
  };

  const getShiftActivityInitialValues = () => {
    const {
      formLibrary,
      allForms,
      careRecipientUID,
      selectedServiceTransactionRow,
    } = props;
    let initialValues = {};
    if (
      isNotNullAndUndefined(careRecipientUID) &&
      isNotNullAndUndefined(props.careRecipientName)
    ) {
      initialValues["care_recipientuid"] = careRecipientUID;
      initialValues["care_recipient"] = props.careRecipientName;
      //initialValues["StartDate"] = careEventRow.TaskDate;
      // initialValues["StartTime"] = careEventRow.TaskDate;
      // initialValues["EndTime"] = careEventRow.EndDate;
      initialValues["task"] = selectedServiceTransactionRow.Title;
      initialValues["taskuid"] = selectedServiceTransactionRow.UID;
      initialValues["assignee"] = selectedServiceTransactionRow.Assignee;
      initialValues["assigneeuid"] = selectedServiceTransactionRow.AssigneeUID;
      initialValues["form_library"] =
        isNotNullAndUndefined(formLibrary) && formLibrary.Title;
      initialValues["form_libraryuid"] =
        isNotNullAndUndefined(formLibrary) && formLibrary.UID;
      //initialValues["status"] = "Done";
      initialValues["start_date"] =
        props.selectedCalenderStartTime ??
        selectedServiceTransactionRow.StartDate;

      // const lastCompletedItem = getLastCompletedShiftItem(
      //     props.careShiftLogs
      // );
      // if (
      //     isNotNullAndUndefined(lastCompletedItem) &&
      //     isNotNullAndUndefined(lastCompletedItem.EndDate)
      // ) {
      //     const startDate = lastCompletedItem.EndDate;
      //     const endNext1Hr = new Date(startDate);
      //     endNext1Hr.setHours(endNext1Hr.getHours() + 1);

      //     // const endDate = isNotNullAndUndefined(selectedServiceTransactionRow.EndDate)
      //     //     ? new Date(selectedServiceTransactionRow.EndDate)
      //     //     : //? new Date(careEventRow.EndDate + "Z")
      //     //     null;

      //     // const endDateTime =
      //     //     isNotNullAndUndefined(endDate) &&
      //     //         endNext1Hr.getTime() < endDate.getTime()
      //     //         ? endNext1Hr
      //     //         : endDate;

      //     //initialValues["start_date"] = lastCompletedItem.EndDate;
      //     //initialValues["EndDate"] = endDateTime;
      //     // initialValues["Duration"] = getTimeDifferenceInMinutes(
      //     //   lastCompletedItem.EndDate,
      //     //   endDateTime
      //     // );
      // }
    }

    const moduleName = CareEsioEntity.CareShiftActivity.EntityName;
    let filteredForms: FormLibraryRow[] = [];
    if (isNotEmptyArray(allForms)) {
      filteredForms = allForms.filter(
        (x) =>
          x.Entity !== CareEsioEntity.ClientNote.EntityName &&
          x.Entity !== CareEsioEntity.CareCompleteShift.EntityName
        //x.Entity !== "suggestion_form" &&
        //x.Entity !== "shift_log_form" &&
        //x.Entity !== "concern_form"
      );
      let shiftActivityIndex = filteredForms.findIndex(
        (x) => x.Entity === moduleName
      );

      if (shiftActivityIndex !== -1) {
        let shiftActivity = filteredForms.splice(shiftActivityIndex, 1)[0];
        filteredForms.unshift(shiftActivity);
      }
    }
    return initialValues;
  };

  const getAddShiftActivityFormContent = () => {
    const shiftActivityInitialValues = getShiftActivityInitialValues();

    return (
      <AddShiftActivityFormContent
        shiftActivityInitialValues={shiftActivityInitialValues}
        allForms={props.allForms}
        formLibrary={props.formLibrary}
        selectedServiceTransactionRow={props.selectedServiceTransactionRow}
        careRecipientUID={props.careRecipientUID}
        onUpdateActiveTabIndex={onUpdateActiveTabIndex}
        isActive
        {...props}
      />
    );
  };

  const getAddShiftFormContent = () => {
    const shiftFormInitialValues = getShiftFormInitialValues();
    const minTimeValue = isNotNullAndUndefined(shiftFormInitialValues)
      ? getUTCDateValue(shiftFormInitialValues["StartDate"])
      : null;
    const maxTimeValue = isNotNullAndUndefined(shiftFormInitialValues)
      ? getUTCDateValue(shiftFormInitialValues["EndDate"])
      : null;

    return (
      <AddShiftFormContent
        shiftFormInitialValues={shiftFormInitialValues}
        careRecipientUID={props.careRecipientUID}
        allForms={props.allForms}
        selectedServiceTransactionRow={props.selectedServiceTransactionRow}
        minTimeValue={minTimeValue}
        maxTimeValue={maxTimeValue}
        onUpdateActiveTabIndex={onUpdateActiveTabIndex}
        onSelectRoutine={(selectedType) =>
          onGenericTabSelected("Activity", selectedType)
        }
        isActive
        showDialogHeader={true}
        {...props}
      />
    );
  };

  let headerClass = "e-dlg-headerContent h-auto px-3";
  if (state.selectedFormType === "Activity") {
    if (state.showHeaderCareShiftTab) {
      headerClass = "px-3 tab_fixedHeader_scrollableContent_dialog_header";
    }
  }

  const shiftLogHeaderTemplate = () => {
    return (
      <div className={headerClass}>
        <div className="d-flex align-items-center justify-content-between w-100">
          {(state.selectedFormType === "Activity" ||
            state.selectedFormType === "Form") && (
            <RAFButtonComponent
              displayMode="DialogCloseBtn"
              iconCss={RAFButtonConstant.ArrowBack.IconCss}
              className="raf_sm"
              onClick={() => onGenericTabSelected("Form")}
            ></RAFButtonComponent>
          )}

          {state.selectedFormType === "Activity" &&
          state.showHeaderCareShiftTab ? (
            <div id="raf_user_feeds_page_tab_header_container">
              <div
                id="user_feeds_page_tab_header"
                className="raf_tab_header hideScrollBar raf_lg"
              >
                {isNotEmptyArray(careShiftHeaderTab) &&
                  careShiftHeaderTab.map((item, index) => {
                    return (
                      <div
                        className={`raf_tab_header_item${
                          (isNotNullAndUndefined(
                            state.selectedHeaderTabValue
                          ) &&
                            state.selectedHeaderTabValue === item.id) ||
                          (isNullOrUndefined(state.selectedHeaderTabValue) &&
                            index === 0)
                            ? " active"
                            : ""
                        }`}
                        key={item.id}
                        onClick={() => onHeaderTabSelected(item.id)}
                      >
                        <span>{item.title}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <div className={"e-dlg-header ms-3"}>
              {props.selectedServiceTransactionRow.Type ===
              RAFServiceTransactionType.Booking
                ? "Log Activity"
                : "Add Shift Log"}
            </div>
          )}
          <div>
            <RAFButtonComponent
              displayMode="DialogCloseBtn"
              onClick={() => onCloseDialogClick()}
              className="raf_sm"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={"dlg-new-style"}>
      {state.selectedFormType === "Form" ? (
        getAddShiftFormContent()
      ) : (
        <>
          {shiftLogHeaderTemplate()}
          {state.selectedFormType === "Activity" && (
            <div className="custom__card__separator custom__card__separator__light"></div>
          )}
          {state.selectedFormType === "Activity" && (
            <>
              {state.selectedHeaderTabValue === "GenericRoutines" &&
                getAddShiftActivityFormContent()}
              {state.selectedHeaderTabValue === "FromSupportPlan" &&
                manageActivityContent()}
            </>
          )}
        </>
      )}
      {/* {state.selectedFormType === "Form" && getAddShiftFormContent()} */}
    </div>
  );
}

export default React.memo(AddCareShiftLog);
