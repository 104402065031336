import { DialogUtility } from "@syncfusion/ej2-popups";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TabComponent } from "@syncfusion/ej2-react-navigations";
import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { FormRenderProps } from "react-final-form";
import { ITheme, Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import { msalInstance } from "../../..";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFHtmlEditor from "../../../RAFComponents/Inputs/RAFHtmlEditor";
import RAFImageFileAttachmentInput from "../../../RAFComponents/Inputs/RAFImageFileAttachmentInput";
import RAFStartDateEndDatePicker from "../../../RAFComponents/Inputs/RAFStartDateEndDatePicker";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  IsSuperAdmin,
  IsSuperAdminQueryString,
  RAFActionMessage,
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import { hasPermission } from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  getSaveRequest,
  getTimeDifferenceInMinutes,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFUIType } from "../../../RAFComponents/models/Common/RAFDataType";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { customTheme } from "../../../RAFMaster/RMModules/FormLibrary/components/custom-theme";
import {
  EvaluateScore,
  getPredefinedURLAndUplodToS3,
  getUpdatedFormDataModel,
  uploadFileAndGetFormData,
} from "../../../RAFMaster/helpers/RMutils";
import ACAutoCompleteMUI from "../../../components/shared/ACFormFields/ACAutoCompleteMUI";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { ServiceTransactionPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  Constants,
  ContentType,
  RAFActionStatus,
  RAFButtonConstant,
  RAFLayout,
  ESIOSuperAdministrator,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import CreateActivitySummary from "../../ActiveContacts/Activity/Controller/CreateActivitySummary";
import {
  DocumentImageAttachmentRow,
  getImageBlobFileAttachment,
  uploadImageAttachment,
} from "../../ActiveContacts/Document/Library/DocumentHelper";
import {
  RAFServiceTransactionType,
  checkHasPermissionToAddOrEditActivitylog,
} from "../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import { ServiceTransactionRow } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import RAFCreate from "../../Common/Create";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../Common/Providers/RAFObjectContextProvider";
import EditContent from "../../Common/Update/EditContent";
import { getUserTenantSettings } from "../../Common/TenantSettings/TenantSettingsHelper";
import { TenantSettingsRow } from "../../Common/TenantSettings/TenantSettingsRow";
import { SaveShiftActivity } from "./CareShiftLogHelper";

interface IProps {
  moduleName?: string;
  onSave?: (entityId?: string, objectName?: string) => void;
  onClose?: () => void;
  onDelete?: () => void;
  objectUID?: string;
  isActive?: boolean;
  allForms?: FormLibraryRow[];

  selectedServiceTransactionRow?: ServiceTransactionRow;

  minTimeValue?: Date;
  maxTimeValue?: Date;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  careShiftLogRow: any;
  selectedTabItem: "Details" | "Discussion" | "Attachments";
  initialFileAttchements: DocumentImageAttachmentRow[];
  tenantSettings: TenantSettingsRow;
}

function UpdateCareShiftLog({
  objectUID,
  allForms,
  ...props
}: PropsWithChildren<IProps & RAFFormComponentProps & IDialogProps>) {
  let rafForm: FormRenderProps | null;
  let submitButtonRef = useRef(null);
  let deleteDialog: any;
  // let objectGUID = useRecoilValue(atomEditingRecordId(moduleName));
  // objectUID = isNotNullAndUndefined(objectGUID) ? objectGUID : objectUID;
  const tempFileStorage = useRef({});

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: true,
      careShiftLogRow: null,
      selectedTabItem: "Details",
      initialFileAttchements: null,
      tenantSettings: null,
    }
  );

  const moduleName =
    isNotNullAndUndefined(state.careShiftLogRow) &&
    isNotNullAndUndefined(state.careShiftLogRow.log_type)
      ? state.careShiftLogRow.log_type
      : CareEsioEntity.CareShiftLog.EntityName;

  let selectedFormLibrary: FormLibraryRow = null;
  if (
    isNotEmptyArray(allForms) &&
    isNotNullAndUndefined(state.careShiftLogRow) &&
    isNotNullAndUndefined(state.careShiftLogRow.log_type)
  ) {
    selectedFormLibrary = allForms.find(
      (x) => x.Entity === state.careShiftLogRow.log_type
    );
  }

  useEffect(() => {
    refresh();
  }, [objectUID]);

  const refresh = async () => {
    if (props.isActive) {
      setState({ isLoading: true });

      const [careShiftLogRow, tenantSettings] = await Promise.all([
        RetrieveRecord(objectUID, CareEsioEntity.CareShiftLog.EntityName),
        getUserTenantSettings(),
      ]);

      let selectedFormLibrary: FormLibraryRow = null;
      if (
        isNotEmptyArray(allForms) &&
        isNotNullAndUndefined(careShiftLogRow) &&
        isNotNullAndUndefined(careShiftLogRow.log_type)
      ) {
        selectedFormLibrary = allForms.find(
          (x) => x.Entity === careShiftLogRow.log_type
        );
      }

      let hasCustomForm = false;
      if (
        isNotNullAndUndefined(selectedFormLibrary) &&
        isNotNullAndUndefined(selectedFormLibrary.FormStructure)
      ) {
        hasCustomForm = true;
      }
      if (isNotNullAndUndefined(careShiftLogRow)) {
        const hasPermissionToDoShiftActions =
          careShiftLogRow.CreatedByUID === msalInstance.currentUserId ||
          hasPermission(
            permissionValue,
            ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsTeamShiftActions
          )
            ? true
            : false;
        if (!hasPermissionToDoShiftActions) {
          setState({
            isLoading: false,
            careShiftLogRow: null,
            noContent: true,
          });
        } else if (hasCustomForm === false) {
          const imageAttachments = await getImageBlobFileAttachment(
            null,
            null,
            null,
            careShiftLogRow.attachments
          );

          careShiftLogRow.ImageAttachments = imageAttachments;

          setState({
            isLoading: false,
            careShiftLogRow,
            tenantSettings,
            noContent: false,
            initialFileAttchements: imageAttachments,
          });
        } else {
          setState({
            isLoading: false,
            careShiftLogRow,
            tenantSettings,
            noContent: false,
          });
        }
      } else {
        setState({
          isLoading: false,
          careShiftLogRow: null,
          noContent: true,
        });
      }
    }
  };

  const onSubmitObjectOld = (
    values?: any,
    entity?: EntityRow,
    queryAttributes?: QueryAttributeJM[],
    formModel?: any,
    hasCustomForm?: boolean
  ) => {
    const hasPermissionToAddOrEditActivitylog =
      checkHasPermissionToAddOrEditActivitylog(
        state.tenantSettings,
        props.selectedServiceTransactionRow
      );
    if (
      isNotNullAndUndefined(hasPermissionToAddOrEditActivitylog) &&
      !hasPermissionToAddOrEditActivitylog.hasPermissionToEdit
    ) {
      showWarningToast(
        `${hasPermissionToAddOrEditActivitylog.warningMessage}`,
        null,
        5000
      );
      return;
    }

    let updatedValues = R.clone(values);

    if (hasCustomForm) {
      let formModeldata = formModel.data;

      let isFormValid = formModel.validate(true, true);
      if (isFormValid) {
        let progressDiv = showProgress(`#raf_dlg_Outer_Div_${moduleName}`);

        let filesToUpload;
        const questionsToUpload = Object.keys(tempFileStorage.current);
        for (let i = 0; i < questionsToUpload.length; i++) {
          const questionName = questionsToUpload[i];
          filesToUpload = tempFileStorage.current[questionName];
        }

        getUpdatedFormDataModel(
          formModel,
          filesToUpload,
          moduleName,
          questionsToUpload,
          "edit"
        ).then((formData) => {
          updatedValues = { ...updatedValues, ...formData };

          let saveRequestData = getSaveRequest(
            updatedValues,
            updatedValues.UID
          );
          saveRequestData.EntityName = moduleName;

          let url = "DataList/SaveForm";
          SaveShiftActivity(saveRequestData, url).then(async (response) => {
            if (isNotNullAndUndefined(response)) {
              showSuccessToast(RAFActionMessage.RecordSaved);
              hideProgress(progressDiv);
              if (props.onSave)
                props.onSave(response.entityId, response.objectName);
            } else {
              showWarningToast(RAFActionMessage.RecordNotSaved);
              if (props.onClose) props.onClose();
              hideProgress(progressDiv);
            }
          });
        });
      }
    } else {
      const { selectedServiceTransactionRow } = props;
      let progressDiv = showProgress(`#raf_dlg_Outer_Div_${moduleName}`);

      const startDate = updatedValues.StartDate;
      const endDate = updatedValues.EndDate;

      const shiftStartDate = isNotNullAndUndefined(
        selectedServiceTransactionRow
      )
        ? selectedServiceTransactionRow.StartDate
        : null;

      const shiftEndDate = isNotNullAndUndefined(selectedServiceTransactionRow)
        ? selectedServiceTransactionRow.EndDate
        : null;

      // const isValidDateRange = isValidActivityStarEndTimeRange(
      //   shiftStartDate,
      //   shiftEndDate,
      //   startDate,
      //   endDate
      // );

      // if (!isValidDateRange) {
      //   hideProgress(progressDiv);
      //   return;
      // }
      const duration = getTimeDifferenceInMinutes(startDate, endDate);
      updatedValues.Duration = duration;

      if (
        isNotNullAndUndefined(entity) &&
        isNotNullAndUndefined(entity.EntitySettings) &&
        isNotNullAndUndefined(entity.EntitySettings.EnableScore) &&
        entity.EntitySettings.EnableScore === true
      ) {
        updatedValues = EvaluateScore(values, queryAttributes);
      }

      const imageAttachments: DocumentImageAttachmentRow[] =
        updatedValues.ImageAttachments;
      const attachments: LookUpRow[] = isNotEmptyArray(imageAttachments)
        ? imageAttachments.map((item) => {
            return {
              UID: item.UID,
              Value: item.Name,
            };
          })
        : [];
      updatedValues.Attachments = attachments;
      delete updatedValues.ImageAttachments;

      let saveRequestData = getSaveRequest(updatedValues, updatedValues.UID);
      saveRequestData.EntityName = moduleName;

      repositoryActions
        .postDataAndGetResponse(
          "Datalist/SaveSubForms",
          saveRequestData,
          null,
          ContentType.applicationJson
        )
        .then(async (response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            const onUploadAttachment = await uploadImageAttachment(
              imageAttachments,
              state.initialFileAttchements,
              response.data.EntityId,
              response.data.ObjectName,
              isNotNullAndUndefined(updatedValues.log_type)
                ? updatedValues.log_type
                : CareEsioEntity.CareShiftLog.EntityName,
              updatedValues.CareRecipientUID,
              updatedValues.CareRecipient,
              CareEsioEntity.CareRecipient.EntityName
            );

            hideProgress(progressDiv);
            showSuccessToast(RAFActionMessage.RecordSaved);
            if (props.onSave)
              props.onSave(response.data.EntityId, response.data.ObjectName);
          } else {
            hideProgress(progressDiv);
            if (props.onClose) props.onClose();
            showWarningToast(RAFActionMessage.RecordNotSaved);
          }
        })
        .catch((error) => error);
    }
  };

  const onSubmitObject = (
    values?: any,
    entity?: EntityRow,
    queryAttributes?: QueryAttributeJM[],
    formModelData?: any,
    hasCustomForm?: boolean
  ) => {
    const hasPermissionToAddOrEditActivitylog =
      checkHasPermissionToAddOrEditActivitylog(
        state.tenantSettings,
        props.selectedServiceTransactionRow
      );
    if (
      isNotNullAndUndefined(hasPermissionToAddOrEditActivitylog) &&
      !hasPermissionToAddOrEditActivitylog.hasPermissionToEdit
    ) {
      showWarningToast(
        `${hasPermissionToAddOrEditActivitylog.warningMessage}`,
        null,
        5000
      );
      return;
    }

    let updatedValues = R.clone(values);
    let { careShiftLogRow } = state;
    if (hasCustomForm) {
      const formDataValue = formModelData.data;

      let isFormValid = formModelData.validate(true, true);
      if (isFormValid) {
        let progressDiv = showProgress(`#raf_dlg_Outer_Div_${moduleName}`);

        //commented for use s3 upload by hari
        //uploadFileAndGetFormData(formModelData, moduleName, "edit").then(
        getPredefinedURLAndUplodToS3(
          formModelData,
          moduleName,
          "edit",
          careShiftLogRow
        ).then((formData) => {
          updatedValues = { ...updatedValues, ...formData };

          let saveRequestData = getSaveRequest(
            updatedValues,
            updatedValues.UID
          );
          saveRequestData.EntityName = moduleName;

          let url = "DataList/SaveForm";
          SaveShiftActivity(saveRequestData, url).then(async (response) => {
            if (isNotNullAndUndefined(response)) {
              showSuccessToast(RAFActionMessage.RecordSaved);
              hideProgress(progressDiv);
              if (props.onSave)
                props.onSave(response.entityId, response.objectName);
            } else {
              showWarningToast(RAFActionMessage.RecordNotSaved);
              if (props.onClose) props.onClose();
              hideProgress(progressDiv);
            }
          });
        });
      }
    } else {
      const { selectedServiceTransactionRow } = props;
      let progressDiv = showProgress(`#raf_dlg_Outer_Div_${moduleName}`);

      const startDate = updatedValues.StartDate;
      const endDate = updatedValues.EndDate;

      const shiftStartDate = isNotNullAndUndefined(
        selectedServiceTransactionRow
      )
        ? selectedServiceTransactionRow.StartDate
        : null;

      const shiftEndDate = isNotNullAndUndefined(selectedServiceTransactionRow)
        ? selectedServiceTransactionRow.EndDate
        : null;

      // const isValidDateRange = isValidActivityStarEndTimeRange(
      //   shiftStartDate,
      //   shiftEndDate,
      //   startDate,
      //   endDate
      // );

      // if (!isValidDateRange) {
      //   hideProgress(progressDiv);
      //   return;
      // }
      const duration = getTimeDifferenceInMinutes(startDate, endDate);
      updatedValues.Duration = duration;

      if (
        isNotNullAndUndefined(entity) &&
        isNotNullAndUndefined(entity.EntitySettings) &&
        isNotNullAndUndefined(entity.EntitySettings.EnableScore) &&
        entity.EntitySettings.EnableScore === true
      ) {
        updatedValues = EvaluateScore(values, queryAttributes);
      }

      const imageAttachments: DocumentImageAttachmentRow[] =
        updatedValues.ImageAttachments;
      const attachments: LookUpRow[] = isNotEmptyArray(imageAttachments)
        ? imageAttachments.map((item) => {
            return {
              UID: item.UID,
              Value: item.Name,
            };
          })
        : [];
      updatedValues.Attachments = attachments;
      delete updatedValues.ImageAttachments;

      let saveRequestData = getSaveRequest(updatedValues, updatedValues.UID);
      saveRequestData.EntityName = moduleName;

      repositoryActions
        .postDataAndGetResponse(
          "Datalist/SaveSubForms",
          saveRequestData,
          null,
          ContentType.applicationJson
        )
        .then(async (response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            const onUploadAttachment = await uploadImageAttachment(
              imageAttachments,
              state.initialFileAttchements,
              response.data.EntityId,
              response.data.ObjectName,
              isNotNullAndUndefined(updatedValues.log_type)
                ? updatedValues.log_type
                : CareEsioEntity.CareShiftLog.EntityName,
              updatedValues.CareRecipientUID,
              updatedValues.CareRecipient,
              CareEsioEntity.CareRecipient.EntityName
            );

            hideProgress(progressDiv);
            showSuccessToast(RAFActionMessage.RecordSaved);
            if (props.onSave)
              props.onSave(response.data.EntityId, response.data.ObjectName);
          } else {
            hideProgress(progressDiv);
            if (props.onClose) props.onClose();
            showWarningToast(RAFActionMessage.RecordNotSaved);
          }
        })
        .catch((error) => error);
    }
  };

  const enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (
        isNotNullAndUndefined(submitButtonRef) &&
        isNotNullAndUndefined(submitButtonRef.current) &&
        isNotNullAndUndefined(submitButtonRef.current.toggleBtnPermission)
      ) {
        submitButtonRef.current.toggleBtnPermission(g.submitting);
      }
    }
  };

  //delete item start
  const onClickDeleteCareShiftLog = () => {
    let title = isNotNullAndUndefined(selectedFormLibrary)
      ? selectedFormLibrary.Title
      : CareEsioEntity.CareShiftLog.DisplayName;
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete ${title}`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");
    const hasPermissionToAddOrEditActivitylog =
      checkHasPermissionToAddOrEditActivitylog(
        state.tenantSettings,
        props.selectedServiceTransactionRow
      );
    if (
      isNotNullAndUndefined(hasPermissionToAddOrEditActivitylog) &&
      !hasPermissionToAddOrEditActivitylog.hasPermissionToEdit
    ) {
      showWarningToast(
        `${hasPermissionToAddOrEditActivitylog.warningMessage}`,
        null,
        5000
      );
      deleteDialog.hide();
      return;
    }

    let isDeleted = await DeleteRecord(objectUID, moduleName);

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      if (isNotNullAndUndefined(props.onDelete)) {
        props.onDelete();
      }
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast("Sorry something went wrong !");
    }
  }
  //delete item end

  const onCloseDialogClick = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const TabSelected = (selectedTabName: HTMLElement) => {
    let selectedTitle = selectedTabName.innerText;
    setState({ selectedTabItem: selectedTitle as any });
  };

  const renderTabContent = (
    rafObject: any,
    careShiftStatusQueryAttribute: QueryAttributeJM,
    formModel: any,
    hasCustomForm: boolean
  ) => {
    let selectedTabItem = state.selectedTabItem;
    if (selectedTabItem === "Details") {
      const { selectedServiceTransactionRow } = props;

      const shiftStartDate = isNotNullAndUndefined(
        selectedServiceTransactionRow
      )
        ? selectedServiceTransactionRow.StartDate
        : null;

      const shiftEndDate = isNotNullAndUndefined(selectedServiceTransactionRow)
        ? selectedServiceTransactionRow.EndDate
        : null;

      // set mintime as shift time-2 hrs
      const startTimeMinValue = new Date(shiftStartDate);
      startTimeMinValue.setHours(startTimeMinValue.getHours() - 2);

      // set maxtime as shift time+2 hrs
      const startTimeMaxValue = new Date(shiftEndDate);
      startTimeMaxValue.setHours(startTimeMaxValue.getHours() + 2);

      return (
        <>
          {hasCustomForm ? (
            <>
              <Survey model={formModel} />
            </>
          ) : (
            <div className="p-3">
              <div className="row gx-2 gy-4">
                {moduleName === CareEsioEntity.CareShiftActivity.EntityName && (
                  <>
                    <div className="col-md-12">
                      <RAFStartDateEndDatePicker
                        field="StartDate"
                        endDateField="EndDate"
                        label="Start Time"
                        showLabel
                        startDateUItype={RAFUIType.TimeOnly}
                        endDateUItype={RAFUIType.TimeOnly}
                        updateEndDateOnStartDateChange={false}
                        formGroupClassName="mb-0"
                        hideDuration
                        //displayStyle={"DesktopTimePicker"}
                        //minStartDateValue={startTimeMinValue}
                        // maxStartDateValue={startTimeMaxValue}
                        //minEndDateValue={startTimeMinValue}
                        //  maxEndDateValue={startTimeMaxValue}
                      />
                    </div>
                    <div className="col-md-12">{getTypeActivityDropdown()}</div>
                    <div className="col-md-12">
                      <RAFDropdownCC
                        field="Status"
                        label="Status"
                        placeholder="Select Status"
                        isColorOption
                        // mode="discView"
                        uitype="colorpicker"
                        createOptionMode={"Footer"}
                        moduleName={CareEsioEntity.CareShiftActivity.EntityName}
                        required
                        displayDropdownType={"customDropdownBtnForMinCount"}
                        //onChanged={onChangeTaskType}
                        allowAdd={false}
                        queryAttribute={
                          careShiftStatusQueryAttribute &&
                          careShiftStatusQueryAttribute
                        }
                      />
                    </div>
                    {isNotNullAndUndefined(rafObject) &&
                      isNotNullAndUndefined(rafObject.Instructions) && (
                        <div className="col-md-12">
                          <span className="secondary-header-text">
                            {rafObject.Instructions}
                          </span>
                        </div>
                      )}
                    <div className="col-md-12">
                      {/* <RAFTextArea
                  field="Description"
                  showLabel
                  label="Provide a brief description or observations"
                  placeholder="Description"
                  // rows={2}
                  formGroupClassName="m-0"
                /> */}
                      <RAFHtmlEditor
                        field="Description"
                        label="Provide a brief description or observations"
                        showLabel={true}
                        placeholder="Description"
                        rows={5}
                        fullHeight={false}
                        rowClassName="row g-0 gy-2"
                      />
                    </div>
                  </>
                )}
                <div className="col-md-12">
                  <EditContent
                    moduleName={moduleName}
                    progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                  />
                </div>
                {getImageAttachments()}
              </div>
            </div>
          )}
        </>
      );
    } else if (selectedTabItem === "Discussion") {
      return (
        <div
          style={{
            background: "#fff",
          }}
          className="p-3"
        >
          <CreateActivitySummary
            relatedToUID={rafObject.taskuid}
            relatedTo={rafObject.task}
            moduleName={RAFEntityName.Task}
            secondaryRelatedToUID={rafObject.UID}
            secondaryRelatedTo={rafObject.title}
            secondaryRelatedToType={moduleName}
          />
        </div>
      );
    }
  };

  const getTypeActivityDropdown = () => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];
    let filter: RAFCustomFilter = {};
    let filterVal: string[] = [];
    filterVal.push("Activity");
    filter.Operator = RAFCustomOperator.Equal;
    filter.Value = filterVal;
    filter.Field = "Type";
    customFilter.Rules.push(filter);

    return (
      <ACAutoCompleteMUI
        label="Select the type of activity"
        field="Title"
        placeholder="Select the type of activity"
        showLabel
        //showCreateButton
        url="StandardizedLibrary/LookUp"
        createform={RAFCreate}
        moduleName={RAFEntityName.StandardizedLibrary}
        customFilter={customFilter}
        SearchCreateOptionMode={"Footer"}
        required
        formGroupClassName="mb-0"
      />
    );
  };

  const getImageAttachments = () => {
    return <RAFImageFileAttachmentInput field="ImageAttachments" />;
  };
  if (
    isNotNullAndUndefined(objectUID) &&
    !IsNullOrWhiteSpace(objectUID) &&
    props.isActive
  ) {
    if (state.isLoading === false) {
      if (state.noContent === false) {
        const { careShiftLogRow } = state;
        if (moduleName === CareEsioEntity.CareShiftLog.EntityName) {
          return (
            <div
              className="h-100"
              key={`${objectUID}_${moduleName}`}
              id={`raf_dlg_Outer_Div_${moduleName}`}
            >
              <RAFForm
                initialValues={careShiftLogRow}
                formRef={(g) => {
                  enableSubmitButton(g);
                  return (rafForm = g);
                }}
                layout={RAFLayout.TwoColumnLayout}
                onSubmit={(values) => onSubmitObject(values, null, null)}
                primaryKey={objectUID}
                //decorators={[accountDecorators]}
                //convertBeforeSave={ConvertAccountRowToAccountDto}
                className="h-100"
              >
                <div className="e-dlg-content-outer">
                  <div className="e-dlg-headerContent h-auto tab_fixedHeader_scrollableContent_dialog_header">
                    <div className="e-dlg-header">
                      {props.selectedServiceTransactionRow.Type ===
                      RAFServiceTransactionType.Booking
                        ? "Update Activity"
                        : isNotNullAndUndefined(selectedFormLibrary)
                        ? `Update ${selectedFormLibrary.Title}`
                        : `Update ${CareEsioEntity.CareShiftLog.DisplayName}`}
                    </div>
                    <RAFButtonComponent
                      type="button"
                      className="primary-custom-button"
                      action={RAFButtonConstant.DialogClose}
                      onClick={() => props.onClose()}
                      iconBtn
                    ></RAFButtonComponent>
                  </div>
                  <div className="e-dlg-body-content">
                    <div>
                      <RAFEntityProvider moduleName={moduleName}>
                        <div className="row gx-2 gy-4">
                          {moduleName ===
                            CareEsioEntity.CareShiftActivity.EntityName && (
                            <>
                              <div className="col-md-12">
                                <RAFStartDateEndDatePicker
                                  field="StartDate"
                                  endDateField="EndDate"
                                  label="Start Time"
                                  showLabel
                                  startDateUItype={RAFUIType.TimeOnly}
                                  endDateUItype={RAFUIType.TimeOnly}
                                  updateEndDateOnStartDateChange={false}
                                  formGroupClassName="mb-0"
                                  hideDuration
                                  required
                                />
                              </div>
                              <div className="col-md-12">
                                {getTypeActivityDropdown()}
                              </div>
                              {isNotNullAndUndefined(careShiftLogRow) &&
                                isNotNullAndUndefined(
                                  careShiftLogRow.Instructions
                                ) && (
                                  <div className="col-md-12">
                                    <span className="secondary-header-text">
                                      {careShiftLogRow.Instructions}
                                    </span>
                                  </div>
                                )}
                              <div className="col-md-12">
                                {/* <RAFTextArea
                              field="Description"
                              showLabel
                              label="Provide a brief description or observations"
                              placeholder="Description"
                              // rows={2}
                              formGroupClassName="m-0"
                            />
                             */}
                                <RAFHtmlEditor
                                  field="Description"
                                  label="Provide a brief description or observations"
                                  showLabel={true}
                                  placeholder="Description"
                                  rows={5}
                                  fullHeight={false}
                                  rowClassName="row g-0 gy-2"
                                />
                              </div>
                            </>
                          )}
                          <div className="col-md-12">
                            <EditContent
                              moduleName={moduleName}
                              progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                            />
                          </div>
                          {getImageAttachments()}
                        </div>
                      </RAFEntityProvider>
                    </div>
                  </div>
                  <div className="e-dlg-footerContent">
                    <div className="w-100">
                      <div className="row gx-2">
                        {isNotNullAndUndefined(props.onDelete) ? (
                          <div className="col-auto">
                            <RAFButtonComponent
                              action={RAFButtonConstant.Delete}
                              onClick={() => onClickDeleteCareShiftLog()}
                              idString="DeleteContent"
                              className="e-danger e-outline"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-auto ms-auto">
                          <RAFButtonComponent
                            ref={submitButtonRef}
                            isPrimary
                            action={RAFButtonConstant.Save}
                            onClick={() => rafForm && rafForm.form.submit()}
                            idString="EditContent"
                            disabled={rafForm && rafForm.submitting}
                          />
                        </div>
                        <div className="col-auto">
                          <RAFButtonComponent
                            action={RAFButtonConstant.Cancel}
                            onClick={props.onClose}
                            idString="EditContent"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </RAFForm>
            </div>
          );
        } else {
          return (
            <div
              className="h-100"
              key={`${objectUID}_${moduleName}`}
              id={`raf_dlg_Outer_Div_${moduleName}`}
            >
              <RAFEntityProvider moduleName={moduleName}>
                <RAFAttributeRelatedListProvider moduleName={moduleName}>
                  <RAFAttributesContext.Consumer>
                    {({ queryAttributes }) => {
                      return (
                        <RAFEntityContext.Consumer>
                          {({ entity, formLibrary }) => {
                            let tabMenuItems = [
                              {
                                id: 0,
                                text: "Details",
                              },
                            ];
                            if (entity.EntitySettings.EnableComment) {
                              tabMenuItems.push({
                                id: 1,
                                text: "Discussion",
                              });
                            }

                            const selectedTabItemIndex = isNotNullAndUndefined(
                              state.selectedTabItem
                            )
                              ? tabMenuItems.findIndex(
                                  (x) => x.text === state.selectedTabItem
                                )
                              : 0;

                            let hasCustomForm = false;
                            if (
                              isNotNullAndUndefined(formLibrary) &&
                              isNotNullAndUndefined(formLibrary.FormStructure)
                            ) {
                              hasCustomForm = true;
                            }
                            let formModel = new Model(
                              formLibrary?.FormStructure
                            );

                            formModel.applyTheme(customTheme as ITheme);
                            let nextButton =
                              formModel.navigationBar.getActionById(
                                "sv-nav-next"
                              );
                            let prevButton =
                              formModel.navigationBar.getActionById(
                                "sv-nav-prev"
                              );
                            let completeButton =
                              formModel.navigationBar.getActionById(
                                "sv-nav-complete"
                              );
                            nextButton.visible = false;
                            prevButton.visible = false;
                            completeButton.visible = false;

                            formModel.onErrorCustomText.add(function (
                              sender,
                              options
                            ) {
                              if (options.name == "required") {
                                //options.text = options.obj.title + " is required";
                                options.text = "This field is required";
                              }
                            });

                            return (
                              <RAFObjectContextProvider
                                moduleName={moduleName}
                                objectId={objectUID}
                                progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                              >
                                <RAFObjectContext.Consumer>
                                  {({
                                    /*objectId, */ rafObject,
                                    error,
                                    saveObject,
                                  }) => {
                                    if (
                                      isNotNullAndUndefined(rafObject) &&
                                      !IsNullOrWhiteSpace(rafObject.UID)
                                    ) {
                                      let queryAttributeClone: QueryAttributeJM[] =
                                        R.clone(queryAttributes);
                                      let careShiftStatusQueryAttribute =
                                        queryAttributeClone &&
                                        queryAttributeClone.find(
                                          (x) => x.AttributeName === "status"
                                        );
                                      if (
                                        careShiftStatusQueryAttribute &&
                                        isNotEmptyArray(
                                          careShiftStatusQueryAttribute.ValueJson
                                        )
                                      ) {
                                        careShiftStatusQueryAttribute.ValueJson =
                                          careShiftStatusQueryAttribute.ValueJson.filter(
                                            (x) =>
                                              x.DisplayName ===
                                                RAFActionStatus.Done ||
                                              x.DisplayName ===
                                                RAFActionStatus.NotDone ||
                                              x.DisplayName ===
                                                RAFActionStatus.NoTime ||
                                              x.DisplayName ===
                                                RAFActionStatus.Refused
                                          );
                                      }
                                      if (
                                        rafObject.status ===
                                        RAFActionStatus.Planned
                                      ) {
                                        rafObject.status = RAFActionStatus.Done;
                                      }

                                      if (
                                        isNotEmptyArray(
                                          state.initialFileAttchements
                                        )
                                      ) {
                                        rafObject.ImageAttachments = [
                                          ...state.initialFileAttchements,
                                        ];
                                      }

                                      formModel.data = rafObject;

                                      let isSuperAdminQueryString =
                                        IsSuperAdminQueryString();
                                      let isSuperAdmin = IsSuperAdmin();
                                      if (
                                        selectedFormLibrary.Entity ===
                                          "incident" &&
                                        (isSuperAdmin ||
                                          isSuperAdminQueryString)
                                      ) {
                                        formModel.setValue(
                                          "role_permission_name",
                                          ESIOSuperAdministrator
                                        );
                                      }

                                      return (
                                        <RAFForm
                                          initialValues={rafObject}
                                          formRef={(g) => {
                                            enableSubmitButton(g);
                                            return (rafForm = g);
                                          }}
                                          layout={RAFLayout.TwoColumnLayout}
                                          onSubmit={(values) =>
                                            onSubmitObject(
                                              values,
                                              entity,
                                              queryAttributes,
                                              formModel,
                                              hasCustomForm
                                            )
                                          }
                                          primaryKey={rafObject.UID}
                                          //decorators={[accountDecorators]}
                                          //convertBeforeSave={ConvertAccountRowToAccountDto}
                                          className="h-100"
                                        >
                                          <div className={"dlg-new-style"}>
                                            <div
                                              className={
                                                "e-dlg-headerContent h-auto tab_fixedHeader_scrollableContent_dialog_header"
                                              }
                                            >
                                              <div className="d-flex justify-content-between w-100">
                                                <div className={"e-dlg-header"}>
                                                  {props
                                                    .selectedServiceTransactionRow
                                                    .Type ===
                                                  RAFServiceTransactionType.Booking
                                                    ? "Update Activity"
                                                    : isNotNullAndUndefined(
                                                        selectedFormLibrary
                                                      )
                                                    ? `Update ${selectedFormLibrary.Title}`
                                                    : `Update ${CareEsioEntity.CareShiftLog.DisplayName}`}
                                                </div>
                                                <ButtonComponent
                                                  type="button"
                                                  cssClass="primary-custom-button"
                                                  iconCss="fas fa-xmark"
                                                  onClick={() =>
                                                    onCloseDialogClick()
                                                  }
                                                ></ButtonComponent>
                                              </div>
                                            </div>
                                            {tabMenuItems.length > 1 ? (
                                              <div
                                                className="border-bottom ps-1"
                                                style={{
                                                  marginTop: "-0.125rem",
                                                }}
                                              >
                                                <TabComponent
                                                  cssClass="e-fill custom-tab-footer-border bp-tab active-bg-gray active-bg-white"
                                                  loadOn="Demand"
                                                  heightAdjustMode="Auto"
                                                  selectedItem={
                                                    selectedTabItemIndex
                                                  }
                                                  // animation={tabAnimationSettings}
                                                  selected={(e) =>
                                                    TabSelected(e.selectedItem)
                                                  }
                                                >
                                                  <div className="d-flex align-items-center justify-content-between bg-blue w-100 pe-4">
                                                    <div className="e-tab-header">
                                                      {tabMenuItems.map(
                                                        (item, index) => {
                                                          return (
                                                            <div key={index}>
                                                              {item.text}
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="e-content"></div>
                                                </TabComponent>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            <div
                                              className={
                                                "e-dlg-body-content taskUpdateForm position-relative p-0 mb-3"
                                              }
                                            >
                                              {renderTabContent(
                                                rafObject,
                                                careShiftStatusQueryAttribute,
                                                formModel,
                                                hasCustomForm
                                              )}
                                            </div>
                                            <div className="e-dlg-footerContent">
                                              <div className="w-100">
                                                <div className="row gx-2">
                                                  {isNotNullAndUndefined(
                                                    props.onDelete
                                                  ) ? (
                                                    <div className="col-auto">
                                                      <RAFButtonComponent
                                                        action={
                                                          RAFButtonConstant.Delete
                                                        }
                                                        onClick={() =>
                                                          onClickDeleteCareShiftLog()
                                                        }
                                                        idString="DeleteContent"
                                                        className="e-danger e-outline"
                                                      />
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <div className="col-auto ms-auto">
                                                    <RAFButtonComponent
                                                      ref={submitButtonRef}
                                                      isPrimary
                                                      action={
                                                        RAFButtonConstant.Save
                                                      }
                                                      onClick={() =>
                                                        rafForm &&
                                                        rafForm.form.submit()
                                                      }
                                                      idString="EditContent"
                                                      disabled={
                                                        rafForm &&
                                                        rafForm.submitting
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col-auto">
                                                    <RAFButtonComponent
                                                      action={
                                                        RAFButtonConstant.Cancel
                                                      }
                                                      onClick={props.onClose}
                                                      idString="EditContent"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </RAFForm>
                                      );
                                    } else {
                                      if (
                                        error === Constants.RAFObject_NoContent
                                      ) {
                                        return (
                                          <div className="container-fluid px-0">
                                            <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div
                                            className="container-fluid px-0"
                                            style={{
                                              background: "transparent",
                                            }}
                                          >
                                            {/* <ACLoadingPanel loadingText="Loading..." /> */}
                                          </div>
                                        );
                                      }
                                    }
                                  }}
                                </RAFObjectContext.Consumer>
                              </RAFObjectContextProvider>
                            );
                          }}
                        </RAFEntityContext.Consumer>
                      );
                    }}
                  </RAFAttributesContext.Consumer>
                </RAFAttributeRelatedListProvider>
              </RAFEntityProvider>
            </div>
          );
        }
      } else {
        return (
          <div className="e-dlg-content-outer">
            <div className="e-dlg-headerContent h-auto tab_fixedHeader_scrollableContent_dialog_header">
              <div className="e-dlg-header"></div>
              <RAFButtonComponent
                type="button"
                className="primary-custom-button"
                action={RAFButtonConstant.DialogClose}
                onClick={() => props.onClose()}
                iconBtn
              ></RAFButtonComponent>
            </div>
            <div className="e-dlg-body-content">
              <div className="container-fluid px-0">
                <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Loading..." />
        </div>
      );
    }
  } else {
    return (
      <div
        className="container-fluid px-0"
        style={{ background: "transparent" }}
      ></div>
    );
  }
}
export default React.memo(UpdateCareShiftLog);
