import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { IconPickerItem } from "react-fa-icon-picker";
import { Field, FormRenderProps } from "react-final-form";
import { RRule } from "rrule";
import RAFAutoCompleteMUI from "../../../RAFComponents/Inputs/RAFAutoCompleteMUI";
import RAFCheckBox from "../../../RAFComponents/Inputs/RAFCheckBox";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDropdown from "../../../RAFComponents/Inputs/RAFDropdown";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFTextBox from "../../../RAFComponents/Inputs/RAFTextBox";
import {
  EvaluateRAFFormRules,
  getFormValue,
  setFormValue,
} from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFIconImage from "../../../RAFComponents/Navigation/RAFIconImage";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  getSessionStorage,
  setSessionStorage,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import { getCreateComponent } from "../../../RAFComponents/helpers/RAFMenuHelper";
import {
  getRuleByWeekday,
  getRuleFrequency,
} from "../../../RAFComponents/helpers/RRuleInputHelper";
import {
  ConvertSystemName,
  ConvertToElementID,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  isEmptyOrNull,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RAFRuleAction } from "../../../RAFComponents/models/Common/FormLibraryRow";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import {
  EvaluateAndSetScore,
  getEntityByObjectName,
} from "../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareInterventionSupportPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  ContentType,
  RAFButtonConstant,
  RAFLayout,
  StorageKey,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { RAFBPCurrentStatus } from "../../../constants/Common/RMConstants";
import CreateContent from "../../Common/Create/CreateContent";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../Common/Providers/RAFObjectContextProvider";
import { getAssessmentListByCarePlanAndRecipientUID } from "../CareAssessmentLibrary/CareAssessmentHelper";
import CareCategoryAndSubCategory from "../CareCategoryAndSubCategory";

interface IProps {
  isActive: boolean;
  moduleName: string;
  onSave: (entityId?: string, objectName?: string) => void;
  onClose: () => void;
  initialValues?: any;
}

interface IState {
  isLoading: boolean;
  initialValues: any;
  showBackBtn: boolean;
  selectedTitleValue: string;
  selectedItemIndex: "category" | "title" | "form";
  allStandardizedLibraryItems: any[];
  careAssessments?: any[];
}

function CreateCareInterventionSupport({
  ...props
}: PropsWithChildren<IProps>) {
  let rafForm: FormRenderProps | null;
  const { formLibrary } = useContext(RAFEntityContext);

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      showBackBtn: true,
      initialValues: null,
      selectedTitleValue: null,
      allStandardizedLibraryItems: null,
      selectedItemIndex: "form",
      careAssessments: [],
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    setState({ isLoading: true });
    if (props.isActive) {
      let initialObject = {};
      if (isNotNullAndUndefined(props.initialValues)) {
        initialObject = props.initialValues;
      }
      const allStandardizedLibraryItems =
        await getAllStandardizedLibraryItems();
      let dropdownItems: any[] = allStandardizedLibraryItems;
      const defaultItem = { Title: "Other", UID: null };

      if (isNotEmptyArray(dropdownItems)) {
        dropdownItems.push(defaultItem);
      } else {
        dropdownItems = [];
        dropdownItems.push(defaultItem);
      }

      /*  const selectedItemIndex: "category" | "title" | "form" =
        isNotNullAndUndefined(initalObject["CategoryUID"])
          ? "title"
          : "category";
      const showBackBtn = selectedItemIndex === "title" ? false : true;*/

      // const selectedItemIndex: "category" | "title" | "form" =
      //   isNotNullAndUndefined(initalObject["CategoryUID"])
      //     ? "form"
      //     : "category";

      const selectedItemIndex = "form";
      const showBackBtn = selectedItemIndex === "form" ? false : true;

      const careAssessments = await getAssessmentListByCarePlanAndRecipientUID(
        initialObject["CarePlanUID"],
        initialObject["CareRecipientUID"],
        CareEsioEntity.CareAssessment.EntityName,
        IsNullOrWhiteSpace(initialObject["CareAssessmentUID"])
          ? RAFBPCurrentStatus.Draft
          : null
      );

      setState({
        initialValues: initialObject,
        selectedItemIndex,
        showBackBtn,
        allStandardizedLibraryItems: dropdownItems,
        selectedTitleValue: null,
        isLoading: false,
        careAssessments,
      });
    }
  };

  const getAllStandardizedLibraryItems = () => {
    return new Promise<[]>(async (resolve) => {
      const standardizedLibraryEntity = await getEntityByObjectName({
        ObjectName: ConvertSystemName(RAFEntityName.StandardizedLibrary),
      });
      if (isNotNullAndUndefined(standardizedLibraryEntity)) {
        let titleCustomFilter: RAFCustomFilter = {}; //StandardizedLibrary
        titleCustomFilter.Condition = "and";
        titleCustomFilter.Rules = [];
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push("Support");
        filter.Operator = RAFCustomOperator.Equal;
        filter.Value = filterVal;
        (filter.Field = "Type"), titleCustomFilter.Rules.push(filter);

        let listServiceRequest = new ListServiceRequest();
        listServiceRequest.CustomFilter = titleCustomFilter;
        listServiceRequest.Skip = 0;
        listServiceRequest.Take = 0;

        listServiceRequest.EntityUID = standardizedLibraryEntity.UID;

        repositoryActions
          .postDataAndGetResponse(
            "DataList/List",
            listServiceRequest,
            null,
            ContentType.applicationJson
          )
          .then((response) => {
            if (isNotNullAndUndefined(response)) {
              if (response.status === 204) {
                resolve(null);
              } else if (
                isNotNullAndUndefined(response.data) &&
                isNotNullAndUndefined(response.data.Entity)
              ) {
                resolve(response.data.Entity);
              } else if (
                response.data &&
                response.data.ResultTable &&
                response.data.ResultTable.length > 0
              ) {
                resolve(response.data.ResultTable);
              } else {
                resolve(null);
              }
            } else {
              resolve(null);
            }
          })
          .catch((error) => error);
      } else {
        resolve(null);
      }
    });
  };

  const onRAFFormOnchange = (values) => {
    let ruleActions: RAFRuleAction[] = formLibrary && formLibrary.FormRules;
    //console.log({ ruleActions });
    if (isNotNullAndUndefined(ruleActions))
      EvaluateRAFFormRules(values, ruleActions);
    return;
  };

  const onSubmitObject = (
    values,
    queryAttributes,
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string }>
  ) => {
    const frequency = getRuleFrequency(values.RruleExpression);
    if (frequency === RRule.WEEKLY) {
      const ruleByWeekday = getRuleByWeekday(values.RruleExpression);
      if (!isNotEmptyArray(ruleByWeekday)) {
        showWarningToast("Please select at least one day");
        return;
      }
    }
    let updatedValues = R.clone(values);
    if (isNotNullAndUndefined(queryAttributes)) {
      updatedValues = EvaluateAndSetScore(values, queryAttributes);
    }
    saveObject(updatedValues)
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.objectUID)
        ) {
          showSuccessToast("Saved successfully");

          let allViews: RAFViewRow[] = JSON.parse(
            getSessionStorage(
              StorageKey.allViews_modulename + props.moduleName,
              true
            )
          );
          let recentlyCreatedView: RAFViewRow =
            allViews && allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
          if (isNotNullAndUndefined(recentlyCreatedView)) {
            setSessionStorage(
              StorageKey.recentViewId_modulename + props.moduleName,
              true,
              JSON.stringify({
                viewId: recentlyCreatedView.UID,
                viewName: recentlyCreatedView.DisplayName,
              })
            );
          }
          if (props.onSave)
            props.onSave(response.objectUID, response.objectName);
        } else {
          showWarningToast("Sorry something went wrong !");
        }
      })
      .catch((error) => error);
  };

  const onDoubleClickTitleItem = (item) => {
    const selectedTitle: string = isNotNullAndUndefined(item)
      ? item.Title
      : null;
    updateTitleFormValue(selectedTitle);
    setSelectedItemStyle(selectedTitle);
    if (isNotNullAndUndefined(item)) {
      setState({
        selectedItemIndex: selectedTitle !== "Other" ? "form" : "title",
        selectedTitleValue: selectedTitle,
      });
    }
  };

  const updateTitleFormValue = (selectedTitle: string) => {
    if (selectedTitle !== "Other") {
      setFormValue(rafForm, "Title", selectedTitle);
    } else {
      setFormValue(rafForm, "Title", null);
    }
  };

  const onClickMoveToFormContent = () => {
    const formValue = getFormValue(rafForm);
    if (
      isNotNullAndUndefined(formValue) &&
      isNotNullAndUndefined(formValue.Title)
    ) {
      setState({ selectedItemIndex: "form" });
    } else {
      showWarningToast("Please select Title");
    }
  };

  const onSelectCategory = () => {
    setState({ selectedItemIndex: "title" });
  };

  const onClickBackBtn = () => {
    setState({
      selectedItemIndex:
        state.selectedItemIndex === "form" ? "title" : "category",
    });

    setTimeout(() => {
      setSelectedItemStyle(state.selectedTitleValue);
    }, 50);
  };

  const setSelectedItemStyle = (selectedTitle) => {
    let cardContents = document.getElementsByClassName("custom__card");
    if (isNotNullAndUndefined(cardContents)) {
      for (let i = 0; i < cardContents.length; i++) {
        cardContents[i].classList.remove("custom__card__active");
      }
    }
    if (IsNotNullOrWhiteSpace(selectedTitle)) {
      let cardId = `card__${ConvertToElementID(selectedTitle)}`;
      let cardContent = document.getElementById(cardId);
      if (isNotNullAndUndefined(cardContent)) {
        cardContent.classList.add("custom__card__active");
      }
    }
  };

  const getSupportTypeDropdown = () => {
    return (
      <RAFDropdownCC
        field="SupportType"
        label="Support Type"
        placeholder="Select Type"
        isColorOption
        uitype="colorpicker"
        moduleName={CareEsioEntity.CareInterventionSupport.EntityName}
        onChanged={onChangeSupportType}
        emptyString="All"
      />
    );
  };

  const getSupportCategory = () => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    let filter: RAFCustomFilter = {};
    let filterVal: string[] = [];
    filterVal.push("Activity");
    filter.Operator = RAFCustomOperator.Equal;
    filter.Value = filterVal;
    filter.Field = "Type";
    customFilter.Rules.push(filter);
    return (
      <RAFLookUpMUI
        field={"Category"}
        placeholder={"Select Category"}
        //required
        url={"StandardizedLibrary/Lookup"}
        moduleName={RAFEntityName.StandardizedLibrary}
        showCreateButton={false}
        onChanged={(value) => onChangeSupportCategory(value)}
        //formGroupClassName={"mb-0"}
        // createform={getCreateComponent(
        //   RAFEntityName.StandardizedLibrary
        // )}
        SearchCreateOptionMode={"Footer"}
        customFilter={customFilter}
      />
    );
  };

  const onChangeSupportCategory = (value) => {
    setFormValue(rafForm, "Title", null);
    setFormValue(rafForm, "TitleUID", null);
  };

  const onChangeSupportType = () => {
    setFormValue(rafForm, "Title", null);
    setFormValue(rafForm, "TitleUID", null);
  };

  const getTitleFieldCustomFilter = (supportType: string) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    let filter: RAFCustomFilter = {};
    let filterVal: string[] = [];
    filterVal.push("Support");
    filter.Operator = RAFCustomOperator.Equal;
    filter.Value = filterVal;
    filter.Field = "Type";
    customFilter.Rules.push(filter);

    if (IsNotNullOrWhiteSpace(supportType)) {
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(supportType);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = "ParentUID";
      customFilter.Rules.push(filter);
    }

    // if (IsNotNullOrWhiteSpace(supportType) && supportType !== "All") {
    //   let filter: RAFCustomFilter = {};
    //   let filterVal: string[] = [];
    //   filterVal.push(supportType);
    //   filter.Operator = RAFCustomOperator.Equal;
    //   filter.Value = filterVal;
    //   filter.Field = "TagsListJson";
    //   customFilter.Rules.push(filter);
    // }
    return customFilter;
  };

  const getTitleLookUpDropdown = () => {
    return (
      <Field name="CategoryUID">
        {({ input }) => {
          const selectedSupportType = input.value;

          return (
            <div key={ConvertToElementID(selectedSupportType)}>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <label className={"form-label required"}>Title</label>
                </div>
                <div className="d-flex align-items-center">
                  <div className="mb-2">
                    <label className={"form-label"}>Must-do</label>
                  </div>
                  <RAFCheckBox
                    field={`IsRequired`}
                    label="Must-do"
                    showLabel={false}
                    validate={false}
                    labelPosition="right"
                    inputFieldClassName="col-auto"
                    labelClassName="col"
                    formGroupClassName="mb-2"
                    rowClassName="row g-2 align-items-center"
                    uitype={"switch"}
                    hideRequiredMessage
                  />
                </div>
              </div>
              {/* <RAFLookUpMUI */}
              <RAFAutoCompleteMUI
                field={"Title"}
                placeholder={"Select Title"}
                required
                showLabel={false}
                url={"StandardizedLibrary/Lookup"}
                moduleName={RAFEntityName.StandardizedLibrary}
                showCreateButton
                //formGroupClassName={"mb-0"}
                createform={getCreateComponent(
                  RAFEntityName.StandardizedLibrary
                )}
                SearchCreateOptionMode={"Footer"}
                customFilter={getTitleFieldCustomFilter(selectedSupportType)}
              />
            </div>
          );
        }}
      </Field>
    );
  };

  const onSupportNeedSelected = (label, value) => {
    if (isNotNullAndUndefined(value)) {
      let { careAssessments } = state;
      let selectedAssessment = careAssessments.find((x) => x.UID === value);
      if (isNotNullAndUndefined(selectedAssessment)) {
        setFormValue(rafForm, "CareAssessment", selectedAssessment["title"]);
        setFormValue(rafForm, "CareAssessmentUID", selectedAssessment.UID);
        setFormValue(
          rafForm,
          "CareAssessmentType",
          CareEsioEntity.CareAssessment.EntityName
        );
      }
    } else {
      setFormValue(rafForm, "CareAssessment", null);
      setFormValue(rafForm, "CareAssessmentUID", null);
      setFormValue(rafForm, "CareAssessmentType", null);
    }
  };

  const getSupportNeedsDropdown = () => {
    const { initialValues } = props;
    const { careAssessments } = state;
    return (
      <div className="col-md-12">
        <RAFDropdown
          field={"CareAssessmentUID"}
          showLabel
          //uitype="checkbox"
          onChanged={(lable, value) => onSupportNeedSelected(lable, value)}
          label="Link Support Need"
          //formGroupClassName="mb-0"
          allowFiltering
          disabled={
            IsNullOrWhiteSpace(initialValues["CareAssessmentUID"])
              ? false
              : true
          }
          required
        >
          {isNotEmptyArray(careAssessments) &&
            careAssessments.map((opt) => {
              return (
                <RAFChoiceOption
                  key={opt.UID}
                  label={opt.title}
                  value={opt.UID}
                />
              );
            })}
        </RAFDropdown>
      </div>
    );
  };

  const getTypeAndTitleInputFiled = () => {
    return (
      <>
        {/* <div className="col-md-12">{getSupportCategory()}</div>
      <div className="col-md-12">{getSupportTypeDropdown()}</div> */}
        <div className="col-md-12">{getTitleLookUpDropdown()}</div>
        {getSupportNeedsDropdown()}
      </>
    );
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      let goalCustomFilter: RAFCustomFilter = {}; //Goal
      if (isNotNullAndUndefined(state.initialValues.CarePlanUID)) {
        goalCustomFilter.Condition = "and";
        goalCustomFilter.Rules = [];
        let filter2: RAFCustomFilter = {};
        let filterVal2: string[] = [];
        filterVal2.push(state.initialValues.CarePlanUID);
        filter2.Operator = RAFCustomOperator.Equal;
        filter2.Value = filterVal2;
        filter2.Field = "CarePlanUID";
        goalCustomFilter.Rules.push(filter2);
      }

      return (
        <div
          className="h-100"
          key={props.moduleName}
          id={`raf_dlg_Outer_Div_${props.moduleName}`}
        >
          <RAFEntityProvider moduleName={props.moduleName}>
            <RAFAttributeRelatedListProvider moduleName={props.moduleName}>
              <RAFAttributesContext.Consumer>
                {({ queryAttributes }) => {
                  return (
                    <RAFObjectContextProvider
                      moduleName={props.moduleName}
                      progressDivId={`#raf_dlg_Outer_Div_${props.moduleName}`}
                    >
                      <RAFObjectContext.Consumer>
                        {({ /*objectId, rafObject,*/ saveObject }) => {
                          return (
                            <RAFForm
                              initialValues={state.initialValues}
                              formRef={(g) => (rafForm = g)}
                              layout={RAFLayout.TwoColumnLayout}
                              onSubmit={(values) =>
                                onSubmitObject(
                                  values,
                                  queryAttributes,
                                  saveObject
                                )
                              }
                              className="h-100"
                              onChange={onRAFFormOnchange}
                            >
                              <div className="e-dlg-content-outer">
                                <div
                                  className={`e-dlg-body-content${
                                    state.selectedItemIndex === "category"
                                      ? " p-0"
                                      : ""
                                  }`}
                                >
                                  {state.selectedItemIndex === "category" && (
                                    <div className="">
                                      <CareCategoryAndSubCategory
                                        categorySelected={onSelectCategory}
                                        entityName={
                                          CareEsioEntity.Assessment.EntityName
                                        }
                                        progressDivId={`#raf_dlg_Outer_Div_${props.moduleName}`}
                                        isActive
                                      />
                                    </div>
                                  )}
                                  <div
                                    className={
                                      state.selectedItemIndex === "title"
                                        ? ""
                                        : "hidden"
                                    }
                                  >
                                    {isNotEmptyArray(
                                      state.allStandardizedLibraryItems
                                    ) && (
                                      <div className="row g-3 mb-3">
                                        {state.allStandardizedLibraryItems.map(
                                          (item, index) => {
                                            const iconName = item.Icon;
                                            return (
                                              <div
                                                className="col-12"
                                                key={index}
                                                // onClick={() => onClickCategoryItem(item)}
                                                onClick={() =>
                                                  onDoubleClickTitleItem(item)
                                                }
                                              >
                                                <div
                                                  className="custom__card clickable"
                                                  id={`card__${ConvertToElementID(
                                                    item.Title
                                                  )}`}
                                                >
                                                  <div className="custom__card_content p-2 darkText">
                                                    <div className="d-flex align-items-center">
                                                      {isEmptyOrNull(
                                                        iconName
                                                      ) ? (
                                                        <RAFIconImage
                                                          iconCssClass={
                                                            "far fa-hand-holding-seedling"
                                                          }
                                                          moduleavatar="transparent-avatar avatar-text avatar-text-square"
                                                          iconSize="30"
                                                          fontSize="16"
                                                        />
                                                      ) : (
                                                        <div>
                                                          <span
                                                            className="border-avatar avatar-text avatar-text-square"
                                                            style={{
                                                              width: "30px",
                                                              height: "30px",
                                                              minWidth: "30px",
                                                              maxWidth: "30px",
                                                              fontSize: "16px",
                                                            }}
                                                          >
                                                            <IconPickerItem
                                                              icon={iconName}
                                                              size={16}
                                                            />
                                                          </span>
                                                        </div>
                                                      )}
                                                      <span className="secondary-header-bold-text-withoutColor ps-2 ecllipseSecondLine">
                                                        {" "}
                                                        {item.Title}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                        {state.selectedTitleValue ===
                                          "Other" && (
                                          <div>
                                            <RAFTextBox
                                              label="Title"
                                              field="Title"
                                              placeholder="Title"
                                              showLabel={false}
                                              required
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {!isNotEmptyArray(
                                      state.allStandardizedLibraryItems
                                    ) && (
                                      <div className="p-3 py-2 columnChooserPanel customScrollBar">
                                        <RAFDeletedRecordState title="You do not have sufficient privileges to add this item." />
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className={
                                      state.selectedItemIndex === "form"
                                        ? ""
                                        : "hidden"
                                    }
                                  >
                                    {getTypeAndTitleInputFiled()}
                                    <div className="col-md-12">
                                      <CreateContent
                                        moduleName={props.moduleName}
                                        progressDivId={`#raf_dlg_Outer_Div_${props.moduleName}`}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {state.selectedItemIndex !== "category" && (
                                  <div className="e-dlg-footerContent ">
                                    <div className="w-100">
                                      <div className="row gx-2">
                                        {state.selectedItemIndex === "title" &&
                                          state.showBackBtn && (
                                            <div className="col-auto">
                                              <div className="col-auto ms-auto">
                                                <RAFButtonComponent
                                                  type="button"
                                                  action={
                                                    RAFButtonConstant.Previous
                                                  }
                                                  onClick={onClickBackBtn}
                                                  idString="back_Content"
                                                  className="e-outline"
                                                />
                                              </div>
                                            </div>
                                          )}
                                        {state.selectedItemIndex ===
                                          "title" && (
                                          <div className="col-auto ms-auto">
                                            <RAFButtonComponent
                                              type="button"
                                              action={RAFButtonConstant.Next}
                                              isPrimary
                                              onClick={onClickMoveToFormContent}
                                              idString="next_Content"
                                              className="e-outline"
                                            />
                                          </div>
                                        )}
                                        {state.selectedItemIndex === "form" && (
                                          <div className="col-auto ms-auto">
                                            <RAFPermissionRender
                                              permissionName={
                                                CareInterventionSupportPermissionConstants.CareInterventionSupportAdd
                                              }
                                            >
                                              <RAFButtonComponent
                                                type="button"
                                                isPrimary
                                                action={RAFButtonConstant.Save}
                                                onClick={() =>
                                                  rafForm &&
                                                  rafForm.form.submit()
                                                }
                                                idString="CreateContent"
                                                disabled={
                                                  rafForm && rafForm.submitting
                                                }
                                              />
                                            </RAFPermissionRender>
                                          </div>
                                        )}
                                        {state.selectedItemIndex === "form" && (
                                          <div className="col-auto">
                                            <RAFButtonComponent
                                              type="button"
                                              action={RAFButtonConstant.Cancel}
                                              onClick={props.onClose}
                                              idString="CreateContent"
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </RAFForm>
                          );
                        }}
                      </RAFObjectContext.Consumer>
                    </RAFObjectContextProvider>
                  );
                }}
              </RAFAttributesContext.Consumer>
            </RAFAttributeRelatedListProvider>
          </RAFEntityProvider>
        </div>
      );
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  }
}

export default React.memo(CreateCareInterventionSupport);
