import DOMPurify from "dompurify";
import {
  Fragment,
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import {
  ActivityRow,
  RAFActivityCommentType,
} from "../../../ActiveContacts/Activity/ActivityRow";
import {
  getAllActivityItems,
  getPreviousShiftHandOvers,
} from "../../../ActiveContacts/Activity/ActivityHelper";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFDetailsValueWithSeparator from "../../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFRecordInfo from "../../../../RAFComponents/Navigation/RAFRecordInfo";
import React from "react";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import { RAFButtonConstant } from "../../../../constants/Common/Constants";
import { ServiceTransactionRow } from "../../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { CareRecipientPermissionConstants } from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import ManageClientNote from "./ManageClientNote";

interface IProps {
  serviceTransactionRow?: ServiceTransactionRow;
  viewMode?: "Horizontal" | "Vertical";
  mode?: "Current" | "Previous";
}

interface IState {
  isLoading: boolean;
  activityRow: ActivityRow;
  activityItems: ActivityRow[];
  showMoreClientNoteContent: boolean;
  showManageClientNoteContent: boolean;
  selectedObjectUID?: string;
}

function ClientHandOverNote({
  serviceTransactionRow,
  mode,
  ...props
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      activityRow: null,
      showMoreClientNoteContent: false,
      showManageClientNoteContent: false,
      activityItems: [],
      selectedObjectUID: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [serviceTransactionRow.UID]);

  const refresh = async () => {
    setState({
      isLoading: true,
      activityRow: null,
      activityItems: [],
      showMoreClientNoteContent: false,
      showManageClientNoteContent: false,
    });

    const sortQuery: string[] = [
      `${propertyOf<ActivityRow>("ModifiedDate")} desc`,
    ];

    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    let commentTypeFiler: RAFCustomFilter = {};
    let commentTypeFilterValue: string[] = [];
    commentTypeFilterValue.push(RAFActivityCommentType.Handover);
    commentTypeFiler.Operator = RAFCustomOperator.Equal;
    commentTypeFiler.Value = commentTypeFilterValue;
    commentTypeFiler.Field = propertyOf<ActivityRow>("CommentType");
    customFilter.Rules.push(commentTypeFiler);

    if (mode === "Current") {
      //get previous shift hand over notes
      const activityItems = await getAllActivityItems(
        null,
        null,
        serviceTransactionRow.UID,
        null,
        customFilter,
        sortQuery,
        20,
        0
      );

      setState({ activityItems, isLoading: false });
    } else {
      //get previous shift hand over notes
      const activityItems = await getPreviousShiftHandOvers(
        serviceTransactionRow.UID
      );

      setState({ activityItems, isLoading: false });
    }
  };

  const headerTemplate = (item: ActivityRow) => {
    return (
      <div className={`position-relative pointer`}>
        <div className={`align-items-center row g-3 flex-nowrap`}>
          <div className="col">
            <div className="d-flex align-items-center justify-content-between">
              <span className="subtitle_1 ecllipseFirstLine">
                {item.Subject}
              </span>
              {item.Pinned && (
                <span className="note_pin_outer_div">
                  <i className="note_pin_icon fas fa-thumbtack text_orange"></i>
                  {/* <i className="note_pin_icon fas fa-thumbtack" style={{ color: item.ColourCode }}></i> */}
                </span>
              )}
            </div>
            <div
              className="d-flex align-items-baseline body_3"
              onClick={(e) => e.stopPropagation()}
            >
              <RAFDetailsValueWithSeparator outerClassName="h-auto">
                <div className="d-flex align-items-center">
                  <span className="body_3 pe-1">
                    {item.Pinned ? "Pinned" : "Added"}
                  </span>
                  <span>
                    {isNotNullAndUndefined(item.CreatedBy) ? (
                      <span className="d-flex align-items-center">
                        <span className="body_3 pe-1">by</span>
                        <span className="body_3 text-decoration-underline pe-1 ecllipseFirstLine word-break-all">
                          {item.CreatedBy}
                        </span>
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div>
                  <RAFRecordInfo
                    createdBy={item.CreatedBy}
                    createdDate={item.CreatedDate}
                    modifiedDate={item.ModifiedDate}
                    modifiedBy={item.ModifiedBy}
                    lastActivityDate={item.LastActivityDate}
                    content="updated:"
                    hideClockIcon
                    dropdownBtnClassName="unset-custom-button-md p-0 remove-minWidth"
                    spanContentClassName="body_3"
                  ></RAFRecordInfo>
                </div>
              </RAFDetailsValueWithSeparator>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //show more ClientNote start
  const showMoreClientNoteContent = () => {
    if (state.showMoreClientNoteContent === true) {
      setState({ showMoreClientNoteContent: false });
    } else {
      setState({ showMoreClientNoteContent: true });
    }
  };

  //show more ClientNote end

  //manage ClientNote_start
  const showManageClientNoteContent = (selectedObjectUID) => {
    setState({ showManageClientNoteContent: true, selectedObjectUID });
  };

  const manageClientNoteContent = () => {
    if (isNotNullAndUndefined(state.showManageClientNoteContent)) {
      return (
        <ManageClientNote
          objectUID={state.selectedObjectUID}
          onSave={refresh.bind(this)}
          onClose={closeManageClientNoteDialog.bind(this)}
          onDelete={refresh.bind(this)}
          careRecipientUID={serviceTransactionRow.RelatedToUID}
          activityCommentType={RAFActivityCommentType.Handover}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const closeManageClientNoteDialog = () => {
    setState({ showManageClientNoteContent: false, selectedObjectUID: null });
  };

  //manage ClientNote_end

  const getNotesContentDiv = (activityRow: ActivityRow) => {
    const colorCodeName = isNotNullAndUndefined(activityRow.ColourCode)
      ? activityRow.ColourCode
      : "#67C6C2";
    return (
      <div className={"col-12"}>
        <CustomCardWidget
          cardContentClassName={"p-2 p-md-3"}
          style={{
            backgroundColor: colorCodeName,
            borderColor: colorCodeName,
          }}
        >
          {headerTemplate(activityRow)}
          {IsNotNullOrWhiteSpace(activityRow.Message) && (
            <div
              id={"summaryTask_" + activityRow.UID}
              // style={{ backgroundColor: item.ColorCode }}
              className="mt-2"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    activityRow !== null
                      ? DOMPurify.sanitize(activityRow.Message)
                      : undefined,
                }}
                className="remove_paragraph_margin body_2_dark description-text ecllipseSecondLine ecllipse_4"
              ></span>
            </div>
          )}
        </CustomCardWidget>
      </div>
    );
  };
  if (state.isLoading === false) {
    if (mode === "Current") {
      if (isNotEmptyArray(state.activityItems)) {
        return (
          <div className="p-md-1">
            <div className="my-2">
              <span className="subtitle_1">Handover Notes</span>
            </div>
            <RAFPermissionRender
              permissionName={
                CareRecipientPermissionConstants.CareRecipientReadNotes
              }
            >
              <div className="service_transaction_note shadow-sm">
                <div
                  className={`service_transaction_note_content${
                    state.showMoreClientNoteContent ? " active" : ""
                  }`}
                >
                  <div
                    className={`row align-items-center gy-3 g-0${
                      props.viewMode === "Horizontal"
                        ? " gx-3 flex-nowrap"
                        : " flex-column"
                    }`}
                  >
                    {state.activityItems.map((item) => {
                      return (
                        <div
                          key={item.UID}
                          onClick={() => showManageClientNoteContent(item.UID)}
                        >
                          {getNotesContentDiv(item)}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className={`service_transaction_note_footer${
                    state.showMoreClientNoteContent ? " active" : ""
                  }`}
                >
                  <div className="">
                    <RAFButtonComponent
                      className="custom-button-sm btn_brand_primary white"
                      action={
                        state.showMoreClientNoteContent
                          ? RAFButtonConstant.HideLess
                          : RAFButtonConstant.ShowMore
                      }
                      onClick={showMoreClientNoteContent}
                      iconPosition="right"
                      btnContent={`${state.activityItems.length} handover note(s)`}
                    ></RAFButtonComponent>
                  </div>
                </div>
                {state.showManageClientNoteContent && (
                  <DialogComponent
                    //header={isNotNullAndUndefined(state.selectedObjectUID) ? `Update${props.activityCommentType === RAFActivityCommentType.Post ? ' Post' : ''}` : `Add${props.activityCommentType === RAFActivityCommentType.Post ? ' Post' : ''}`}
                    //showCloseIcon={true}
                    visible={state.showManageClientNoteContent}
                    cssClass={
                      "rightDialog createEditForm full-height dlg-new-style"
                    }
                    id="manageupdate_clientnote_dialog"
                    content={manageClientNoteContent.bind(this)}
                    isModal
                    target="body"
                    closeOnEscape={false}
                    close={closeManageClientNoteDialog.bind(this)}
                    open={PreventFocusOnDialogOpen}
                    zIndex={1200}
                  ></DialogComponent>
                )}
              </div>
            </RAFPermissionRender>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      if (isNotEmptyArray(state.activityItems)) {
        return (
          <Fragment>
            <RAFPermissionRender
              permissionName={
                CareRecipientPermissionConstants.CareRecipientReadNotes
              }
            >
              <div className="service_transaction_note shadow-sm mb-3">
                <div
                  className={`service_transaction_note_content${
                    state.showMoreClientNoteContent ? " active" : ""
                  }`}
                >
                  <div
                    className={`row align-items-center gy-3 g-0${
                      props.viewMode === "Horizontal"
                        ? " gx-3 flex-nowrap"
                        : " flex-column"
                    }`}
                  >
                    {state.activityItems.map((item) => {
                      return (
                        <div
                          key={item.UID}
                          onClick={() => showManageClientNoteContent(item.UID)}
                        >
                          {getNotesContentDiv(item)}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className={`service_transaction_note_footer${
                    state.showMoreClientNoteContent ? " active" : ""
                  }`}
                >
                  <div className="">
                    <RAFButtonComponent
                      className="custom-button-sm btn_brand_primary white"
                      action={
                        state.showMoreClientNoteContent
                          ? RAFButtonConstant.HideLess
                          : RAFButtonConstant.ShowMore
                      }
                      onClick={showMoreClientNoteContent}
                      iconPosition="right"
                      btnContent={`${state.activityItems.length} handover note(s)`}
                    ></RAFButtonComponent>
                  </div>
                </div>
              </div>
            </RAFPermissionRender>
          </Fragment>
        );
      } else {
        return <></>;
      }
    }
  } else {
    return (
      <div className="container-fluid px-0">
        <ACLoadingPanel loadingText="Loading..." />
      </div>
    );
  }
}

export default React.memo(ClientHandOverNote);
