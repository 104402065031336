import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  ReactElement,
  Reducer,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import RAFCollapseWithPlusIcon from "../../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import { showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  getAllFormsByCategoryTypes,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  hasPermission,
  hasPermissions,
} from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  convertUTCDateToLocalTimezone,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../../RAFComponents/models/Common/FormLibraryRow";
import { RolePermissionRow } from "../../../../RAFComponents/models/Common/RolePermissionRow";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { ServiceTransactionPermissionConstants } from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import "../../../../styles/timeline-layout.scss";
import { ServiceTransactionRow } from "../../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import AddCareShiftLog from "../AddCareShiftLog";
import { getCareShiftLogsList } from "../CareShiftLogHelper";
import CareShiftLogActivityDetailsDialog from "./CareShiftLogActivityDetailsDialog";
import CareShiftLogCalenderContent from "./CareShiftLogCalenderContent";
import { RAFServiceTransactionType } from "../../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";

const adjustToNearestHalfHour = (date: Date) => {
  if (isNotNullAndUndefined(date)) {
    const minutes = date.getMinutes();

    if (minutes < 30) {
      date.setMinutes(0);
    } else if (minutes >= 30) {
      date.setMinutes(30);
    } else {
      date.setMinutes(0);
    }

    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }
};

export const getShiftActivityDrawerItems = (
  permissionValue: RolePermissionRow
) => {
  const drawerItems: {
    Label: string;
    Value: "Activity" | "FormObservations" | "FormQualitySafety";
  }[] = [];
  if (
    hasPermission(
      permissionValue,
      ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableActivities
    )
  ) {
    drawerItems.push({ Label: "Add Activity", Value: "Activity" });
  }
  if (
    hasPermission(
      permissionValue,
      ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableForms
    )
  ) {
    drawerItems.push({ Label: "Add Observation", Value: "FormObservations" });
    drawerItems.push({
      Label: "Add Quality & Safety",
      Value: "FormQualitySafety",
    });
  }
  return drawerItems;
};

class SchedulesRowDataRow {
  Id: string;
  Subject: string;
  StartTime: Date;
  EndTime: Date;
  StartActualTime: Date;
  EndActualTime: Date;
  hasEndTime: boolean;
}

interface IProps {
  careRecipientUID: string;
  careRecipientName: string;

  selectedEntity?: string;
  headerString?: { primaryHeader: string; secondaryHeader?: string };
  serviceTransactionRow: ServiceTransactionRow;
  groupBtnContent: ReactElement;
}

interface IState {
  isLoading: boolean;
  careShiftLogs: any[];
  formLibrary: FormLibraryRow;
  allForms: FormLibraryRow[];
  showAddShiftActivityContent: boolean;
  selectedCalenderStartTime: Date;
}

function CareShiftLogCalendar({
  serviceTransactionRow,
  careRecipientUID,
  ...props
}: PropsWithChildren<IProps>) {
  let careShiftLogCalenderContentRef = useRef(null);
  let careShiftLogActivityDetailsDialog = useRef(null);

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      careShiftLogs: null,
      formLibrary: null,
      allForms: null,
      selectedCalenderStartTime: null,
      showAddShiftActivityContent: false,
    }
  );

  useEffect(() => {
    refresh();
  }, [serviceTransactionRow]);

  const refresh = async () => {
    setState({
      isLoading: true,
      selectedCalenderStartTime: null,
      showAddShiftActivityContent: false,
    });
    if (
      isNotNullAndUndefined(careShiftLogCalenderContentRef) &&
      isNotNullAndUndefined(careShiftLogCalenderContentRef.current)
    ) {
      careShiftLogCalenderContentRef.current.onDestroyed();
    }
    let progressDiv = showProgress(".body", true);

    if (isNotNullAndUndefined(serviceTransactionRow)) {
      const [careShiftLogs, allForms] = await Promise.all([
        getCareShiftLogsList(serviceTransactionRow.UID, props.selectedEntity),
        getAllFormsByCategoryTypes(
          [
            CareEsioEntity.CareShiftLog.DisplayName,
            CareEsioEntity.CareRecipientCQIRegister.DisplayName,
          ],
          CareEsioEntity.CareShiftLog.EntityName
        ),
      ]);

      let formLibrary =
        allForms &&
        allForms.find(
          (x) => x.Entity === CareEsioEntity.CareShiftActivity.EntityName
        );

      hideProgress(progressDiv);
      setState({
        isLoading: false,
        careShiftLogs,
        formLibrary,
        allForms,
      });
    }
  };

  const refreshOnUpdate = async () => {
    setState({
      selectedCalenderStartTime: null,
      showAddShiftActivityContent: false,
    });
    let progressDiv = showProgress(".body", true);

    if (isNotNullAndUndefined(serviceTransactionRow)) {
      const [careShiftLogs] = await Promise.all([
        getCareShiftLogsList(serviceTransactionRow.UID, props.selectedEntity),
      ]);

      hideProgress(progressDiv);

      if (
        isNotNullAndUndefined(careShiftLogCalenderContentRef) &&
        isNotNullAndUndefined(careShiftLogCalenderContentRef.current)
      ) {
        careShiftLogCalenderContentRef.current.updateDataSource(
          convertShiftLogItemsToCalendarDataSource(careShiftLogs),
          careShiftLogs
        );
      }
      if (
        isNotNullAndUndefined(careShiftLogActivityDetailsDialog) &&
        isNotNullAndUndefined(careShiftLogActivityDetailsDialog.current)
      ) {
        careShiftLogActivityDetailsDialog.current.editCareShiftLog(null);
      }

      state.careShiftLogs = careShiftLogs;
    }
  };

  const convertShiftLogItemsToCalendarDataSource = (careShiftLogs: any[]) => {
    const calendarDataSourcePropsValue: SchedulesRowDataRow[] = [];
    isNotEmptyArray(careShiftLogs) &&
      careShiftLogs.forEach((log) => {
        const start_date = log.start_date;
        const newStartDate = new Date(log.start_date);
        newStartDate.setMinutes(newStartDate.getMinutes() + 30);
        const end_date = isNotNullAndUndefined(log.end_date)
          ? log.end_date
          : newStartDate;
        calendarDataSourcePropsValue.push({
          Id: log.UID,
          Subject: log.title,
          StartTime: adjustToNearestHalfHour(
            convertUTCDateToLocalTimezone(start_date)
          ),
          EndTime: adjustToNearestHalfHour(
            convertUTCDateToLocalTimezone(end_date)
          ),
          StartActualTime: convertUTCDateToLocalTimezone(start_date),
          EndActualTime: convertUTCDateToLocalTimezone(end_date),
          hasEndTime: isNotNullAndUndefined(log.end_date) ? true : false,
        });
      });
    return calendarDataSourcePropsValue;
  };

  //create shift activity start

  function onAddShiftActivitySectionContent() {
    if (state.showAddShiftActivityContent === true) {
      return (
        <AddCareShiftLog
          onSave={refreshOnUpdate.bind(this)}
          onClose={onAddShiftActivityDialogClose.bind(this)}
          allForms={state.allForms}
          formLibrary={state.formLibrary}
          selectedServiceTransactionRow={serviceTransactionRow}
          careRecipientUID={careRecipientUID}
          careRecipientName={props.careRecipientName}
          careShiftLogs={state.careShiftLogs}
          selectedCalenderStartTime={state.selectedCalenderStartTime}
          isActive
          {...props}
        />
      );
    } else {
      return <div></div>;
    }
  }

  const onAddShiftActivityDialogClose = () => {
    setState({ showAddShiftActivityContent: false });
  };
  //create shift activity end

  //show event details start
  const onClickShowEventDetails = (
    item: SchedulesRowDataRow,
    careShiftLogs: any[]
  ) => {
    const selectedShiftActivityRow = careShiftLogs.find(
      (x) => x.UID === item.Id
    );
    if (
      isNotNullAndUndefined(selectedShiftActivityRow) &&
      isNotNullAndUndefined(careShiftLogActivityDetailsDialog) &&
      isNotNullAndUndefined(careShiftLogActivityDetailsDialog.current)
    ) {
      careShiftLogActivityDetailsDialog.current.editCareShiftLog(
        selectedShiftActivityRow
      );
    }
  };

  const shiftActivityDetailsContent = () => {
    return (
      <CareShiftLogActivityDetailsDialog
        ref={careShiftLogActivityDetailsDialog}
        careRecipientUID={careRecipientUID}
        serviceTransactionRow={serviceTransactionRow}
        onUpdateData={refreshOnUpdate}
        allForms={state.allForms}
      />
    );
  };
  //show event details end

  const OnCellCreateClick = (selectedCalenderStartTime: Date) => {
    if (serviceTransactionRow.Type === RAFServiceTransactionType.Booking) {
      if (
        hasPermissions(permissionValue, [
          ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableActivities,
          ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableForms,
        ])
      ) {
        setState({
          showAddShiftActivityContent: true,
          selectedCalenderStartTime,
        });
      }
    } else {
      if (isNotNullAndUndefined(serviceTransactionRow.ActualStartDate)) {
        if (
          hasPermissions(permissionValue, [
            ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableActivities,
            ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableForms,
          ])
        ) {
          setState({
            showAddShiftActivityContent: true,
            selectedCalenderStartTime,
          });
        }
      } else {
        showWarningToast("Start the shift to add activity.");
      }
    }
  };

  const careShiftLogSectionCardContent = (
    isLoading: boolean,
    serviceTransactionRow: ServiceTransactionRow,
    careShiftLogs: any[]
  ) => {
    if (isLoading === false) {
      const calendarDataSourcePropsValue: SchedulesRowDataRow[] =
        convertShiftLogItemsToCalendarDataSource(careShiftLogs);
      const shiftStartDate = isNotNullAndUndefined(
        serviceTransactionRow.ActualStartDate
      )
        ? serviceTransactionRow.ActualStartDate
        : serviceTransactionRow.StartDate;
      const shiftEndDate = isNotNullAndUndefined(
        serviceTransactionRow.ActualEndDate
      )
        ? serviceTransactionRow.ActualEndDate
        : serviceTransactionRow.EndDate;

      return (
        <CareShiftLogCalenderContent
          ref={careShiftLogCalenderContentRef}
          calenderDataSourcePropsValue={calendarDataSourcePropsValue}
          careShiftLogsPropsValue={careShiftLogs}
          shiftStartDate={adjustToNearestHalfHour(
            convertUTCDateToLocalTimezone(shiftStartDate)
          )}
          shiftEndDate={adjustToNearestHalfHour(
            convertUTCDateToLocalTimezone(shiftEndDate)
          )}
          onClickShowEventDetails={onClickShowEventDetails}
          OnCellCreateClick={OnCellCreateClick}
          groupBtnContent={props.groupBtnContent}
          serviceTransactionRow={serviceTransactionRow}
        />
      );
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Loading..." />
        </div>
      );
    }
  };

  const onExpandClicked = (expanded?: boolean) => {
    if (expanded) {
      refresh();
    }
  };

  const careShiftLogSectionContent = useMemo(() => {
    return (
      <div>
        <RAFCollapseWithPlusIcon
          // customTitle={overviewHeaderTemplate()}
          toggleArrowIcon="Arrow"
          // title={
          //   isNotNullAndUndefined(props.headerString) &&
          //     isNotNullAndUndefined(props.headerString.primaryHeader)
          //     ? props.headerString.primaryHeader
          //     : CareEsioEntity.CareShiftLog.CollectionName
          // }
          // customTitle={headerTemplate()}
          collapsePanelHeaderClass={"p-0"}
          allowFullRowClick
          collapsePanelContentClassname="p-0"
          collapsePanelRowClass="g-0"
          collapsePanelHeaderSpanClass=""
          onExpandedSection={(expanded) => onExpandClicked(expanded)}
          showSeparator={false}
          collapsible={false}
        >
          <div>
            {careShiftLogSectionCardContent(
              state.isLoading,
              serviceTransactionRow,
              state.careShiftLogs
            )}
          </div>
        </RAFCollapseWithPlusIcon>
      </div>
    );
  }, [state.isLoading, state.careShiftLogs, serviceTransactionRow]);

  if (isNotNullAndUndefined(serviceTransactionRow)) {
    return (
      <div className="row gx-0 gy-3">
        <div className="col-12">{careShiftLogSectionContent}</div>
        {state.showAddShiftActivityContent === true && (
          <DialogComponent
            // header={"Add Activity"}
            // showCloseIcon
            visible={state.showAddShiftActivityContent}
            //cssClass="rightDialog createEditForm full-height dlg-new-style"
            cssClass="rightDialog createEditForm full-height dlg-new-style"
            id="add_shift_activity_dlg_outerDiv"
            content={onAddShiftActivitySectionContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={onAddShiftActivityDialogClose.bind(this)}
            open={PreventFocusOnDialogOpen}
            zIndex={1200}
          ></DialogComponent>
        )}
        {shiftActivityDetailsContent()}
      </div>
    );
  } else {
    return null;
  }
}

export default React.memo(CareShiftLogCalendar);
