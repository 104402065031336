import { CalculatedValue, Model } from "survey-core";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  Guid,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { retrieveFormLibraryObjectByUID } from "../../../WorkesioRepository/FormLibraryData/FormLibraryDataRespository";
import { retrieveProcessObjectByUID } from "../../../WorkesioRepository/Process/ProcessRespository";
import {
  ContentType,
  RAFActionStatus,
} from "../../../constants/Common/Constants";
import { EntityRow } from "../Entity/EntityRow";
import { ProcessDataRow } from "./ProcessDataRow";
import { ConvertProcessLibraryDtoToProcessLibraryRow } from "./ProcessLibraryMappingRegister";
import { IProcessLibraryDto, ProcessLibraryRow } from "./ProcessLibraryRow";
import { TaskRow } from "../../../RAFModules/ActiveContacts/Task/TaskRow";
import { RAFBPStatus } from "../../../constants/Common/RMConstants";
import { msalInstance } from "../../..";
import { processStepType } from "../FormLibrary/components/SurveyCreator/SurveyCreatorProcess";
import {
  getAllRelatedTasksByBPStep,
  saveTaskListWithReminders,
  saveTaskWithReminders,
} from "../../../RAFModules/ActiveContacts/Task/TaskHelper";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { getEntityByName } from "../../helpers/RMutils";
import { RepeatProcesssItems } from "../../../RAFModules/ActiveContacts/Task/RAFRepeatProcesssDropdown";
import {
  RetrieveRecord,
  SaveDataList,
} from "../../../RAFComponents/helpers/AppHelper";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";

export const RetrieveProcessRecordById = (recordId: string) => {
  return new Promise<ProcessDataRow>((resolve) => {
    if (isNotNullAndUndefined(recordId)) {
      return repositoryActions
        .postDataAndGetResponse(
          "ProcessData/Retrieve",
          { EntityId: recordId },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entity)
            ) {
              resolve(response.data.Entity);
            } else if (
              response.data &&
              response.data.ResultTable &&
              response.data.ResultTable.length > 0
            ) {
              resolve(response.data.ResultTable[0]);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export function RetrieveProcessLibraryById(id: string) {
  return new Promise<ProcessLibraryRow>((resolve) => {
    if (isNotNullAndUndefined(id)) {
      return repositoryActions
        .postDataAndGetResponse(
          `ProcessLibrary/Retrieve`,
          { EntityId: id },
          null,
          ContentType.applicationJson,
          false
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entity)
          ) {
            const responseDto: IProcessLibraryDto = response.data
              .Entity as IProcessLibraryDto;
            let processLibrary: ProcessLibraryRow =
              ConvertProcessLibraryDtoToProcessLibraryRow(responseDto);
            resolve(processLibrary);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
}

export const SaveProcess = (saveRequestData?: any, apiurl?: string) => {
  return new Promise<{ entityId: string; objectName: string }>(
    (resolve, reject) => {
      const url = isNotNullAndUndefined(apiurl)
        ? apiurl
        : "Datalist/SaveSubForms";

      repositoryActions
        .postDataAndGetResponse(
          url,
          saveRequestData,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            resolve({
              entityId: response.data.EntityId,
              objectName: response.data.ObjectName,
            });
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    }
  );
};

export const getInitialProcessRow = (
  objectUID?: string,
  initialValues?: any
) => {
  return new Promise<any>(async (resolve) => {
    if (isNotNullAndUndefined(objectUID)) {
      //getting from local db if already exists
      const initialObjectDB = await retrieveProcessObjectByUID(objectUID);

      if (isNotNullAndUndefined(initialObjectDB)) {
        const formLibraryDataRow = { ...initialObjectDB };
        resolve(formLibraryDataRow as any);
      } else {
        const responseFormLibraryDataRow: any = await RetrieveProcessRecordById(
          objectUID
        );
        if (
          isNotNullAndUndefined(responseFormLibraryDataRow) &&
          isNotNullAndUndefined(responseFormLibraryDataRow.UID)
        ) {
          let formLibraryDataRow = {};
          formLibraryDataRow = responseFormLibraryDataRow["DataJson"];
          formLibraryDataRow["UID"] = responseFormLibraryDataRow.UID;
          resolve(formLibraryDataRow);
        }
      }
    } else {
      const moduleName =
        isNotNullAndUndefined(initialValues) &&
        isNotNullAndUndefined(initialValues.formLibrary_item)
          ? initialValues.formLibrary_item
          : null;
      //getting from local db if already exists
      const initialObjectDB = await retrieveFormLibraryObjectByUID(
        `${Guid.emptyGuid()}${moduleName ?? ""}`
      );
      //getting from local db if already exists
      if (isNotNullAndUndefined(initialObjectDB)) {
        const initialObject = { ...initialObjectDB };
        resolve(initialObject);
      } else {
        let initialObject = isNotNullAndUndefined(initialValues)
          ? initialValues
          : {};
        initialObject.UID = `${Guid.emptyGuid()}${moduleName ?? ""}`;
        resolve(initialObject);
      }
    }
  });
};

// Function to find the page index by stepId
export const findPageIndexByStepId = (
  stepId: string,
  steps: any,
  rafObject: any
) => {
  for (let i = 0; i < steps.length; i++) {
    const stepQuestion = steps[i];
    const newValue = rafObject && rafObject[stepQuestion.name];
    if (isNotNullAndUndefined(newValue) && newValue.stepId === stepId) {
      return i; // Return the index of the step (page)
    }
  }
  return -1; // Return -1 if stepId is not found
};

export const getAllProcessLibraryList = (
  relatedToUID?: string,
  relatedEntity?: string
) => {
  return new Promise<ProcessLibraryRow[]>((resolve) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    let isActiveFilter: RAFCustomFilter = {};
    let isActiveFilterValue: string[] = [];
    isActiveFilterValue.push("true");
    isActiveFilter.Operator = RAFCustomOperator.Equal;
    isActiveFilter.Value = isActiveFilterValue;
    isActiveFilter.Field = "IsActive";
    customFilter.Rules.push(isActiveFilter);

    let filter: RAFCustomFilter = {};
    let filterVal: string[] = [];
    filterVal.push("Process Library");
    filter.Operator = RAFCustomOperator.Equal;
    filter.Value = filterVal;
    filter.Field = "CategoryType";
    customFilter.Rules.push(filter);

    if (isNotNullAndUndefined(relatedToUID)) {
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(relatedToUID);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = "RelatedToUID";
      customFilter.Rules.push(filter);
    }

    if (isNotNullAndUndefined(relatedEntity)) {
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(relatedEntity);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = "RelatedToType";
      customFilter.Rules.push(filter);
    }

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;
    listServiceRequest.CustomFilter = customFilter;
    repositoryActions
      .postDataAndGetResponse(
        "ProcessLibrary/ListAll",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
};

export const StartProcess = (
  processLibrary?: ProcessLibraryRow,
  relatedToUID?: string,
  relatedToType?: string,
  relatedTo?: string
) => {
  return new Promise<{ entityId: string; objectName: string }>(
    async (resolve, reject) => {
      const entity: EntityRow = await getEntityByName(processLibrary.Entity);
      let updatedValues = {};
      updatedValues["process_libraryuid"] = processLibrary.UID;
      updatedValues["process_library"] = processLibrary.Title;
      updatedValues["category"] = processLibrary.CategoryType;
      updatedValues["entity_uid"] = entity.UID;
      updatedValues["entity"] = entity.EntityName;
      updatedValues["status"] = RAFBPStatus.InProgress;
      updatedValues["process_library_version_number"] =
        processLibrary.VersionNumber;
      updatedValues["process_library_version_uid"] = processLibrary.VersionUID;
      updatedValues["record_owner"] = processLibrary.RecordOwner;
      updatedValues["record_owneruid"] = processLibrary.RecordOwnerUID;

      //set related to values

      if (isNotNullAndUndefined(relatedToUID)) {
        updatedValues["related_touid"] = relatedToUID;
      }
      if (isNotNullAndUndefined(relatedToType)) {
        updatedValues["related_totype"] = relatedToType;
      }
      if (isNotNullAndUndefined(relatedTo)) {
        updatedValues["related_to"] = relatedTo;
      }

      let taskRow: TaskRow = new TaskRow();
      let updatedModel = new Model(processLibrary.FormStructure);
      // Access the custom variable
      let customVariables: CalculatedValue[] = updatedModel["calculatedValues"];
      let processStartDate = customVariables.find(
        (x) => x.name === "process_startdate"
      )?.value;

      /* commented by hari
      // Get the first page from the updatedModel
      const firstPage =
        updatedModel.pages.length > 0 ? updatedModel.pages[0] : null;

      const firstPageStepQuestion =
        firstPage &&
        firstPage.questions.find((q) => q.getType() === processStepType);
      const defaultValue = isNotNullAndUndefined(firstPageStepQuestion)
        ? firstPageStepQuestion.defaultValue || {}
        : null;
      if (isNotNullAndUndefined(defaultValue)) {
        const assignee = defaultValue["assignee"];
        const assigneeuid = defaultValue["assigneeuid"];
        const status = defaultValue["status"];
        const deadline = defaultValue["deadline"];
        const steptype = defaultValue["steptype"];
        const calculationtype = defaultValue["calculationtype"];
        const stepId = Guid.newGuid();
        let newValue = {};
        newValue["steptype"] = steptype;
        newValue["status"] = status;
        newValue["calculationtype"] = calculationtype;
        newValue["stepId"] = stepId;
        if (steptype === "Task" || steptype === "Approval") {
          newValue["assignee"] = assignee;
          newValue["assigneeuid"] = assigneeuid;
          newValue["deadline"] = deadline;

          let duedate;
          if (
            calculationtype === "Process Start" &&
            isNotNullAndUndefined(processStartDate)
          ) {
            if (deadline === 1) {
              duedate = new Date(
                processStartDate.getTime() + 24 * 60 * 60 * 1000
              );
            } else if (deadline > 1) {
              duedate = new Date(
                processStartDate.getTime() + deadline * 24 * 60 * 60 * 1000
              );
            }

            newValue["duedate"] = duedate;
            updatedModel.setValue(firstPageStepQuestion.name, newValue);

            //API call to create new task for first step if assignee is not null and step type is task/approval

            if (
              isNotNullAndUndefined(assignee) &&
              isNotNullAndUndefined(assigneeuid)
            ) {
              taskRow.Assignee = assignee;
              taskRow.AssigneeUID = assigneeuid;
            }
            if (isNotNullAndUndefined(duedate)) {
              taskRow.TaskDate = duedate;
            }

            taskRow.TaskStatus = RAFActionStatus.Planned;
            taskRow.IsApprovalTask = steptype === "Approval" ? true : false;
            taskRow.TaskType = "Action";
            taskRow.SubType = "Task";
            taskRow.Title = firstPage.title;
            taskRow.Priority = "Low";
            taskRow.RepeatProcesss = RepeatProcesssItems.Never;

            // taskRow.SecondaryRelatedTo = firstPage.title;
            // taskRow.SecondaryRelatedToUID = stepId;
            // taskRow.SecondaryRelatedToType = `${firstPageStepQuestion.name}_${steptype}`;
            //set related step values
            taskRow.BPStep = firstPage.name;
            taskRow.BPStepUID = stepId;

            taskRow.RecordOwner = msalInstance.currentUserName;
            taskRow.RecordOwnerUID = msalInstance.currentUserId;
          }
        }
      }
*/
      //get all stepquestions and create tasks for each step if step type is task/approval
      let allQuestions = updatedModel.getAllQuestions();
      let allStepQuestions = allQuestions.filter(
        (question) => question.getType() === processStepType
      );

      let taskRows: TaskRow[] = [];
      if (
        isNotNullAndUndefined(allStepQuestions) &&
        allStepQuestions.length > 0
      ) {
        allStepQuestions.forEach((stepQuestion, index) => {
          let taskRow: TaskRow = new TaskRow();
          let currentPage = updatedModel.pages[index];
          const defaultValue = isNotNullAndUndefined(stepQuestion)
            ? stepQuestion.defaultValue || {}
            : null;

          if (isNotNullAndUndefined(defaultValue)) {
            const assignee = defaultValue["assignee"];
            const assigneeuid = defaultValue["assigneeuid"];
            const assigneeteam = defaultValue["assigneeteam"];
            const assigneeteamuid = defaultValue["assigneeteamuid"];
            const status = defaultValue["status"];
            const deadline = defaultValue["deadline"];
            const steptype = defaultValue["steptype"];
            const calculationtype = defaultValue["calculationtype"];
            const stepId = Guid.newGuid();
            let newValue = {};
            newValue["steptype"] = steptype;
            newValue["status"] = status;
            newValue["calculationtype"] = calculationtype;
            newValue["stepId"] = stepId;
            let duedate;
            if (steptype === "Task" || steptype === "Approval") {
              newValue["assignee"] = assignee;
              newValue["assigneeuid"] = assigneeuid;
              newValue["assigneeteam"] = assigneeteam;
              newValue["assigneeteamuid"] = assigneeteamuid;
              newValue["deadline"] = deadline;
              if (
                calculationtype === "Process Start" &&
                isNotNullAndUndefined(processStartDate)
              ) {
                if (deadline === 1) {
                  duedate = new Date(
                    processStartDate.getTime() + 24 * 60 * 60 * 1000
                  );
                } else if (deadline > 1) {
                  duedate = new Date(
                    processStartDate.getTime() + deadline * 24 * 60 * 60 * 1000
                  );
                }
              }
              newValue["duedate"] = duedate;
              updatedModel.setValue(stepQuestion.name, newValue);

              //API call to create new task for step if assignee is not null and step type is task/approval
              if (
                isNotNullAndUndefined(assignee) &&
                isNotNullAndUndefined(assigneeuid)
              ) {
                taskRow.Assignee = assignee;
                taskRow.AssigneeUID = assigneeuid;
              }
              if (
                isNotNullAndUndefined(assigneeteam) &&
                isNotNullAndUndefined(assigneeteamuid)
              ) {
                taskRow.AssignTeam = assigneeteam;
                taskRow.AssignTeamUID = assigneeteamuid;
              }
              if (isNotNullAndUndefined(duedate)) {
                taskRow.TaskDate = duedate;
              }

              taskRow.TaskStatus = RAFActionStatus.Planned;
              taskRow.IsApprovalTask = steptype === "Approval" ? true : false;
              taskRow.TaskType = "Action";
              taskRow.SubType = "Task";
              taskRow.Title = currentPage && currentPage.title;
              taskRow.Priority = "Low";
              taskRow.RepeatProcesss = RepeatProcesssItems.Never;

              //set related step values
              taskRow.BPStep = currentPage && currentPage.name;
              taskRow.BPStepUID = stepId;

              taskRow.RecordOwner = msalInstance.currentUserName;
              taskRow.RecordOwnerUID = msalInstance.currentUserId;

              taskRows.push({ ...taskRow });
            }
          }
        });
      }

      updatedValues = { ...updatedValues, ...updatedModel.data };

      const url = "ProcessData/SaveData";
      SaveProcess(updatedValues, url)
        .then(async (response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.entityId)
          ) {
            const rafObject = await RetrieveProcessRecordById(
              response.entityId
            );

            if (
              isNotNullAndUndefined(rafObject) &&
              isNotNullAndUndefined(rafObject.UID)
            ) {
              let objectName = rafObject["ProcessDataId"];
              let relatedObject;

              if (
                isNotNullAndUndefined(relatedToUID) &&
                isNotNullAndUndefined(relatedToType)
              ) {
                if (relatedToType === CareEsioEntity.CareRecipient.EntityName) {
                  relatedObject = await RetrieveRecord(
                    relatedToUID,
                    relatedToType,
                    null,
                    null,
                    "CareRecipient/RetrieveForm"
                  );

                  relatedObject["business_process"] = objectName;
                  relatedObject["business_processuid"] = response.entityId;
                  relatedObject["process_status"] = RAFBPStatus.InProgress;

                  let relatedObjectResponse = await SaveDataList(
                    relatedObject,
                    null,
                    relatedToType,
                    "CareRecipient/SaveForm"
                  );
                } else {
                  relatedObject = await RetrieveRecord(
                    relatedToUID,
                    relatedToType
                  );

                  relatedObject["BusinessProcess"] = objectName;
                  relatedObject["BusinessProcessUID"] = response.entityId;
                  relatedObject["ProcessStatus"] = RAFBPStatus.InProgress;

                  let relatedObjectResponse = await SaveDataList(
                    relatedObject,
                    null,
                    relatedToType
                  );
                }
              }

              //commented by hari
              // if (isNotNullAndUndefined(taskRow)) {
              //   //set related to values
              //   if (isNotNullAndUndefined(relatedToUID)) {
              //     taskRow.RelatedToUID = relatedToUID;
              //     taskRow.RelatedTo = relatedTo;
              //     taskRow.RelatedToType = relatedToType;
              //     taskRow.BusinessProcess = objectName;
              //     taskRow.BusinessProcessUID = response.entityId;
              //   } else {
              //     taskRow.RelatedToUID = response.entityId;
              //     taskRow.RelatedTo = objectName;
              //     taskRow.RelatedToType = "Process";
              //     // taskRow.BusinessProcess = objectName;
              //     // taskRow.BusinessProcessUID = response.entityId;
              //   }

              //   saveTaskWithReminders(taskRow).then((taskResponse) => {
              //     if (isNotNullAndUndefined(taskResponse)) {
              //       resolve({
              //         entityId: response.entityId,
              //         objectName: objectName,
              //       });
              //     }
              //   });
              // } else {
              //   resolve({
              //     entityId: response.entityId,
              //     objectName: objectName,
              //   });
              // }

              if (isNotEmptyArray(taskRows)) {
                taskRows.forEach((taskRow) => {
                  //set related to values
                  if (isNotNullAndUndefined(relatedToUID)) {
                    taskRow.RelatedToUID = relatedToUID;
                    taskRow.RelatedTo = relatedTo;
                    taskRow.RelatedToType = relatedToType;
                    taskRow.BusinessProcess = objectName;
                    taskRow.BusinessProcessUID = response.entityId;
                  } else {
                    taskRow.RelatedToUID = response.entityId;
                    taskRow.RelatedTo = objectName;
                    taskRow.RelatedToType = "Process";
                    // taskRow.BusinessProcess = objectName;
                    // taskRow.BusinessProcessUID = response.entityId;
                  }
                });

                saveTaskListWithReminders(taskRows).then((taskResponse) => {
                  if (isNotNullAndUndefined(taskResponse)) {
                    resolve({
                      entityId: response.entityId,
                      objectName: objectName,
                    });
                  }
                });
              } else {
                resolve({
                  entityId: response.entityId,
                  objectName: objectName,
                });
              }
            }
          } else {
            showWarningToast("Process not found");
          }
        })
        .catch((error) => {});
    }
  );
};

export const getAllProcessDataList = (
  relatedToUID?: string,
  entityName?: string
) => {
  return new Promise<ProcessDataRow[]>((resolve) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    // let filter: RAFCustomFilter = {};
    // let filterVal: string[] = [];
    // filterVal.push("Process Library");
    // filter.Operator = RAFCustomOperator.Equal;
    // filter.Value = filterVal;
    // filter.Field = "Category";
    // customFilter.Rules.push(filter);

    if (isNotNullAndUndefined(relatedToUID)) {
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(relatedToUID);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = "RelatedToUID";
      customFilter.Rules.push(filter);
    }

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;
    listServiceRequest.CustomFilter = customFilter;
    if (isNotNullAndUndefined(entityName)) {
      listServiceRequest.EntityName = entityName;
    }
    repositoryActions
      .postDataAndGetResponse(
        "ProcessData/List",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
};

// Method to find the object with the specific stepId
function findStepByStepId(datajson, stepId) {
  // Iterate over all keys in the datajson object
  for (let key in datajson) {
    if (key.endsWith("_headerField")) {
      // Check if the current step has the matching stepId
      if (datajson[key].stepId === stepId) {
        return key;
      }
    }
  }
  return null; // Return null if no matching stepId is found
}

export function findStepByValue(datajson, stepQuestions) {
  // Iterate over all keys in the datajson object
  let stepValues = [];
  stepQuestions.forEach((stepQuestion) => {
    let questionName = stepQuestion.name;
    for (let key in datajson) {
      if (key === questionName) {
        // Check if the key has the matching with question name
        stepValues.push({ ...datajson[key] });
      }
    }
  });

  return stepValues; // Return null if no matching stepId is found
}

export const completeTaskAndUpdateProcess = (taskFormRow: TaskRow) => {
  return new Promise<boolean>(async (resolve, reject) => {
    let relatedToUID = isNotNullAndUndefined(taskFormRow.BusinessProcessUID)
      ? taskFormRow.BusinessProcessUID
      : taskFormRow.RelatedToUID;
    const processRecord = await RetrieveProcessRecordById(relatedToUID);
    const processLibrary = await RetrieveProcessLibraryById(
      processRecord.ProcessLibraryUID
    );
    const formData = processRecord.DataJson;
    formData["UID"] = relatedToUID;

    let objectName = processRecord.ProcessDataId;
    let objectUID = processRecord.UID;
    let relatedTo = processRecord.RelatedTo;
    let related_totype = processRecord.RelatedToType;
    let related_touid = processRecord.RelatedToUID;
    let updatedModel = new Model(processLibrary.FormStructure);
    updatedModel.data = formData;
    const selectedStepObjectName = findStepByStepId(
      formData,
      taskFormRow.BPStepUID
    );

    let pages = updatedModel.pages;
    const completionDate = new Date();

    // Find the index of the current page
    let currentPageIndex = pages.findIndex(
      (page) => page.name === taskFormRow.BPStep
    );

    //Get the current page
    let currentPage = pages[currentPageIndex];
    const currentPageStepQuestion = currentPage.questions.find(
      (q) => q.getType() === processStepType
    );

    if (isNotNullAndUndefined(currentPageStepQuestion)) {
      const newValue = currentPageStepQuestion.value || {};
      newValue["status"] = "Completed";
      newValue["completiondate"] = completionDate;
      newValue["completedby"] = msalInstance.currentUserName;
      formData[selectedStepObjectName] = newValue;
    }

    // Get the next page
    let nextPage = pages[currentPageIndex + 1];
    if (isNotNullAndUndefined(nextPage)) {
      const nextPageStepQuestion = nextPage.questions.find(
        (q) => q.getType() === processStepType
      );

      // Access the custom variable
      let customVariables: CalculatedValue[] = updatedModel.calculatedValues;
      let processStartDate = customVariables.find(
        (x) => x.name === "process_startdate"
      )?.value;

      const newValue = nextPageStepQuestion.value || {};
      const assignee = newValue["assignee"];
      const assigneeuid = newValue["assigneeuid"];
      const status = newValue["status"];
      const deadline = newValue["deadline"];
      const steptype = newValue["steptype"];
      const calculationtype = newValue["calculationtype"];
      const stepId = Guid.newGuid();
      newValue["stepId"] = stepId;

      if (
        (steptype === "Task" || steptype === "Approval") &&
        status === "Not Started"
      ) {
        //API call to get task for next step
        let nextStepTasks: TaskRow[] = await getAllRelatedTasksByBPStep(
          nextPage.name
        );

        if (isNotEmptyArray(nextStepTasks)) {
        } else {
          if (
            calculationtype === "Process Start" &&
            isNotNullAndUndefined(processStartDate)
          ) {
            if (deadline === 1) {
              newValue["duedate"] = new Date(
                processStartDate.getTime() + 24 * 60 * 60 * 1000
              );
            } else if (deadline > 1) {
              newValue["duedate"] = new Date(
                processStartDate.getTime() + deadline * 24 * 60 * 60 * 1000
              );
            }
            formData[nextPageStepQuestion.name] = newValue;
          } else if (calculationtype === "Previous Step") {
            if (deadline === 1) {
              newValue["duedate"] = new Date(
                completionDate.getTime() + 24 * 60 * 60 * 1000
              );
            } else if (deadline > 1) {
              newValue["duedate"] = new Date(
                completionDate.getTime() + deadline * 24 * 60 * 60 * 1000
              );
            }

            formData[nextPageStepQuestion.name] = newValue;
          } else if (calculationtype === "Custom Date") {
          }

          if (
            (steptype === "Task" || steptype === "Approval") &&
            status === "Not Started"
          ) {
            //API call to create new task for next step if assignee is not null and step type is task/approval
            let taskRow: TaskRow = new TaskRow();
            if (
              isNotNullAndUndefined(assignee) &&
              isNotNullAndUndefined(assigneeuid)
            ) {
              taskRow.Assignee = assignee;
              taskRow.AssigneeUID = assigneeuid;
            }
            if (isNotNullAndUndefined(newValue["duedate"])) {
              taskRow.TaskDate = newValue["duedate"];
            }

            taskRow.Title = nextPage.title;
            taskRow.TaskStatus = RAFActionStatus.Planned;
            taskRow.IsApprovalTask = steptype === "Approval" ? true : false;
            taskRow.TaskType = "Action";
            taskRow.SubType = "Task";
            taskRow.Priority = "Low";

            taskRow.BPStep = nextPage.name;
            taskRow.BPStepUID = stepId;

            if (isNotNullAndUndefined(taskFormRow.BusinessProcessUID)) {
              taskRow.RelatedToUID = related_touid;
              taskRow.RelatedTo = relatedTo;
              taskRow.RelatedToType = related_totype;
              taskRow.BusinessProcess = objectName;
              taskRow.BusinessProcessUID = objectUID;
            } else {
              taskRow.RelatedToUID = related_touid;
              taskRow.RelatedTo = relatedTo;
              taskRow.RelatedToType = related_totype;
            }

            taskRow.RecordOwner = msalInstance.currentUserName;
            taskRow.RecordOwnerUID = msalInstance.currentUserId;
            let taskRowResponse = await saveTaskWithReminders(taskRow);
          }
        }
      }
    } else {
      // If the next page is not found, then the process is completed
      let allQuestions = updatedModel.getAllQuestions();
      let allStepQuestions = allQuestions.filter(
        (question) => question.getType() === processStepType
      );

      let allCompleteStepQuestions = allStepQuestions.filter(
        (x) => x.value.status === RAFBPStatus.Completed
      );
      if (allCompleteStepQuestions.length === allStepQuestions.length) {
        formData["status"] = RAFBPStatus.Completed;
      }
    }

    const url = "ProcessData/SaveData";
    SaveProcess(formData, url).then(async (response) => {
      if (isNotNullAndUndefined(response)) {
        //API call to save related object
        if (
          isNotNullAndUndefined(related_touid) &&
          formData["status"] === RAFBPStatus.Completed
        ) {
          if (related_totype === CareEsioEntity.CareRecipient.EntityName) {
            let relatedObject = await RetrieveRecord(
              relatedToUID,
              related_totype,
              null,
              null,
              "CareRecipient/RetrieveForm"
            );

            relatedObject["process_status"] = RAFBPStatus.InProgress;

            let relatedObjectResponse = await SaveDataList(
              relatedObject,
              null,
              related_totype,
              "CareRecipient/SaveForm"
            );
          } else {
            let relatedObject = await RetrieveRecord(
              relatedToUID,
              related_totype
            );

            relatedObject["ProcessStatus"] = RAFBPStatus.Completed;

            let relatedObjectResponse = await SaveDataList(
              relatedObject,
              null,
              related_totype
            );
          }
        }
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
};
