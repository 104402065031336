import { PortalTreeViewItemRow } from "./WorkspaceItemHelper";

// CareESIOAdminMenuData
// CareESIOSystemSettingsMenuData
// CareESIOUserSettingsMenuData

export const CareESIOMainMenuData: PortalTreeViewItemRow[] = [
  {
    UID: "1ab64c78-6d63-45e5-991d-c452c709f905",
    Name: "home",
    Text: "Home",
    Icon: "home",
    URL: "/",
    IsExternalIcon: false,
    NodeChild: [],
    HasChildren: false,
    Entity: null,
    ParentUID: null,
  },
  {
    UID: "ee211bbd-d0e4-499b-a34a-e881a3e01d4d",
    Name: "inbox",
    Text: "Inbox",
    Icon: "file-alt",
    URL: "/Inbox",
    IsExternalIcon: false,
    NodeChild: [],
    HasChildren: false,
    Entity: null,
    ParentUID: null,
  },
  {
    UID: "a9ecd1b7-4704-45ef-ab08-9c19757b72fb",
    Name: "task_dashboard",
    Text: "Tasks",
    Icon: "bars-progress",
    URL: "/taskdashboard",
    Entity: "task",
    IsExternalIcon: false,
    ItemType: "Module",
    NodeChild: [
      {
        UID: "task_My Tasks",
        Name: "My Tasks",
        Text: "Upcoming Tasks",
        Icon: "bars-progress",
        URL: "/taskdashboard",
        Entity: "task",
        ParentUID: "a9ecd1b7-4704-45ef-ab08-9c19757b72fb",
        IsExternalIcon: false,
        ItemType: "View",
        NodeChild: [],
        HasChildren: false,
        PermissionName: "task::read",
      },
      {
        UID: "task_Tasks Assigned by Me",
        Name: "Tasks Assigned by Me",
        Text: "Assigned by Me",
        Icon: "bars-progress",
        URL: "/taskdashboard",
        Entity: "task",
        ParentUID: "a9ecd1b7-4704-45ef-ab08-9c19757b72fb",
        IsExternalIcon: false,
        ItemType: "View",
        NodeChild: [],
        HasChildren: false,
        PermissionName: "task::read",
      },
      {
        UID: "task_Completed Tasks",
        Name: "Completed Tasks",
        Text: "Completed Tasks",
        Icon: "bars-progress",
        URL: "/taskdashboard",
        Entity: "task",
        ParentUID: "a9ecd1b7-4704-45ef-ab08-9c19757b72fb",
        IsExternalIcon: false,
        ItemType: "View",
        NodeChild: [],
        HasChildren: false,
        PermissionName: "task::read",
      },
    ],
    HasChildren: true,
    ParentUID: null,
    PermissionName: "task::read",
  },
  {
    UID: "3e8c6d9b-a3ac-4b79-87f9-442cfcc11e46",
    Name: "task_action_center",
    Text: "Action Center",
    Icon: "fasdl fa-list-timeline",
    URL: "/action_center",
    Entity: "task_action_center",
    ParentUID: null,
    IsExternalIcon: false,
    ItemType: "Module",
    NodeChild: [],
    HasChildren: false,
    PermissionName: "task::read",
  },
  {
    UID: "50112573-d3f1-4061-8696-97408b11575a",
    Name: "care_recipient",
    Text: "Clients",
    Icon: "FaHandHoldingHeart",
    URL: "/raf/list/care_recipient/",
    Entity: "care_recipient",
    ParentUID: null,
    IsExternalIcon: true,
    ItemType: "Module",
    NodeChild: [],
    HasChildren: false,
    PermissionName: "care_recipient::read",
  },
  {
    UID: "0e890a2f-a2e1-40d5-8f99-31a826294a9e",
    Name: "service_transaction",
    Text: "My Shifts",
    Icon: "FaCalendarAlt",
    URL: "/myshifts",
    Entity: "service_transaction",
    ParentUID: null,
    IsExternalIcon: true,
    ItemType: "Module",
    NodeChild: [],
    HasChildren: false,
    PermissionName: "service_transaction_myshifts::Read",
  },
  {
    UID: "f7ae95be-1470-4597-9154-6197e73244e0",
    Name: "service_transaction",
    Text: "Roster",
    Icon: "FaCalendarDay",
    URL: "/roster",
    Entity: "service_transaction",
    ParentUID: null,
    IsExternalIcon: true,
    ItemType: "Module",
    NodeChild: [],
    HasChildren: false,
    PermissionName: "service_transaction::ReadRoster",
  },
  // {
  //   UID: "cc5ddd7a-12b5-40d7-8db5-59a2744e9a8a",
  //   Name: "service_transaction",
  //   Text: "Bookings",
  //   Icon: "FaCalendarDay",
  //   URL: "/bookings",
  //   Entity: "service_transaction",
  //   ParentUID: null,
  //   IsExternalIcon: true,
  //   ItemType: "Module",
  //   NodeChild: [],
  //   HasChildren: false,
  //   PermissionName: "service_transaction::ReadBookings",
  // },
  // {
  //     "UID": "69a3e5c3-cd88-461d-826b-cf7ad90b8b8b",
  //     "Name": "service_transaction",
  //     "Text": "Shift Offers",
  //     "Icon": "FaRegWindowRestore",
  //     "URL": "/shiftoffers",
  //     "Entity": "service_transaction",
  //     "ParentUID": null,
  //     "IsExternalIcon": true,
  //     "ItemType": "Module",
  //     "NodeChild": [],
  //     "HasChildren": false,
  //     "PermissionName": 'service_transaction::ViewShiftOffer'
  // },
  {
    UID: "14868c4f-cbd0-4596-9ca1-c640bc0ddcff",
    Name: "account",
    Text: "Companies",
    Icon: "FaHotel",
    URL: "/company/list/",
    Entity: "account",
    ParentUID: null,
    IsExternalIcon: true,
    ItemType: "Module",
    NodeChild: [],
    HasChildren: false,
    PermissionName: "account::read",
  },
  {
    UID: "e3bbe3d2-1fff-4d7f-8567-70c2188eb867",
    Name: "contact",
    Text: "Contacts",
    Icon: "FaIdCard",
    URL: "/contact/list/",
    Entity: "contact",
    ParentUID: null,
    IsExternalIcon: true,
    ItemType: "Module",
    NodeChild: [],
    HasChildren: false,
    PermissionName: "contact::read",
  },
  {
    UID: "33759cb0-c818-44c5-9aa3-2396a18c51f4",
    Name: "ticket",
    Text: "Tickets",
    Icon: "FaTicketAlt",
    URL: "/ticket/list/",
    Entity: "ticket",
    ParentUID: null,
    IsExternalIcon: true,
    ItemType: "Module",
    NodeChild: [],
    HasChildren: false,
    PermissionName: "ticket::read",
  },
  {
    UID: "3ae86f70-b83c-4913-bec8-03ece807d49f",
    Name: "employee",
    Text: "Employees",
    Icon: "FaUserAstronaut",
    URL: "/raf/list/employee",
    Entity: "employee",
    ParentUID: null,
    IsExternalIcon: true,
    NodeChild: [],
    HasChildren: false,
    PermissionName: "employee::read",
  },
  {
    UID: "22ba3d23-2326-4dbb-870a-7716cd78274d",
    Name: "employee_leave",
    Text: "Leave Management",
    Icon: "FaUserAstronaut",
    URL: "/leavemanagement/list",
    Entity: "employee_leave",
    ParentUID: null,
    IsExternalIcon: true,
    NodeChild: [],
    HasChildren: false,
    PermissionName: "EmployeeLeave::ApproveOrReject",
  },
  {
    UID: "7069abc9-b4e0-4148-bc70-477210c9f7af",
    Name: "billing",
    Text: "Billing",
    Icon: "FaFileInvoiceDollar",
    URL: "/billing",
    Entity: "Billing",
    ParentUID: null,
    IsExternalIcon: true,
    ItemType: "Module",
    NodeChild: [],
    HasChildren: false,
    PermissionName: "invoice_transaction::ViewBilling",
  },
  {
    UID: "a1feec53-05b4-45d1-a4cb-3d38a1caed94",
    Name: "payroll",
    Text: "Timesheet",
    Icon: "FaUserClock",
    URL: "/payroll",
    Entity: "Payroll",
    ParentUID: null,
    IsExternalIcon: true,
    ItemType: "Module",
    NodeChild: [],
    HasChildren: false,
    PermissionName: "payroll_transaction::ViewTimeSheet",
  },
  // {
  //     "UID": "3d4579a8-7c1b-4101-8d00-5f63bbdf286f",
  //     "Name": "supportlog",
  //     "Text": "Support Log",
  //     "Icon": "FaRegListAlt",
  //     "URL": "/supportlog",
  //     "Entity": "SupportLog",
  //     "ParentUID": null,
  //     "IsExternalIcon": true,
  //     "ItemType": "Module",
  //     "NodeChild": [],
  //     "HasChildren": false,
  //     "PermissionName": 'service_transaction_supportlog::ViewSupportLog'
  // },
  {
    UID: "184a4779-2f3e-419c-b3ea-5ece1e72f764",
    Name: "content_library",
    Text: "Company Documents",
    Icon: "FaFileAlt",
    URL: "/document/list/",
    Entity: "content_library",
    ParentUID: null,
    IsExternalIcon: true,
    ItemType: "Module",
    NodeChild: [],
    HasChildren: false,
    PermissionName: "content_library::read",
  },
  // {
  //     "UID": "2757a145-131f-4bb7-abbb-6a783bbd8683",
  //     "Name": "checklist_item_trans",
  //     "Text": "Compliance",
  //     "Icon": "FaRegWindowRestore",
  //     "URL": "/compliance/list/",
  //     "Entity": "checklist_item_trans",
  //     "ParentUID": null,
  //     "IsExternalIcon": true,
  //     "ItemType": "Module",
  //     "NodeChild": [],
  //     "HasChildren": false,
  //     "PermissionName": 'checklist_item_trans::AdminComplianceDocuments'
  // },
  {
    UID: "dceaf7db-febc-40b9-aeb1-20819af9529a",
    Name: "reports",
    Text: "Reports & Analytics",
    Icon: "FaRegWindowRestore",
    URL: "/report/list",
    Entity: "Reports",
    ParentUID: null,
    IsExternalIcon: true,
    ItemType: "Module",
    NodeChild: [],
    HasChildren: false,
    PermissionName: "Analytics::Read",
  },
  {
    UID: "72113d67-cd1a-4d49-9349-167b58c91260",
    Name: "qualityandsafety",
    Text: "Quality & Safety",
    Icon: "FaExclamationTriangle",
    URL: "/quality/list",
    Entity: "QualityAndSafety",
    ParentUID: null,
    IsExternalIcon: true,
    ItemType: "Module",
    NodeChild: [],
    HasChildren: false,
    PermissionName: "QualitySafety::View",
  },
  // {
  //   UID: "a4b0c86e-9ea5-411d-98b3-b3188dfd95e3",
  //   Name: "formlibrary",
  //   Text: "Form Library",
  //   Icon: "FaExclamationTriangle",
  //   URL: "/formlibrary/list",
  //   Entity: "form_library",
  //   ParentUID: null,
  //   IsExternalIcon: true,
  //   ItemType: "Module",
  //   NodeChild: [],
  //   HasChildren: false,
  //   PermissionName: null,
  // },
  // {
  //   UID: "db396661-4eae-41e8-91c7-5bdf491e0de0",
  //   Name: "processlibrary",
  //   Text: "Process Library",
  //   Icon: "FaExclamationTriangle",
  //   URL: "/processlibrary/list",
  //   Entity: "process_library",
  //   ParentUID: null,
  //   IsExternalIcon: true,
  //   ItemType: "Module",
  //   NodeChild: [],
  //   HasChildren: false,
  //   PermissionName: null,
  // },
  // {
  //     "UID": "4b951e55-76cf-4177-b456-dd290e44c8b6",
  //     "Name": "acknowledgement_tracking",
  //     "Text": "Acknowledgement Tracking",
  //     "Icon": "FaRegWindowRestore",
  //     "URL": "/raf/list/acknowledgement_tracking/",
  //     "Entity": "acknowledgement_tracking",
  //     "ParentUID": null,
  //     "IsExternalIcon": true,
  //     "ItemType": "Module",
  //     "NodeChild": [],
  //     "HasChildren": false,
  //     "PermissionName": 'acknowledgement_tracking::read'
  // }
];
