import "bootstrap/dist/css/bootstrap.min.css";
import React, { ComponentType, lazy, Suspense } from "react";
import {
  NavigateFunction,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
//import { msalInstance } from "..";
//import ManageBusinessProcessV2 from "../RAFMaster/RMModules/BusinessProcess/Process/ManageBusinessProcessV2";
//import ManageFormComponent1 from "../RAFMaster/RMModules/FormLibrary/ManageFormComponent1";
import NavigationMenuTreeView from "../components/shared/NavigationMenu/NavigationMenuTreeView/NavigationMenuTreeView";
import { RAFEntityName } from "../constants/Common/EntityConstants";
import SwitchTenantPage from "../helpers/SwitchTenantPage";
import RAFDeletedRecordState from "../RAFComponents/Navigation/RAFDeletedRecordState";
import ServiceTransactionDetailsPage from "../RAFModules/ActiveContacts/ServiceTransaction/Details/ServiceTransactionDetailsPage";
import LoginPage from "./../pages/LoginPage";
import LogoutPage from "./../pages/LogoutPage";

const lazyRetry = function (componentImport, name) {
  return new Promise<{ default: ComponentType<any>; }>((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false"
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

//const AuthSuccessPage = lazy(() => import("../pages/AuthSuccessPage"));
const TenantSettingsDetails = React.lazy(() =>
  lazyRetry(
    () => import("../RAFModules/Common/TenantSettings/TenantSettingsDetails"),
    "TenantSettingsDetails"
  )
);
const AuthSuccessPage = React.lazy(() =>
  lazyRetry(() => import("../pages/AuthSuccessPage"), "AuthSuccessPage")
);
//const RegisterTenantPage = lazy(() => import("../pages/RegisterTenantPage"));
const RegisterTenantPage = React.lazy(() =>
  lazyRetry(() => import("../pages/RegisterTenantPage"), "RegisterTenantPage")
);
//const ActivatePage = lazy(() => import("./../pages/ActivatePage"));
const ActivatePage = React.lazy(() =>
  lazyRetry(() => import("./../pages/ActivatePage"), "ActivatePage")
);
//const HomePage = lazy(() => import("./../pages/HomePage"));
// const HomePage = React.lazy(() =>
//   lazyRetry(() => import("./../pages/HomePage"), "HomePage")
// );

const RAFBusinessProcessTypeListPage = lazy(() =>
  lazyRetry(
    () =>
      import("../RAFMaster/RMModules/Category/RAFBusinessProcessTypeListPage"),
    "RAFBusinessProcessTypeListPage"
  )
);
const ManageBusinessProcess = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/BusinessProcess/Process/ManageBusinessProcess"
      ),
    "ManageBusinessProcess"
  )
);

const ManageBusinessProcessView = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/BusinessProcess/ProcessLibrary/ManageBusinessProcess"
      ),
    "ManageBusinessProcessView"
  )
);
const ManageBusinessProcessV1 = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/BusinessProcess/Process/ManageBusinessProcessV1"
      ),
    "ManageBusinessProcessV1"
  )
);
const ManageBusinessProcessV2 = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/BusinessProcess/Process/ManageBusinessProcessV2"
      ),
    "ManageBusinessProcessV2"
  )
);
const RAFBusinessProcessDetails = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/BusinessProcess/Process/RAFBusinessProcessDetails"
      ),
    "RAFBusinessProcessDetails"
  )
);
const RAFBPTemplateIndexPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/BusinessProcess/Template/RAFBPTemplateIndexPage"
      ),
    "RAFBPTemplateIndexPage"
  )
);

const RAFProcessTemplateIndexPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/BusinessProcess/ProcessLibrary/RAFBPTemplateIndexPage"
      ),
    "RAFProcessTemplateIndexPage"
  )
);

const RAFProcessIndexPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/BusinessProcess/Process/RAFProcessIndexPage"
      ),
    "RAFProcessIndexPage"
  )
);

const RAFRunningBusinessProcessListPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/BusinessProcess/Process/RAFRunningBusinessProcessListPage"
      ),
    "RAFRunningBusinessProcessListPage"
  )
);
const ManageBusinessProcessTemplate = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/BusinessProcess/Template/ManageBusinessProcessTemplate"
      ),
    "ManageBusinessProcessTemplate"
  )
);

const ManageBusinessProcessTemplate2 = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/BusinessProcess/Template/ManageBusinessProcessTemplate2"
      ),
    "ManageBusinessProcessTemplate2"
  )
);
const ManageBusinessProcessTemplate3 = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/BusinessProcess/TemplateDetailsPage1/ManageBusinessProcessTemplate"
      ),
    "ManageBusinessProcessTemplate"
  )
);

const RAFManageBusinessProcessTemplate = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/BusinessProcess/ProcessLibrary/ManageBusinessProcessTemplate"
      ),
    "RAFManageBusinessProcessTemplate"
  )
);
const EntityDetails = lazy(() =>
  lazyRetry(
    () => import("../RAFMaster/RMModules/Entity/EntityDetails"),
    "EntityDetails"
  )
);
const EntityPage = lazy(() =>
  lazyRetry(
    () => import("../RAFMaster/RMModules/Entity/EntityPage"),
    "EntityPage"
  )
);
const ManageFormLibrary = lazy(() =>
  lazyRetry(
    () => import("../RAFMaster/RMModules/FormLibrary/ManageFormLibrary"),
    "ManageFormLibrary"
  )
);
const ManageDataList = lazy(() =>
  lazyRetry(
    () => import("../RAFMaster/RMModules/FormLibrary/RAFManagePage"),
    "ManageDataList"
  )
);

const ManageDataListSurvey = lazy(() =>
  lazyRetry(
    () => import("../RAFMaster/RMModules/FormLibrary/RAFManagePageSurvey"),
    "ManageDataListSurvey"
  )
);

const ManageFormPage = lazy(() =>
  lazyRetry(
    () => import("../RAFMaster/RMModules/FormLibrary/RAFManageFormPage"),
    "ManageFormPage"
  )
);
const FormLibraryRecordCreatePage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/FormLibrary/FormLibraryDataRecords/FormLibraryRecordCreatePage"
      ),
    "FormLibraryRecordCreatePage"
  )
);
const FormLibraryRecordUpdatePage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/FormLibrary/FormLibraryDataRecords/FormLibraryRecordUpdatePage"
      ),
    "FormLibraryRecordUpdatePage"
  )
);
const FormLibraryDataRecordsDetails = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/FormLibrary/FormLibraryDataRecords/FormLibraryDataRecordsDetails"
      ),
    "FormLibraryDataRecordsDetails"
  )
);
const FormLibraryDataRecordsIndexPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/FormLibrary/FormLibraryDataRecords/FormLibraryDataRecordsIndexPage"
      ),
    "FormLibraryDataRecordsIndexPage"
  )
);
const ViewPage = lazy(() =>
  lazyRetry(() => import("../RAFMaster/RMModules/Views/ViewPage"), "ViewPage")
);

const ReportAnalysisPage = lazy(() =>
  lazyRetry(
    () => import("../RAFMaster/RMModules/Reports/RAFReportIndex"),
    "ReportAnalysisPage"
  )
);

const QualityPage = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/CareESIO/QualityAndSafety/RAFQualityIndex"),
    "QualityPage"
  )
);

const WorkFlowPage = lazy(() =>
  lazyRetry(
    () => import("../RAFMaster/RMModules/Workflow/WorkFlowPage"),
    "WorkFlowPage"
  )
);

const SystemSettingsPage = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/ActiveContacts/Common/SystemSettingsPage"),
    "SystemSettingsPage"
  )
);
const ComplaintDetails = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/ActiveContacts/Complaint/ComplaintDetails"),
    "ComplaintDetails"
  )
);
const ComplaintPage = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/ActiveContacts/Complaint/ComplaintPage"),
    "ComplaintPage"
  )
);
const Dashboard = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/ActiveContacts/Dashboards/Dashboard"),
    "Dashboard"
  )
);
const UserFeedIndexPage = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/ActiveContacts/UserFeed/UserFeedIndexPage"),
    "UserFeedIndexPage"
  )
);
const DocumentContentDetails = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/ActiveContacts/Document/Library/DocumentContentDetails"
      ),
    "DocumentContentDetails"
  )
);
const DocumentContentDetailsCopy = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/ActiveContacts/Document/Library/DocumentContentDetailsCopy"
      ),
    "DocumentContentDetailsCopy"
  )
);
const DocumentPageContentDetails = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/ActiveContacts/Document/Library/DocumentPageContentDetails"
      ),
    "DocumentPageContentDetails"
  )
);

const RAFDocumentIndexPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/ActiveContacts/Document/DocumentIndex/RAFDocumentIndexPage"
      ),
    "RAFDocumentIndexPage"
  )
);
const RAFDocumentCreate = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/ActiveContacts/Document/ManageDocument/RAFDocumentCreate"
      ),
    "RAFDocumentIndexPage"
  )
);
const ProductPage = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/ActiveContacts/Product/ProductPage"),
    "ProductPage"
  )
);
const TaskDashboard = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/ActiveContacts/Task/TaskDashboard"),
    "TaskDashboard"
  )
);
const PlannerTaskIndexListPage = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/CareESIO/CareShiftLog/PlannerTask/PlannerTaskIndex/PlannerTaskIndexListPage"),
    "PlannerTaskIndexListPage"
  )
);

const ServiceTransactionIndexPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/ActiveContacts/ServiceTransaction/ServiceTransactionIndex/ServiceTransactionIndexPage"
      ),
    "ServiceTransactionIndexPage"
  )
);
const ShiftOfferIndexPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/ActiveContacts/ServiceTransaction/ShiftOffer/ShiftOfferIndexPage"
      ),
    "ShiftOfferIndexPage"
  )
);
const CareSupportLogIndexPage = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/CareESIO/CareRegister/CareSupportLogIndexPage"),
    "CareSupportLogIndexPage"
  )
);

const RAFAUtomationIndexPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/Automations/Notifications/RAFAutomationIndexPage"
      ),
    "RAFAUtomationIndexPage"
  )
);

const TimeSheetListPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/CareESIO/CareShiftLog/CareBillingTimesheet/TimeSheet/TimeSheetListPage"
      ),
    "TimeSheetListPage"
  )
);

const RAFCareBillingListPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/CareESIO/CareShiftLog/CareBillingTimesheet/CareBilling/RAFCareBillingListPage"
      ),
    "RAFCareBillingListPage"
  )
);

const RAFXeroSynctListPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/CareESIO/CareShiftLog/CareBillingTimesheet/XeroSync/RAFXeroSynctListPage"
      ),
    "RAFXeroSynctListPage"
  )
);

const RAFSyncLogIndexListPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/CareESIO/CareShiftLog/CareBillingTimesheet/SyncLog/SyncLogListPage/RAFSyncLogIndexListPage"
      ),
    "RAFSyncLogIndexListPage"
  )
);

const TaskPage = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/ActiveContacts/Task/TaskPage"),
    "TaskPage"
  )
);
const WorkspaceDetails = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/Common/Workspace/WorkspaceDetails"),
    "WorkspaceDetails"
  )
);
const RAFDetails = lazy(() =>
  lazyRetry(() => import("../RAFModules/Common/Details"), "RAFDetails")
);
const ManageFormComponent = lazy(() =>
  lazyRetry(
    () => import("./../RAFMaster/RMModules/FormLibrary/ManageFormComponent"),
    "ManageFormComponent"
  )
);
const FormLibraryIndex = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFMaster/RMModules/FormLibrary/FormLibraryIndex/FormLibraryIndex"
      ),
    "FormLibraryIndex"
  )
);

const RAFDataListPage = lazy(() =>
  lazyRetry(
    () => import("./../RAFMaster/RMModules/RAFDataList/RAFDataListPage"),
    "RAFDataListPage"
  )
);

const RAFRecordListPage = lazy(() =>
  lazyRetry(
    () => import("./../RAFMaster/RMModules/RAFDataList/RAFRecordListPage"),
    "RAFRecordListPage"
  )
);

const ProcessLibraryIndexPage = lazy(
  () =>
    import("./../RAFMaster/RMModules/ProcessLibrary/ProcessLibraryIndexPage")
);

const RAFManageProcessTemplate = lazy(
  () =>
    import("./../RAFMaster/RMModules/ProcessLibrary/RAFManageProcessTemplate")
);

const RAFCreateProcessPage = lazy(
  () => import("./../RAFMaster/RMModules/ProcessLibrary/CreateProcessPage")
);

const RAFUpdateProcessPage = lazy(
  () => import("./../RAFMaster/RMModules/ProcessLibrary/UpdateProcessPage")
);

const RAFDetailsProcessPage = lazy(
  () => import("./../RAFMaster/RMModules/ProcessLibrary/ProcessDetails")
);

const ProcessLibraryRecordIndexPage = lazy(
  () => import("../RAFMaster/RMModules/ProcessLibrary/ProcessRecordsIndexPage")
);

const AccountDetails = lazy(() =>
  lazyRetry(
    () => import("./../RAFModules/ActiveContacts/Account/AccountDetails"),
    "AccountDetails"
  )
);
const ContactDetails = lazy(() =>
  lazyRetry(
    () => import("./../RAFModules/ActiveContacts/Contact/ContactDetails"),
    "ContactDetails"
  )
);
const ContactDetails1 = lazy(() =>
  lazyRetry(
    () => import("./../RAFModules/ActiveContacts/Contact/ContactDetails1"),
    "ContactDetails1"
  )
);
const ContractDetails = lazy(() =>
  lazyRetry(
    () => import("./../RAFModules/ActiveContacts/Contract/ContractDetails"),
    "ContractDetails"
  )
);
const ContractPage = lazy(() =>
  lazyRetry(
    () => import("./../RAFModules/ActiveContacts/Contract/ContractPage"),
    "ContractPage"
  )
);
const DealDetails = lazy(() =>
  lazyRetry(
    () => import("./../RAFModules/ActiveContacts/Deal/DealDetails"),
    "DealDetails"
  )
);
const TicketDetails = lazy(() =>
  lazyRetry(
    () => import("./../RAFModules/ActiveContacts/Ticket/TicketDetails"),
    "TicketDetails"
  )
);

const UserDetails = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/ActiveContacts/User/UserDetails"),
    "UserDetails"
  )
);
const UserGroupDetailsPage = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/ActiveContacts/UserGroup/UserGroupDetails/UserGroupDetailsPage"),
    "UserGroupDetailsPage"
  )
);
const RAFCreate = lazy(() =>
  lazyRetry(
    () => import("./../RAFModules/Common/Create/RAFCreatePage"),
    "RAFCreatePage"
  )
);

const RAFUpdate = lazy(() =>
  lazyRetry(
    () => import("./../RAFModules/Common/Update/RAFUpdatePage"),
    "RAFUpdatePage"
  )
);

const RAFIndexPage = lazy(() =>
  lazyRetry(
    () => import("./../RAFModules/Common/List/RAFIndexPage"),
    "RAFIndexPage"
  )
);
const TaskDetailsPage = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/ActiveContacts/Task/TaskDetailsPage"),
    "TaskDetailsPage"
  )
);
// const CareRecipientDetailsCopy = lazy(() =>
//   lazyRetry(
//     () =>
//       import(
//         "../RAFModules/CareESIO/CareRecipient/Details/CareRecipientDetailsCopy"
//       ),
//     "CareRecipientDetailsCopy"
//   )
// );

const CareRecipientDetails = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/CareESIO/CareRecipient/Details/CareRecipientDetails"
      ),
    "CareRecipientDetails"
  )
);

const EmployeeDetailsPage = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/CareESIO/Employee/Details/EmployeeDetailsPage"),
    "EmployeeDetailsPage"
  )
);

const EmployeeLeaveDetailsPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/CareESIO/Employee/EmployeeLeave/EmployeeLeaveDetailsPage"
      ),
    "EmployeeLeaveDetailsPage"
  )
);
const PriceListDetails = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/CareESIO/PriceList/Details"),
    "PriceListDetails"
  )
);
const ChecklistTemplateDetails = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/CareESIO/ChecklistTemplate/ChecklistTemplateDetails"
      ),
    "ChecklistTemplateDetails"
  )
);
const RafChecklistIndexPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/CareESIO/ChecklistItemTrans/List/RafChecklistIndexPage"
      ),
    "RafChecklistIndexPage"
  )
);

const NDISPriceGuideDetails = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/CareESIO/NDISPriceGuide/Details"),
    "NDISPriceGuideDetails"
  )
);

const IncidentDetails = lazy(() =>
  lazyRetry(
    () =>
      import("../RAFModules/CareESIO/CQIRegister/Incident/RAFIncidentDetails"),
    "IncidentDetails"
  )
);
const AcknowledgementTrackingDetailsPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/ActiveContacts/Document/AcknowledgementTracking/AcknowledgementTrackingDetailsPage"
      ),
    "AcknowledgementTrackingDetailsPage"
  )
);
const ManageFormComponent1 = lazy(() =>
  lazyRetry(
    () => import("../RAFMaster/RMModules/FormLibrary/ManageFormComponent1"),
    "ManageFormComponent1"
  )
);

const XeroIntegration = lazy(() =>
  lazyRetry(
    () => import("../RAFMaster/RMModules/RAFAppIntegrations/Xero"),
    "XeroIntegration"
  )
);


const RAFChat = lazy(() =>
  lazyRetry(
    () => import("../RAFModules/Common/RAFMessenger/RAFChat"),
    "RAFChat"
  )
);

const AccountPage = lazy(() =>
  lazyRetry(
    () => import("./../RAFModules/ActiveContacts/Account/AccountPage"),
    "AccountPage"
  )
);

const ContactPage = lazy(() =>
  lazyRetry(
    () => import("./../RAFModules/ActiveContacts/Contact/ContactPage"),
    "ContactPage"
  )
);
const AutomationIndexPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        "../RAFModules/CareESIO/CareShiftLog/Automation/AutomationIndexPage"
      ),
    "AutomationIndexPage"
  )
);
//const SwitchComponent = this.state.animate ? SwitchWithSlide : Routes;
//const SwitchComponent = SwitchWithSlide ;

export const AppRouter = () => {
  let location = useLocation();
  return (
    <Suspense fallback={<div></div>}>
      <Routes
        location={location}
      //key={location.pathname}
      >
        <Route
          //exact
          key={"/login"}
          path={"/login"}
          element={<LoginPage />}
        />
        <Route
          //exact
          key={"/logout/:accountType?"}
          path={"/logout/:accountType?"}
          element={<LogoutPage />}
        />

        <Route key={"/Logout"} path={"/Logout"} element={<LogoutPage />} />

        <Route
          key={"/activate"}
          path={"/activate"}
          element={<ActivatePage />}
        />
        <Route key={"/"} path={"/"} element={<Dashboard />} />
        <Route key={"/Inbox"} path={"/Inbox"} element={<UserFeedIndexPage />} />
        <Route
          key={"/menu"}
          path={"/menu"}
          element={<NavigationMenuTreeView mode="FullScreen" />}
        />
        <Route
          key={"/authsuccess"}
          path={"/authsuccess"}
          element={<AuthSuccessPage />}
        />
        {/* <Route
          key={"/Home"}
          path={"/Home"}
          element={
            !msalInstance.isAuthenticated ? (
              <Navigate to={"/"} />
            ) : (
              <div className="page-wrapper-Container">
                {" "}
                <Dashboard />
              </div>
            )
          }
        /> */}
        <Route
          key={"/TaskDashboard"}
          path={"/TaskDashboard"}
          element={
            <div className="page-wrapper-Container">
              <TaskDashboard />
            </div>
          }
        />
        <Route
          key={"/action_center"}
          path={"/action_center"}
          element={
            <div className="page-wrapper-Container">
              <PlannerTaskIndexListPage />
            </div>
          }
        />
        <Route
          key={"/Schedule"}
          path={"/Schedule"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ServiceTransactionIndexPage />
            </div>
          }
        />
        <Route
          key={"/bookings"}
          path={"/bookings"}
          element={
            <div className="page-wrapper-Container">
              <ServiceTransactionIndexPage scheduleType="SupportLog" />
            </div>
          }
        />
        <Route
          key={"/bookings/View/:id"}
          path={"/bookings/View/:id"}
          element={
            <div className="page-wrapper-Container">
              <ServiceTransactionDetailsPage scheduleType="Bookings" />
            </div>
          }
        />
        <Route
          key={"/myshifts"}
          path={"/myshifts"}
          element={
            <div className="page-wrapper-Container">
              <ServiceTransactionIndexPage scheduleType="MyShift" />
            </div>
          }
        />
        <Route
          key={"/roster"}
          path={"/roster"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ServiceTransactionIndexPage scheduleType="Roster" />
            </div>
          }
        />
        <Route
          key={"/shiftoffers"}
          path={"/shiftoffers"}
          element={
            <div className="page-wrapper-Container">
              <ShiftOfferIndexPage />
            </div>
          }
        />
        <Route
          key={"/supportlog"}
          path={"/supportlog"}
          element={
            <div className="page-wrapper-Container">
              <CareSupportLogIndexPage />
            </div>
          }
        />
        <Route
          key={"/automation/list"}
          path={"/automation/list"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFAUtomationIndexPage />
            </div>
          }
        />
        <Route
          key={"/SwitchTenant"}
          path={"/SwitchTenant"}
          element={
            <div className="page-wrapper-Container">
              <SwitchTenantPage mode="Page" />
            </div>
          }
        />
        <Route
          key={"/Payroll"}
          path={"/Payroll"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <TimeSheetListPage shiftType="Payroll" />
            </div>
          }
        />

        <Route
          key={"/XeroSync"}
          path={"/XeroSync"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFXeroSynctListPage shiftType="Payroll" />
            </div>
          }
        />
        <Route
          key={"/XeroSyncLog"}
          path={"/XeroSyncLog"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFSyncLogIndexListPage />
            </div>
          }
        />
        <Route
          key={"/Billing"}
          path={"/Billing"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFCareBillingListPage shiftType="Billing" />
            </div>
          }
        />
        <Route
          key={"/Billing1"}
          path={"/Billing1"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <TimeSheetListPage shiftType="Billing" />
            </div>
          }
        />
        <Route
          key={"/Email/List"}
          path={"/Email/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFIndexPage
                key={RAFEntityName.Email}
                moduleName={RAFEntityName.Email}
              />
              {/* <EmailPage /> */}
            </div>
          }
        />
        <Route
          key={"/Email/List/:id"}
          path={"/Email/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFIndexPage
                key={RAFEntityName.Email}
                moduleName={RAFEntityName.Email}
              />
              {/* <EmailPage /> */}
            </div>
          }
        />
        <Route
          key={"/Task/List/:id"}
          path={"/Task/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <TaskPage />
            </div>
          }
        />
        <Route
          key={"/Company/List/:id"}
          path={"/Company/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <AccountPage /> */}
              <RAFIndexPage
                key={RAFEntityName.Account}
                moduleName={RAFEntityName.Account}
              />
            </div>
          }
        />
        <Route
          key={"/Company/List"}
          path={"/Company/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <AccountPage /> */}
              <RAFIndexPage
                key={RAFEntityName.Account}
                moduleName={RAFEntityName.Account}
              />
            </div>
          }
        />
        <Route
          key={"/Company1/List"}
          path={"/Company1/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <AccountPage />
              {/* <RAFIndexPage
                key={RAFEntityName.Account}
                moduleName={RAFEntityName.Account}
              /> */}
            </div>
          }
        />
        <Route
          key={"/Company/View/:id"}
          path={"/Company/View/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <AccountDetails />
            </div>
          }
        />
        <Route
          key={"/Contact/List/:id"}
          path={"/Contact/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <ContactPage /> */}
              <RAFIndexPage
                key={RAFEntityName.Contact}
                moduleName={RAFEntityName.Contact}
              />
            </div>
          }
        />
        <Route
          key={"/Contact/List"}
          path={"/Contact/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <ContactPage /> */}
              <RAFIndexPage
                key={RAFEntityName.Contact}
                moduleName={RAFEntityName.Contact}
              />
            </div>
          }
        />
        <Route
          key={"/Contact1/List"}
          path={"/Contact1/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ContactPage />
              {/* <RAFIndexPage
                key={RAFEntityName.Contact}
                moduleName={RAFEntityName.Contact}
              /> */}
            </div>
          }
        />
        <Route
          key={"/Category/List"}
          path={"/Category/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <ContactPage /> */}
              <RAFIndexPage
                key={RAFEntityName.Category}
                moduleName={RAFEntityName.Category}
              //leftMenuMode={'Category'}
              />
            </div>
          }
        />
        <Route
          key={"/SubCategory/List"}
          path={"/SubCategory/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <ContactPage /> */}
              <RAFIndexPage
                key={RAFEntityName.RecordCategory}
                moduleName={RAFEntityName.RecordCategory}
                leftMenuMode={"Category"}
              />
            </div>
          }
        />
        <Route
          key={"/Contact/View/:id"}
          path={"/Contact/View/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ContactDetails />
            </div>
          }
        />
        <Route
          key={"/Contact/View1/:id"}
          path={"/Contact/View1/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ContactDetails1 />
            </div>
          }
        />
        <Route
          key={"/Deal/List/:id"}
          path={"/Deal/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <DealPage /> */}
              <RAFIndexPage
                key={RAFEntityName.Deal}
                moduleName={RAFEntityName.Deal}
              />
            </div>
          }
        />
        <Route
          key={"/Deal/List"}
          path={"/Deal/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <DealPage /> */}
              <RAFIndexPage
                key={RAFEntityName.Deal}
                moduleName={RAFEntityName.Deal}
              />
            </div>
          }
        />
        <Route
          key={"/Deal/View/:id"}
          path={"/Deal/View/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <DealDetails />
            </div>
          }
        />
        <Route
          key={"/Ticket/List/:id"}
          path={"/Ticket/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <TicketPage /> */}
              <RAFIndexPage
                key={RAFEntityName.Ticket}
                moduleName={RAFEntityName.Ticket}
              />
            </div>
          }
        />
        <Route
          key={"/Ticket/List"}
          path={"/Ticket/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <TicketPage /> */}
              <RAFIndexPage
                key={RAFEntityName.Ticket}
                moduleName={RAFEntityName.Ticket}
              />
            </div>
          }
        />
        <Route
          key={"/Ticket/View/:id"}
          path={"/Ticket/View/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <TicketDetails />
            </div>
          }
        />
        <Route
          key={"/tenantSettings"}
          path={"/tenantSettings"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <UserGroupPage /> */}
              <TenantSettingsDetails key={RAFEntityName.TenantSettings} />
            </div>
          }
        />
        <Route
          key={"/Team/List"}
          path={"/Team/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <UserGroupPage /> */}
              <RAFIndexPage
                key={RAFEntityName.Team}
                moduleName={RAFEntityName.Team}
              />
            </div>
          }
        />
        <Route
          key={"/Team/List/:id"}
          path={"/Team/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <UserGroupPage /> */}
              <RAFIndexPage
                key={RAFEntityName.Team}
                moduleName={RAFEntityName.Team}
              />
            </div>
          }
        />
        <Route
          key={"/team/View/:id"}
          path={"/team/View/:id"}
          element={
            <div className="page-wrapper-Container">
              <UserGroupDetailsPage />
            </div>
          }
        />
        <Route
          key={"/RAF/View/team/:id"}
          path={"/RAF/View/team/:id"}
          element={
            <div className="page-wrapper-Container">
              <UserGroupDetailsPage />
            </div>
          }
        />
        <Route
          key={"/User/List"}
          path={"/User/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <UserPage />
               */}
              <RAFIndexPage
                key={RAFEntityName.User}
                moduleName={RAFEntityName.User}
              />
            </div>
          }
        />
        <Route
          key={"/User/List/:id"}
          path={"/User/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <UserPage /> */}
              <RAFIndexPage
                key={RAFEntityName.User}
                moduleName={RAFEntityName.User}
              />
            </div>
          }
        />
        <Route
          key={"/User/View/:id"}
          path={"/User/View/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <UserDetails />
            </div>
          }
        />
        <Route
          key={"/myprofile"}
          path={"/myprofile"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <UserDetails showCurrentUserProfile />
            </div>
          }
        />
        <Route
          key={"/MyNotifications"}
          path={"/MyNotifications"}
          element={
            <div className="page-wrapper-Container">
              <div className="container-fluid px-0">
                <RAFDeletedRecordState
                  title="Notifications Coming Soon...."
                  showBackBtn
                />
              </div>
            </div>
          }
        />
        <Route
          key={"/MySignature"}
          path={"/MySignature"}
          element={
            <div className="page-wrapper-Container">
              <RAFIndexPage
                key={RAFEntityName.Template}
                moduleName={RAFEntityName.Template}
              />
            </div>
          }
        />
        <Route
          key={"/Module/List"}
          path={"/Module/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <EntityPage />
            </div>
          }
        />
        <Route
          key={"/Module/List/:id"}
          path={"/Module/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <EntityPage />
            </div>
          }
        />
        <Route
          key={"/Module/View/:id"}
          path={"/Module/View/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <EntityDetails />
            </div>
          }
        />
        <Route
          key={"/Template/List"}
          path={"/Template/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFIndexPage
                key={RAFEntityName.Template}
                moduleName={RAFEntityName.Template}
              //leftMenuMode="Category"
              />
              {/* <TemplatePage /> */}
            </div>
          }
        />
        <Route
          key={"/Template/List/:id"}
          path={"/Template/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFIndexPage
                key={RAFEntityName.Template}
                moduleName={RAFEntityName.Template}
              //leftMenuMode="Category"
              />
              {/* <TemplatePage /> */}
            </div>
          }
        />
        <Route
          key={"/Contract/List/:id"}
          path={"/Contract/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ContractPage />
            </div>
          }
        />
        <Route
          key={"/Contract/View/:id"}
          path={"/Contract/View/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ContractDetails />
            </div>
          }
        />
        {/* <Route
                    key={"/ContentLibrary/List/:id"}
                    path={"/ContentLibrary/List/:id"}
                    element={
                        !msalInstance.isAuthenticated ? (
                            <Navigate to={"/"} />
                        ) : (
                            <div className="page-wrapper-Container">
                                {" "}
                                <RAFBusinessProcessTemplatePage />
                            </div>
                        )
                    }
                /> */}
        <Route
          key={"/ContentLibrary/Manage/:id"}
          path={"/ContentLibrary/Manage/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ManageBusinessProcessTemplate />
            </div>
          }
        />

        <Route
          key={"/ContentLibrary/Manage2/:id"}
          path={"/ContentLibrary/Manage2/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ManageBusinessProcessTemplate2 />
            </div>
          }
        />
        <Route
          key={"/ContentLibrary/Manage3/:id"}
          path={"/ContentLibrary/Manage3/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ManageBusinessProcessTemplate3 />
            </div>
          }
        />
        <Route
          key={"/Process/Manage/:id"}
          path={"/Process/Manage/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFBusinessProcessDetails />
            </div>
          }
        />
        {/* <Route
          key={"/Process/View/:id"}
          path={"/Process/View/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ManageBusinessProcess />
            </div>
          }
        /> */}
        <Route
          key={"/FormFlow/View/:id"}
          path={"/FormFlow/View/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ManageBusinessProcessView />
            </div>
          }
        />
        <Route
          key={"/Process/View1/:id"}
          path={"/Process/View1/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ManageBusinessProcessV1 />
            </div>
          }
        />
        <Route
          key={"/Process/View2/:id"}
          path={"/Process/View2/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ManageBusinessProcessV2 />
            </div>
          }
        />
        <Route
          key={"/Document/List/:id"}
          path={"/Document/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFDocumentIndexPage />
            </div>
          }
        />
        <Route
          key={"/Document/Create"}
          path={"/Document/Create"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFDocumentCreate />
            </div>
          }
        />
        <Route
          key={"/Document/List"}
          path={"/Document/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFDocumentIndexPage />
            </div>
          }
        />
        <Route
          key={"/Document/View/:id"}
          path={"/Document/View/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <RAFHtmlPageContentDetails /> */}
              <DocumentContentDetails />
            </div>
          }
        />
        <Route
          key={"/Document/detail/:id"}
          path={"/Document/detail/:id"}
          element={
            <div className="page-wrapper-Container">
              <DocumentPageContentDetails />
            </div>
          }
        />
        <Route
          key={"/Document1/View/:id"}
          path={"/Document1/View/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <DocumentContentDetailsCopy />
            </div>
          }
        />
        <Route
          key={"/DocumentLibrary/View/:id"}
          path={"/DocumentLibrary/View/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <DocumentContentDetailsCopy />
            </div>
          }
        />
        <Route
          key={"/DocumentLibrary/List"}
          path={"/DocumentLibrary/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFDocumentIndexPage />
            </div>
          }
        />
        <Route
          key={"/DocumentLibrary/List/:id"}
          path={"/DocumentLibrary/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFDocumentIndexPage />
            </div>
          }
        />
        {/*<Route key={"/FormLibrary/List"} path={"/FormLibrary/List"} element={!msalInstance.isAuthenticated ? <Navigate to={'/'} /> : <div className="page-wrapper-Container"> <RAFFormLibraryPage /></div>} />*/}
        <Route
          key={"/FormLibrary/List/"}
          path={"/FormLibrary/List/"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <FormLibraryIndex
                moduleName={RAFEntityName.FormLibrary}
                indexMode={"Form Library"}
              />
              {/* <RAFFormLibraryListPage /> */}
            </div>
          }
        />
        <Route
          key={"/FormLibrary/List/admin"}
          path={"/FormLibrary/List/admin"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <FormLibraryIndex
                moduleName={RAFEntityName.FormLibrary}
                indexMode={"Form Library"}
              />
              {/* <RAFFormLibraryListPage /> */}
            </div>
          }
        />
        <Route
          key={"/FormLibrary/Manage/:id"}
          path={"/FormLibrary/Manage/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <ManageFormComponent1 /> */}
              <ManageFormPage />
            </div>
          }
        />
        <Route
          key={"/FormLibrary/Designer/:id"}
          path={"/FormLibrary/Designer/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ManageDataListSurvey />
            </div>
          }
        />
        <Route
          key={"/FormLibraryRecord/Create/:formId"}
          path={"/FormLibraryRecord/Create/:formId"}
          element={<FormLibraryRecordCreatePage />}
        />
        <Route
          key={"/FormLibraryRecord/Update/:formId"}
          path={"/FormLibraryRecord/Update/:formId"}
          element={<FormLibraryRecordUpdatePage />}
        />
        <Route
          key={"/FormLibraryRecord/View/:recordId"}
          path={"/FormLibraryRecord/View/:recordId"}
          element={<FormLibraryDataRecordsDetails />}
        />
        <Route
          key={"/FormLibraryRecord/Records/:recordId"}
          path={"/FormLibraryRecord/Records/:recordId"}
          element={<FormLibraryDataRecordsIndexPage />}
        />
        <Route
          key={"/DataList/List"}
          path={"/DataList/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFDataListPage />
            </div>
          }
        />
        <Route
          key={"/DataList/Manage/:id"}
          path={"/DataList/Manage/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ManageFormLibrary />
            </div>
          }
        />
        <Route
          key={"/DataList/Manage1/:id"}
          path={"/DataList/Manage1/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ManageDataList />
            </div>
          }
        />
        <Route
          key={"/DataList/Manage2/:id"}
          path={"/DataList/Manage2/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ManageDataListSurvey />
            </div>
          }
        />

        <Route
          key={"/Form/List/:id"}
          path={"/Form/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFRecordListPage />
            </div>
          }
        />

        <Route
          key={"/ProcessLibrary/List"}
          path={"/ProcessLibrary/List"}
          element={
            <ProcessLibraryIndexPage selectedCategoryType="Process Library" />
          }
        />

        <Route
          key={"/ProcessLibrary/Designer/:formId"}
          path={"/ProcessLibrary/Designer/:formId"}
          element={<RAFManageProcessTemplate />}
        />

        <Route
          key={"/Process/Create/:formId"}
          path={"/Process/Create/:formId"}
          element={<RAFCreateProcessPage />}
        />

        <Route
          key={"/Process/Update/:recordId"}
          path={"/Process/Update/:recordId"}
          element={<RAFUpdateProcessPage />}
        />

        <Route
          key={"/Process/View/:recordId"}
          path={"/Process/View/:recordId"}
          element={<RAFDetailsProcessPage />}
        />

        <Route
          key={"/ProcessLibrary/Records/:formId"}
          path={"/ProcessLibrary/Records/:formId"}
          element={<ProcessLibraryRecordIndexPage />}
        />

        <Route
          key={"/View/List"}
          path={"/View/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ViewPage />
            </div>
          }
        />

        <Route
          key={"/Report/List"}
          path={"/Report/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ReportAnalysisPage />
            </div>
          }
        />

        <Route
          key={"/Quality/List"}
          path={"/Quality/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <QualityPage />
            </div>
          }
        />

        <Route
          key={"/Automations/List"}
          path={"/Automations/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <AutomationIndexPage />
            </div>
          }
        />

        <Route
          key={"/ContentLibrary/List/:id"}
          path={"/ContentLibrary/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <RAFBusinessProcessTemplateListPage /> */}
              <RAFBPTemplateIndexPage
                moduleName={RAFEntityName.BusinessProcessTemplate}
              />
            </div>
          }
        />
        {/* <Route
          key={"/ProcessLibrary/List"}
          path={"/ProcessLibrary/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFBPTemplateIndexPage
                moduleName={RAFEntityName.BusinessProcessTemplate}
              />
            </div>
          }
        /> */}
        <Route
          key={"/FormFlow/List"}
          path={"/FormFlow/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <RAFBusinessProcessTemplateListPage /> */}
              <RAFProcessTemplateIndexPage
                moduleName={RAFEntityName.BusinessProcessTemplate}
              />
            </div>
          }
        />
        <Route
          key={"/FormFlow/List/:id"}
          path={"/FormFlow/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <RAFBusinessProcessTemplateListPage /> */}
              <RAFProcessTemplateIndexPage
                moduleName={RAFEntityName.BusinessProcessTemplate}
              />
            </div>
          }
        />
        {/* <Route
          key={"/ProcessLibrary/List/:id"}
          path={"/ProcessLibrary/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFBPTemplateIndexPage
                moduleName={RAFEntityName.BusinessProcessTemplate}
              />
            </div>
          }
        /> */}
        <Route
          key={"/ProcessLibrary/Manage/:id"}
          path={"/ProcessLibrary/Manage/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ManageBusinessProcessTemplate3 />
            </div>
          }
        />

        <Route
          key={"/FormFlow/Manage/:id"}
          path={"/FormFlow/Manage/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFManageBusinessProcessTemplate />
            </div>
          }
        />
        <Route
          key={"/ContentType/List"}
          path={"/ContentType/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFBusinessProcessTypeListPage />
            </div>
          }
        />
        <Route
          key={"/ContentType/List/:id"}
          path={"/ContentType/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFBusinessProcessTypeListPage />
            </div>
          }
        />
        <Route
          key={"/Process/List"}
          path={"/Process/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFRunningBusinessProcessListPage />
            </div>
          }
        />
        <Route
          key={"/Process/List/:id"}
          path={"/Process/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFRunningBusinessProcessListPage />
            </div>
          }
        />
        <Route
          key={"/ProcessMonitor/List"}
          path={"/ProcessMonitor/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <RAFBusinessProcessTemplateListPage /> */}
              <RAFProcessIndexPage moduleName={RAFEntityName.BusinessProcess} />
            </div>
          }
        />
        <Route
          key={"/ProcessMonitor/List/:id"}
          path={"/ProcessMonitor/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <RAFBusinessProcessTemplateListPage /> */}
              <RAFProcessIndexPage moduleName={RAFEntityName.BusinessProcess} />
            </div>
          }
        />
        <Route
          key={"/RAF/List/:modulename"}
          path={"/RAF/List/:modulename"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <RAFIndexPage /> */}
              <RAFIndexPage />
            </div>
          }
        />
        <Route
          key={"/RAF/List/:modulename/:id"}
          path={"/RAF/List/:modulename/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              {/* <RAFIndexPage /> */}
              <RAFIndexPage />
            </div>
          }
        />
        <Route
          key={"/RAF/View/:modulename/:id"}
          path={"/RAF/View/:modulename/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFDetails />
            </div>
          }
        />
        <Route
          key={"/RAF/Create/:modulename"}
          path={"/RAF/Create/:modulename"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFCreate />
            </div>
          }
        />
        <Route
          key={"/RAF/Update/:modulename/:id"}
          path={"/RAF/Update/:modulename/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFUpdate />
            </div>
          }
        />
        <Route
          key={"/Workflow/List"}
          path={"/Workflow/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <WorkFlowPage />
            </div>
          }
        />
        <Route
          key={"/Portal/List"}
          path={"/Portal/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFIndexPage
                key={RAFEntityName.Portal}
                moduleName={RAFEntityName.Portal}
              />
              {/* <WorkspacePage /> */}
            </div>
          }
        />
        <Route
          key={"/Portal/Manage/:id"}
          path={"/Portal/Manage/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <WorkspaceDetails />
            </div>
          }
        />
        <Route
          key={"/Complaint/List"}
          path={"/Complaint/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ComplaintPage />
            </div>
          }
        />
        <Route
          key={"/Complaint/List/:id"}
          path={"/Complaint/List/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ComplaintPage />
            </div>
          }
        />
        <Route
          key={"/Complaint/View/:id"}
          path={"/Complaint/View/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ComplaintDetails />
            </div>
          }
        />
        <Route
          key={"/Product/List"}
          path={"/Product/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ProductPage />
            </div>
          }
        />
        <Route
          key={"/RegisterTenant"}
          path={"/RegisterTenant"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RegisterTenantPage />
            </div>
          }
        />
        <Route
          key={"/Role/List"}
          path={"/Role/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RAFIndexPage
                key={RAFEntityName.Role}
                moduleName={RAFEntityName.Role}
              />
            </div>
          }
        />
        {/* <Route
          key={"/Role/List"}
          path={"/Role/List"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <RolePage />
            </div>
          }
        /> */}
        <Route
          key={"/Settings"}
          path={"/Settings"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <SystemSettingsPage />
            </div>
          }
        />
        <Route
          key={"/FormComponent/Manage/:id"}
          path={"/FormComponent/Manage/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ManageFormComponent />
            </div>
          }
        />
        <Route
          key={"/FormComponent/Manage1/:id"}
          path={"/FormComponent/Manage1/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <ManageFormComponent1 />
            </div>
          }
        />
        <Route
          key={"/task/retrieve/:id"}
          path={"/task/retrieve/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <TaskDetailsPage />
            </div>
          }
        />
        <Route
          key={"/task/view/:id"}
          path={"/task/view/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <TaskDetailsPage />
            </div>
          }
        />
        <Route
          key={"/raf/view/task/:id"}
          path={"/raf/view/task/:id"}
          element={
            <div className="page-wrapper-Container">
              {" "}
              <TaskDetailsPage />
            </div>
          }
        />
        {/* <Route
          key={"/CareRecipient/View"}
          path={"/CareRecipient/View"}
          element={
            <div className="page-wrapper-Container">
              <CareRecipientDetailsCopy />
            </div>
          }
        />
        <Route
          key={"/CareRecipient/View/:id"}
          path={"/CareRecipient/View/:id"}
          element={
            <div className="page-wrapper-Container">
              <CareRecipientDetailsCopy />
            </div>
          }
        /> */}
        {/* <Route
          key={"/RAF/View/care_recipient1/:id"}
          path={"/RAF/View/care_recipient1/:id"}
          element={
            <div className="page-wrapper-Container">
              <CareRecipientDetailsCopy />
            </div>
          }
        /> */}
        <Route
          key={"/RAF/View/care_recipient/:id"}
          path={"/RAF/View/care_recipient/:id"}
          element={
            <div className="page-wrapper-Container">
              <CareRecipientDetails />
            </div>
          }
        />
        <Route
          key={"/employee/View/:id"}
          path={"/employee/View/:id"}
          element={
            <div className="page-wrapper-Container">
              <EmployeeDetailsPage />
            </div>
          }
        />
        <Route
          key={"/RAF/View/employee/:id"}
          path={"/RAF/View/employee/:id"}
          element={
            <div className="page-wrapper-Container">
              <EmployeeDetailsPage />
            </div>
          }
        />
        <Route
          key={"/leavemanagement/list"}
          path={"/leavemanagement/list"}
          element={
            <div className="page-wrapper-Container">
              <RAFIndexPage
                moduleName={RAFEntityName.EmployeeLeave}
                headerTitle='Leave Management'
              />
            </div>
          }
        />
        <Route
          key={"/leavemanagement/View/:id"}
          path={"/leavemanagement/View/:id"}
          element={
            <div className="page-wrapper-Container">
              <EmployeeLeaveDetailsPage />
            </div>
          }
        />
        <Route
          key={"/RAF/View/employee_profile/:id"}
          path={"/RAF/View/employee_profile/:id"}
          element={
            <div className="page-wrapper-Container">
              <EmployeeDetailsPage />
            </div>
          }
        />

        <Route
          key={"/RAF/View/pricelist/:id"}
          path={"/RAF/View/pricelist/:id"}
          element={
            <div className="page-wrapper-Container">
              <PriceListDetails />
            </div>
          }
        />
        <Route
          key={"/RAF/View/checklist_template/:id"}
          path={"/RAF/View/checklist_template/:id"}
          element={
            <div className="page-wrapper-Container">
              <ChecklistTemplateDetails />
            </div>
          }
        />
        <Route
          key={"/compliance/List"}
          path={"/compliance/List"}
          element={
            <div className="page-wrapper-Container">
              <RafChecklistIndexPage />
            </div>
          }
        />
        <Route
          key={"/compliance/List/:id"}
          path={"/compliance/List/:id"}
          element={
            <div className="page-wrapper-Container">
              <RafChecklistIndexPage />
            </div>
          }
        />
        <Route
          key={"/RAF/View/ndis_price_guide/:id"}
          path={"/RAF/View/ndis_price_guide/:id"}
          element={
            <div className="page-wrapper-Container">
              <NDISPriceGuideDetails />
            </div>
          }
        />
        <Route
          key={"/RAF/View/incident/:id"}
          path={"/RAF/View/incident/:id"}
          element={
            <div className="page-wrapper-Container">
              <IncidentDetails />
            </div>
          }
        />
        <Route
          key={"/RAF/View/acknowledgement_tracking/:id"}
          path={"/RAF/View/acknowledgement_tracking/:id"}
          element={
            <div className="page-wrapper-Container">
              <AcknowledgementTrackingDetailsPage />
            </div>
          }
        />
        <Route
          key={"/Integration"}
          path={"/Integration"}
          element={
            <div className="page-wrapper-Container">
              <XeroIntegration />
            </div>
          }
        />
        <Route
          key={"/chat"}
          path={"/chat"}
          element={
            <div className="page-wrapper-Container">
              <RAFChat />
            </div>
          }
        />
        {/*{pages.map((page) => {
                    return (
                        <Route
                            key={page.path}
                            path={page.path}
                            element={
                                            !msalInstance.isAuthenticated ? <Navigate to={'/'} /> : <div className="page-wrapper-Container"> <page.Component /></div>
                                       
                            }
                        />
                    );
                })}*/}
      </Routes>
    </Suspense>
  );
  //}
};

export interface NavigateParamsProps {
  params?: any;
  navigate?: NavigateFunction;
}

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
        params={params}
        navigate={navigate}
      />
    );
  }

  return ComponentWithRouterProp;
}
