import { ContentType } from "../../../constants/Common/Constants";
import {
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
  IsNullOrWhiteSpace,
  propertyOf,
} from "../../../RAFComponents/helpers/utils";
import { ActivityListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { RAFCustomFilter } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { ActivityRow, RAFActivityCommentType } from "./ActivityRow";

export enum RAFActivityAction {
  Added = "Added",
  Modified = "Modified",
}

export const createUpdateActivityRow = (activityRow: ActivityRow) => {
  return new Promise<{ entityId: string; objectName: string }>((resolve) => {
    if (isNotNullAndUndefined(activityRow)) {
      const activityFormSubmit = { ...activityRow } as ActivityRow;
      if (IsNullOrWhiteSpace(activityFormSubmit.CommentType)) {
        activityFormSubmit.CommentType = RAFActivityCommentType.Note;
      }
      activityFormSubmit.Action = isNotNullAndUndefined(activityFormSubmit.UID)
        ? RAFActivityAction.Modified
        : RAFActivityAction.Added;

      repositoryActions
        .postDataAndGetResponse(
          "Activity/Save",
          //"Activity/SaveStream",
          getSaveRequest(activityFormSubmit, activityFormSubmit.UID),
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            resolve({
              entityId: response.data.EntityId,
              objectName: response.data.ObjectName,
            });
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
};

export const getAllActivityItems = (
  relatedToType: string,
  relatedToUID: string,
  secondaryRelatedToUID: string,
  secondaryRelatedToType: string,
  customFilter: RAFCustomFilter,
  sortQuery: string[],
  take: number,
  skip: number
) => {
  return new Promise<ActivityRow[]>((resolve) => {
    const listRequest: ActivityListServiceRequest =
      new ActivityListServiceRequest();

    if (isNotNullAndUndefined(relatedToUID)) {
      listRequest.RelatedToUID = relatedToUID;
    }
    if (isNotNullAndUndefined(relatedToType)) {
      listRequest.EntityName = relatedToType;
    }

    if (isNotNullAndUndefined(secondaryRelatedToUID)) {
      listRequest.SecondaryRelatedToUID = secondaryRelatedToUID;
    }

    if (isNotNullAndUndefined(secondaryRelatedToType)) {
      listRequest.SecondaryRelatedToType = secondaryRelatedToType;
    }

    const sortQueryBy: string[] = isNotEmptyArray(sortQuery)
      ? sortQuery
      : [`${propertyOf<ActivityRow>("ModifiedDate")} desc`];
    listRequest.Sort = sortQueryBy;

    if (isNotNullAndUndefined(customFilter)) {
      listRequest["CustomFilter"] = customFilter;
    }

    if (isNotNullAndUndefined(take) && isNotNullAndUndefined(skip)) {
      listRequest.Take = take;
      listRequest.Skip = skip;
    }

    repositoryActions
      .postDataAndGetResponse(
        "Activity/List",
        listRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => resolve(null));
  });
};

export const getPreviousShiftHandOvers = (id) => {
  return new Promise<ActivityRow[]>((resolve /*, reject*/) => {
    repositoryActions
      .postDataAndGetResponse(
        "Activity/PreviousShiftHandOvers",
        { EntityId: id },
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
};
