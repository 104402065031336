import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { IconButton, InputAdornment } from "@mui/material";
import { DatePicker, DesktopDatePicker, LocalizationProvider, MobileDatePicker, PickersActionBarAction } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, { PropsWithChildren } from "react";
import {
    IsNotNullOrWhiteSpace,
    IsNullOrWhiteSpace,
    isDate,
    isNotNullAndUndefined
} from "../../RAFComponents/helpers/utils";
import { BrowserIsDevice, MomentFormats } from "../../constants/Common/Constants";
import {
    convertUTCDateToLocalTimezone
} from "../helpers/utils";
import "./InputStyle.scss";

interface IProps {
    fieldName: string;
    inputvalue: Date;
    showClearButton: boolean;
    disabled: boolean;

    width?: number | string,

    onChangeMobileDatePicker: (value: Date) => void;
}

//MUI_mobile_time_picker start
const is12AmTime = (time) => {
    let is12AM = false;
    if (isNotNullAndUndefined(time)) {
        const date = new Date(time);
        if (date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0) {
            is12AM = false;// true;
        }
    }
    return is12AM;
};

const getComponentValueInMoment = (inputValue) => {
    if (IsNullOrWhiteSpace(inputValue)) {
        return null;
    }
    const formValue = inputValue;
    const momentObj = moment.parseZone(formValue);
    //const isUtc = momentObj.utcOffset() === 0;
    const isLocal = momentObj.utcOffset() !== 0;

    if (isLocal === true) {
        if (is12AmTime(formValue)) {
            return null;
        } else {
            return moment(formValue);
        }
    } else {
        let retVal = convertUTCDateToLocalTimezone(inputValue);
        if (IsNotNullOrWhiteSpace(inputValue)) {
            if (
                moment(retVal, moment.ISO_8601, true).isValid() &&
                !isDate(retVal)
            ) {
                if (is12AmTime(formValue)) {
                    return null;
                } else {
                    return moment(new Date(retVal + "Z"));
                }
            }
        }
        if (is12AmTime(formValue)) {
            return null;
        } else {
            return isNotNullAndUndefined(retVal) ? moment(retVal) : null;
        }
    }
};
//MUI_mobile_time_picker end

function RAFCustomResponsiveDatePicker({
    fieldName,
    disabled,
    inputvalue,
    showClearButton,
    width,
    ...props }: PropsWithChildren<IProps>) {
    const [openMobile, setOpenMobile] = React.useState(false);

    const muiDateTimePickerActionsBtns: PickersActionBarAction[] = showClearButton === false ? ["cancel", 'accept'] : ['clear', "cancel", 'accept'];


    const handleMobileOpen = () => {
        if (disabled) return;
        setOpenMobile(true);
    };

    const handleMobileClose = () => {
        setOpenMobile(false);
    };

    const onChangeMobileDatePicker = (value: Date) => {
        if (props.onChangeMobileDatePicker) {
            props.onChangeMobileDatePicker(value);
        }
    };

    const displayValue = getComponentValueInMoment(inputvalue);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            {BrowserIsDevice ? (//Mobile variant
                <>
                    <DesktopDatePicker
                        value={displayValue}
                        name={fieldName}
                        sx={{ width: width }}
                        slots={{
                            openPickerButton: () => (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleMobileOpen}>
                                        <CalendarTodayIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        slotProps={{
                            textField: {
                                InputProps: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleMobileOpen}>
                                                <CalendarTodayIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            },
                            field: { clearable: showClearButton === true ? true : false }
                        }}
                        onChange={(e) => {
                            const dateValue = isNotNullAndUndefined(e) ? e['_d'] : null;
                            const _isValid = isNotNullAndUndefined(e) ? e['_isValid'] : false;
                            if (IsNullOrWhiteSpace(dateValue)) {
                                onChangeMobileDatePicker(null);
                            } else if (_isValid) {
                                if (IsNotNullOrWhiteSpace(displayValue)) {
                                    onChangeMobileDatePicker(dateValue);
                                } else {
                                    const newDateValue = new Date(dateValue);
                                    const now = new Date();
                                    newDateValue.setHours(now.getHours(), now.getMinutes());
                                    onChangeMobileDatePicker(newDateValue);
                                }
                            }
                        }}
                        format={MomentFormats.DATE}
                        disabled={disabled}
                        className="raf_mui_input raf_date_picker"
                    />
                    {openMobile && ( //do not remove this div and comment this open on icon click from desktop time picker
                        <div style={{ display: 'none' }}>
                            <MobileDatePicker
                                value={displayValue}
                                name={fieldName}
                                sx={{ width: width }}
                                slotProps={{
                                    actionBar: {
                                        actions: muiDateTimePickerActionsBtns
                                    },
                                    field: { clearable: showClearButton === true ? true : false }
                                }}
                                onChange={(valueDayjs) => {
                                    const dateValue = IsNotNullOrWhiteSpace(valueDayjs) ? valueDayjs.toDate() : null;
                                    onChangeMobileDatePicker(dateValue);
                                }}
                                format={MomentFormats.DATE}
                                disabled={disabled}
                                className="raf_mui_input raf_date_picker"
                                open={openMobile}
                                onClose={handleMobileClose}
                                closeOnSelect
                            />
                        </div>
                    )}
                </>
            )
                :
                (//Desktop variant
                    <DatePicker
                        value={displayValue}
                        name={fieldName}
                        sx={{ width: width }}
                        slotProps={{
                            actionBar: {
                                actions: muiDateTimePickerActionsBtns
                            },
                            field: { clearable: showClearButton === true ? true : false }
                        }}
                        onChange={(e) => {
                            const dateValue = isNotNullAndUndefined(e) ? e['_d'] : null;
                            const _isValid = isNotNullAndUndefined(e) ? e['_isValid'] : false;
                            if (IsNullOrWhiteSpace(dateValue)) {
                                onChangeMobileDatePicker(null);
                            } else if (_isValid) {
                                if (IsNotNullOrWhiteSpace(displayValue)) {
                                    onChangeMobileDatePicker(dateValue);
                                } else {
                                    const newDateValue = new Date(dateValue);
                                    const now = new Date();
                                    newDateValue.setHours(now.getHours(), now.getMinutes());
                                    onChangeMobileDatePicker(newDateValue);
                                }
                            }
                        }}
                        format={MomentFormats.DATE}
                        disabled={disabled}
                        className="raf_mui_input raf_date_picker"
                    />
                )}
        </LocalizationProvider>
    );
}


export default React.memo(RAFCustomResponsiveDatePicker);