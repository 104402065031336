import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer
} from "react";
import { FormRenderProps } from "react-final-form";
import RAFForm from "../../../../RAFComponents/Inputs/RAFForm";
import RAFStartDateEndDatePicker from "../../../../RAFComponents/Inputs/RAFStartDateEndDatePicker";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RetrieveRecord, hideProgress, showProgress } from "../../../../RAFComponents/helpers/AppHelper";
import { IDialogProps, isNotNullAndUndefined, propertyOf } from "../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { RAFButtonConstant, RAFLayout, RAFShiftActionStatus } from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import RAFEntityProvider from "../../../Common/Providers/RAFEntityProvider";
import { saveServiceTransaction } from "../ServiceTransactionHelper";
import { ServiceTransactionRow } from "../ServiceTransactionRow";

interface IProps {
  objectUID: string;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  initialValues: ServiceTransactionRow;
}

function CompleteBookingServiceTransaction({
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  const outerDivId = "complete_booking_service_transaction_outerDiv";

  const moduleName = RAFEntityName.ServiceTransaction;

  let rafFormRef: FormRenderProps | null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: false,
      initialValues: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.objectUID]);

  const refresh = async () => {
    setState({ isLoading: true });
    if (isNotNullAndUndefined(props.objectUID)) {

      const initialValues: ServiceTransactionRow = await RetrieveRecord(
        props.objectUID,
        RAFEntityName.ServiceTransaction
      );

      if (isNotNullAndUndefined(initialValues) && isNotNullAndUndefined(initialValues.UID)) {

        setState({
          noContent: false,
          initialValues,
          isLoading: false,
        });
      } else {
        setState({ noContent: true, isLoading: false });
      }

    } else {
      setState({ noContent: true, isLoading: false });
    }
  };

  const onSubmitForm = async (formValue: ServiceTransactionRow) => {
    let progressDiv = showProgress(`#${outerDivId}`);

    const submitValue: ServiceTransactionRow = R.clone(state.initialValues);
    submitValue.Status = RAFShiftActionStatus.Completed;
    submitValue.ActualStartDate = formValue.StartDate;
    submitValue.ActualEndDate = formValue.EndDate;
    submitValue.ActualDuration = formValue.Duration;

    const serviceTransactionResponse = await saveServiceTransaction(submitValue);

    hideProgress(progressDiv);
    if (props.onSave) {
      props.onSave();
    }
  };

  const getFormContent = () => {
    return (
      <div id={outerDivId}>
        <RAFEntityProvider moduleName={moduleName}>
          <RAFAttributeRelatedListProvider moduleName={moduleName}>
            <RAFForm
              initialValues={state.initialValues}
              layout={RAFLayout.TwoColumnLayout}
              submitOnEnterKey={false}
              formRef={(g) => {
                return (rafFormRef = g);
              }}
              onSubmit={onSubmitForm}
            >
              <div className="row gy-4 g-0 flex-column text-align-center form-group-margin-0">
                <div className="col-md-12">
                  <RAFStartDateEndDatePicker
                    field={propertyOf<ServiceTransactionRow>("StartDate")}
                    endDateField={propertyOf<ServiceTransactionRow>("EndDate")}
                    label="Start Date"
                    required
                    formGroupClassName="mb-0"
                    interval={15}
                    startEndDateDurationHrs={2}
                    defaultEndDateInput="DateTimePicker"
                    roundOff
                  />
                </div>
              </div>
            </RAFForm>
          </RAFAttributeRelatedListProvider>
        </RAFEntityProvider>
      </div>
    );
  };

  if (state.isLoading === false) {
    if (state.noContent === false) {
      return (
        <div className="h-100" key={moduleName}>
          <div className="e-dlg-content-outer">
            <div className="e-dlg-body-content">{getFormContent()}</div>
            <div className="e-dlg-footerContent ">
              <RAFButtonComponent
                action={RAFButtonConstant.Complete}
                showIcon={false}
                className="btn_state_success semi_dark form-custom-button"
                onClick={() => rafFormRef && rafFormRef.form.submit()}
                type="submit"
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="e-dlg-content-outer">
          <div className="e-dlg-body-content">
            <RAFDeletedRecordState title="You do not have sufficient privileges to edit this item." />
          </div></div>
      );
    }
  } else {
    return (
      <div className="container-fluid px-0">
        <ACLoadingPanel loadingText="Preparing Data..." />
      </div>
    );
  }
}

export default React.memo(CompleteBookingServiceTransaction);
