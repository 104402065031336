import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { getFormatedDate } from "../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import {
  IsNotNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  getDate,
  getTimeDifferenceInUserReadableText,
  isNotEmptyArray,
  isNotNullAndUndefined
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import nodata_es from "../../../assets/ES/nodata_es.svg";
import {
  RAFActionStatus
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { NavigateParamsProps } from "../../../router";
import { ServiceTransactionRow } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import UserProfilePhoto from "../../ActiveContacts/User/UserProfilePhoto";
import { ShiftActivityTitle } from "../CareShiftLog/CareShiftLogHelper";
import CareShiftLogSection from "../CareShiftLog/CareShiftLogSection";

interface IProps {
  careShiftLogs: any;
  allRelatedServiceTransactions: ServiceTransactionRow[];
  careRecipientUID: string;
  allForms: FormLibraryRow[];
  isDashBoard: boolean;
  onSave: () => void;
}

function CareSupportLogActivities({
  careShiftLogs,
  allRelatedServiceTransactions,
  careRecipientUID,
  allForms,
  ...props
}: PropsWithChildren<IProps & NavigateParamsProps>) {
  let navigate = useNavigate();

  const refresh = () => {
    if (props.onSave) {
      props.onSave();
    }
  };

  function careTaskSectionCustomHeader(serviceTransactionRow) {
    let taskTitle;
    let taskDateValue;
    let taskDayValue;
    let taskMonthValue;
    if (
      isNotNullAndUndefined(serviceTransactionRow.StartDate) &&
      isNotNullAndUndefined(serviceTransactionRow.EndDate)
    ) {
      taskDateValue = getFormatedDate(serviceTransactionRow.StartDate, "DD");
      taskMonthValue = getFormatedDate(serviceTransactionRow.StartDate, "MMM");
      // taskDayValue = getFormatedDate(serviceTransactionRow.StartDate, "ddd");
      taskTitle =
        getFormatedDate(serviceTransactionRow.StartDate, "h:mm a") +
        " - " +
        getFormatedDate(serviceTransactionRow.EndDate, "h:mm a");

      const objTaskDate: Date = convertUTCDateToLocalTimezone(
        serviceTransactionRow.StartDate
      );
      taskDayValue = getDate(objTaskDate, "ddd");
      if (
        taskDayValue !== "Today" &&
        taskDayValue !== "Yesterday" &&
        taskDayValue !== "Tomorrow"
      ) {
        taskDayValue = getFormatedDate(objTaskDate, "dddd");
      }
    }

    const durationDisplayText = getTimeDifferenceInUserReadableText(
      null,
      null,
      serviceTransactionRow.ActualDuration
    );

    let plannedStartDate = serviceTransactionRow.StartDate;
    let plannedEndDate = serviceTransactionRow.EndDate;
    let actualStartDate = serviceTransactionRow.ActualStartDate;
    let actualEndDate = serviceTransactionRow.ActualEndDate;

    let plannedStartDateTime = getFormatedDate(serviceTransactionRow.StartDate, "hh:mm a");
    let plannedEndDateTime = getFormatedDate(serviceTransactionRow.EndDate, "hh:mm a");
    let actualStartDateTime = getFormatedDate(
      serviceTransactionRow.ActualStartDate,
      "hh:mm a"
    );
    let actualEndDateTime = getFormatedDate(serviceTransactionRow.ActualEndDate, "hh:mm a");

    const plannedDurationDisplayText = getTimeDifferenceInUserReadableText(
      plannedStartDate,
      plannedEndDate,
      serviceTransactionRow.Duration
    );

    const careTaskSectionAssigneeStartEndContent = (
      actualStartDateTime,
      actualEndDateTime
    ) => {
      return (
        <div className={`row gx-sm-4 gx-2 flex-nowrap justify-content-between`}>
          <div className={`overflow-auto col`}>
            {/*<div className={`overflow-auto${state.showShiftLogContent ? ' col-auto' : ' col'}`} style={{ flex: 'unset' }}>*/}
            <div className="px-1">
              <span className="profile_card_details_label profile_card_details_label_noraml mb-2">
                Assignee
              </span>
              <div className="pt-1 d-flex align-items-center">
                {IsNotNullOrWhiteSpace(serviceTransactionRow.Assignee) ? (
                  <UserProfilePhoto
                    uid={serviceTransactionRow.AssigneeUID}
                    relatedToType={RAFEntityName.User}
                    canEdit={false}
                    profileImgCss={"profileImg-container-xsm"}
                    title={serviceTransactionRow.Assignee ?? "Not set"}
                    iconSize="24"
                    fontSize="10"
                    emptyAvatarType="Initial"
                  />
                ) : (
                  ""
                )}
                <span
                  className={`ecllipseFirstLine text-decoration-underline profile_card_details_value${IsNotNullOrWhiteSpace(serviceTransactionRow.Assignee) ? " ps-2" : ""
                    }`}
                >
                  {serviceTransactionRow.Assignee ?? "Not set"}
                </span>
              </div>
            </div>
          </div>
          <div className="col-auto profile_card_start_col">
            <div className="px-1">
              <span className="profile_card_details_label profile_card_details_label_noraml mb-2">
                Start
              </span>
              <div className="pt-1">
                <i className="fas fa-hourglass-start fa-sm-normal text_orange pe-2"></i>
                <span className="profile_card_details_value text-decoration-underline">
                  {actualStartDateTime ?? "NA"}
                </span>
              </div>
            </div>
          </div>
          <div className="col-auto profile_card_end_col">
            <div className="px-1">
              <span className="profile_card_details_label profile_card_details_label_noraml mb-2">
                End
              </span>
              <div className="pt-1">
                <i className="fas fa-hourglass-end fa-sm-normal text_orange pe-2"></i>
                <span className="profile_card_details_value text-decoration-underline">
                  {actualEndDateTime ?? "NA"}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const actualDurationDisplayText = getTimeDifferenceInUserReadableText(
      actualStartDate,
      actualEndDate,
      serviceTransactionRow.ActualDuration
    );

    return (
      <div className="w-100 p-3">
        <div className="row g-2 g-md-3 align-items-center flex-xxl-nowrap">
          <div className="col-auto">
            <UserProfilePhoto
              uid={serviceTransactionRow.RelatedToUID}
              relatedToType={serviceTransactionRow.RelatedToType}
              canEdit={false}
              profileImgCss={"profileImg-container-md"}
              title={serviceTransactionRow.RelatedTo ?? "Not Set"}
              iconSize="56"
              fontSize="24"
              emptyAvatarType="Initial"
            />
          </div>
          <div className="col">
            <div>
              <span
                className={`header-text ecllipseFirstLine${isNotNullAndUndefined(serviceTransactionRow.RelatedToUID) ? " pointer" : ""
                  }`}
                onClick={() => {
                  if (isNotNullAndUndefined(serviceTransactionRow.RelatedToUID)) {
                    navigate(
                      `/RAF/View/care_recipient/${serviceTransactionRow.RelatedToUID}`
                    );
                  }
                }}
              >
                {serviceTransactionRow.RelatedTo ?? "Not Set"}
              </span>
            </div>
            <div>
              <span className="header-text-sm">{`${taskMonthValue} ${taskDateValue},`}</span>
              <span className="header-text-sm-light ps-1">{`${plannedStartDateTime} - ${plannedEndDateTime}`}</span>
            </div>
          </div>
          <div className={`col-xxl-5 d-none d-xxl-block`}>
            {careTaskSectionAssigneeStartEndContent(
              actualStartDateTime,
              actualEndDateTime
            )}
          </div>
        </div>
      </div>
    );
  }

  const serviceTransactionUIDs = allRelatedServiceTransactions.map(
    (transaction) => transaction.UID
  );
  const filteredCareShiftLogs = careShiftLogs.filter((log) =>
    serviceTransactionUIDs.includes(log.taskuid)
  );

  return (
    <div className="row g-0 gy-3">
      {isNotEmptyArray(allRelatedServiceTransactions) ? (
        allRelatedServiceTransactions.map((serviceTransaction) => {
          const careShiftLogList = isNotEmptyArray(filteredCareShiftLogs) ?
            filteredCareShiftLogs.filter(
              (x) => x.taskuid === serviceTransaction.UID
            ) : null;

          return (
            <div className="col-12" key={serviceTransaction.UID}>
              <CustomCardWidget
                cardClassName="overflow-hidden shadow-sm"
                cardContentClassName="ps-3 p-0"
                headerTemplate={careTaskSectionCustomHeader(
                  serviceTransaction
                )}
                showCardSeparator
              >
                {isNotEmptyArray(careShiftLogList) && (
                  <div>
                    <ul
                      className={
                        "timeline__ul timeline__container timeline__ul__active"
                      }
                    >
                      {isNotEmptyArray(filteredCareShiftLogs) &&
                        filteredCareShiftLogs
                          .sort((a, b) => {
                            if (a.title === ShiftActivityTitle.StartedShift)
                              return -1;
                            if (b.title === ShiftActivityTitle.StartedShift)
                              return 1;
                            if (a.title === ShiftActivityTitle.CompletedShift)
                              return 1;
                            if (b.title === ShiftActivityTitle.CompletedShift)
                              return -1;
                            return 0;
                          })
                          .map((item, index) => {
                            if (item.taskuid === serviceTransaction.UID) {
                              return (
                                <li
                                  key={item.UID}
                                  className={
                                    item.status === RAFActionStatus.Done ||
                                      item.status === RAFActionStatus.Completed ||
                                      item.status === RAFActionStatus.Resolved ||
                                      item.Status === RAFActionStatus.Submitted ||
                                      item.Status === RAFActionStatus.Approved ||
                                      item.Status === RAFActionStatus.Rejected
                                      ? "timeline__li timeline__li__completed"
                                      : "timeline__li"
                                  }
                                >
                                  <CareShiftLogSection
                                    key={item.UID}
                                    //careEventRow={careEventRow}
                                    careShiftLogUID={item.UID}
                                    careShiftLogRow={item}
                                    careRecipientUID={item.care_recipientuid}
                                    onSave={refresh}
                                    showRelatedSections={false}
                                    IsCollapsed={false}
                                    allForms={allForms}
                                    allowEdit={false}
                                    allowDelete={false}
                                    isReporting={true}
                                  />
                                </li>
                              );
                            }
                          })}
                    </ul>
                  </div>
                )}
              </CustomCardWidget>
            </div>
          );
        })
      ) : (
        <RAFEmptyState
          body="Try adjusting your search or changing your filter to find what you're looking for!"
          image={nodata_es}
          title="We're sorry. We cannot find any matches for your search criteria"
        ></RAFEmptyState>
      )}
    </div>
  );
}

export default React.memo(CareSupportLogActivities);
