import { SidebarComponent } from "@syncfusion/ej2-react-navigations";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import {
  IsNotNullOrWhiteSpace,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import {
  CareEsioEntity,
  CareEsioTab,
} from "../../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  RAFButtonConstant,
  RAFShiftActionStatus,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import CareRecipientProfileInformation from "../../../CareESIO/CareRecipient/Details/CareRecipientProfileInformation";
import {
  atomSelectedRecipientRightContent,
  getClientByUID,
} from "../../../CareESIO/CareRecipientHelper";
import CareTaskListDetails from "../../../CareESIO/CareTask/CareTaskListDetails";
import RAFDetailsPageHeading from "../../../Common/Details/RAFDetailsPageHeading";
import {
  RAFServiceTransactionType,
  RetrieveServiceTransactionById,
  getServiceTransactionCollectionNameFromStorage,
} from "../ServiceTransactionHelper";
import { ServiceTransactionRow } from "../ServiceTransactionRow";
import ServiceTransactionProfileInfoHeader from "./ServiceTransactionProfileInfoHeader";

interface IProps {
  scheduleType: "Roster" | "Bookings";
}

interface IState {
  isLoading: boolean;
  careRecipientRow: any;
  selectedServiceTransactionRow: ServiceTransactionRow;
  noContent: boolean;
}

function ServiceTransactionDetailsPage({
  scheduleType,
  ...props
}: PropsWithChildren<IProps>) {
  const params = useParams();
  const careShiftUID = params.id;
  const careRecipientModule = CareEsioEntity.CareRecipient.EntityName;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      careRecipientRow: null,
      selectedServiceTransactionRow: null,
      noContent: false,
    }
  );

  const [selectedRecipientRightContent, setSelectedRecipientRightContent] =
    useRecoilState(atomSelectedRecipientRightContent(careRecipientModule));
  // const currentView: RAFTreeViewMenu = useRecoilValue(
  //     atomSelectedViewState(RAFEntityName.ServiceTransaction)
  // );

  // const currentViewName = isNotNullAndUndefined(currentView) ?
  //     currentView.Name === 'my_shift' ? 'my_shift' : currentView.Name === 'all_shift' ? 'all_shift' : null : BrowserIsDevice ? 'my_shift' : null;

  // const currentViewName = scheduleType === 'Roster' ? 'all_shift' : scheduleType === 'Bookings' ? 'my_shift' : null;

  const locationData = useLocation();
  let navigate = useNavigate();

  const currentViewName =
    isNotNullAndUndefined(locationData) &&
      isNotNullAndUndefined(locationData.state) &&
      isNotNullAndUndefined(locationData.state.currentViewName)
      ? locationData.state.currentViewName
      : "all_shift";
  const taskStatus = [
    RAFShiftActionStatus.Scheduled,
    RAFShiftActionStatus.InProgress,
    RAFShiftActionStatus.Completed,
    RAFShiftActionStatus.Unassigned,
  ];

  let sidebarShiftLeftObj = useRef<SidebarComponent>(null);

  useEffect(() => {
    setSelectedRecipientRightContent(null);
    refresh();
  }, [careShiftUID]);

  const refresh = async () => {
    setState({ isLoading: true });
    const selectedServiceTransactionRow = await RetrieveServiceTransactionById(
      careShiftUID
    );
    if (
      isNotNullAndUndefined(selectedServiceTransactionRow) &&
      isNotNullAndUndefined(selectedServiceTransactionRow.UID)
    ) {
      let careRecipientRow = null;
      if (isNotNullAndUndefined(selectedServiceTransactionRow.RelatedToUID)) {
        careRecipientRow = await getClientByUID(
          selectedServiceTransactionRow.RelatedToUID
        );
      }
      setState({
        selectedServiceTransactionRow,
        careRecipientRow,
        isLoading: false,
        noContent: false,
      });
    } else {
      setState({ noContent: true, isLoading: false });
    }
  };

  const relatedClientUpdated = async () => {
    setState({ isLoading: true });
    const selectedServiceTransactionRow = await RetrieveServiceTransactionById(
      careShiftUID
    );
    if (
      isNotNullAndUndefined(selectedServiceTransactionRow) &&
      isNotNullAndUndefined(selectedServiceTransactionRow.UID)
    ) {
      let careRecipientRow = null;
      if (isNotNullAndUndefined(selectedServiceTransactionRow.RelatedToUID)) {
        careRecipientRow = await getClientByUID(
          selectedServiceTransactionRow.RelatedToUID
        );
      }
      setState({
        selectedServiceTransactionRow,
        careRecipientRow,
        isLoading: false,
        noContent: false,
      });
    } else {
      setState({ noContent: true, isLoading: false });
    }
  };

  async function checkScrollable(text?) {
    const div = await document.querySelector("#raf_tab_header");
    const leftButton = await document.getElementById(
      "btn_raf_tab_header_left_scroll_iconBtn"
    );
    const rightButton = await document.getElementById(
      "btn_raf_tab_header_right_scroll_iconBtn"
    );

    if (div && leftButton && rightButton) {
      // Check if the div is scrollable
      if (div.scrollWidth > div.clientWidth) {
        // The div is scrollable, show the arrow buttons
        leftButton.style.display = "block";
        rightButton.style.display = "block";

        // Add click event listeners to the arrow buttons
        leftButton.addEventListener("click", () => {
          // Scroll the div to the left
          div.scrollTo({
            left: div.scrollLeft - (div.clientWidth - 100),
            behavior: "smooth",
          });
        });

        rightButton.addEventListener("click", () => {
          // Scroll the div to the right
          div.scrollTo({
            left: div.scrollLeft + (div.clientWidth - 100),
            behavior: "smooth",
          });
        });
      } else {
        // The div is not scrollable, hide the arrow buttons
        leftButton.style.display = "none";
        rightButton.style.display = "none";
      }

      // Stop observing the DOM changes
      observer.disconnect();
    }
  }

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(checkScrollable);

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  function onCreate() {
    if (
      isNotNullAndUndefined(sidebarShiftLeftObj) &&
      isNotNullAndUndefined(sidebarShiftLeftObj.current)
    ) {
      sidebarShiftLeftObj.current.element.style.visibility = "";
      if (!BrowserIsDevice) {
        sidebarShiftLeftObj.current.show();
      } else {
        sidebarShiftLeftObj.current.hide();
      }
    }
  }

  const toggleLeftSection = (isOpen?: boolean) => {
    let client_details_collapse_btn = document.getElementById(
      "client_details_collapse_btn"
    );
    if (
      isNotNullAndUndefined(sidebarShiftLeftObj) &&
      isNotNullAndUndefined(sidebarShiftLeftObj.current)
    ) {
      if (isOpen === true) {
        sidebarShiftLeftObj.current.show();
        if (IsNotNullOrWhiteSpace(client_details_collapse_btn)) {
          client_details_collapse_btn.classList.add("hidden");
        }
      } else if (isOpen === false) {
        sidebarShiftLeftObj.current.hide();
        if (IsNotNullOrWhiteSpace(client_details_collapse_btn)) {
          client_details_collapse_btn.classList.remove("hidden");
        }
      } else {
        sidebarShiftLeftObj.current.toggle();
        if (IsNotNullOrWhiteSpace(client_details_collapse_btn)) {
          client_details_collapse_btn.classList.toggle("hidden");
        }
      }
    }
  };

  const profileInfoDivHeader = (shiftRafObject, rafObject) => {
    return (
      <div className="h-100 bg-white">
        <div className={`section__container`}>
          <div className={`section__div customScrollBar`}>
            <CareRecipientProfileInformation
              careRecipientUID={shiftRafObject.RelatedToUID}
              selectedTabItem={{
                text: "Overview",
                value: "CareInfo",
              }}
              isdashboard={true}
            />
          </div>
          {/* <div className={BrowserIsDevice ? "pt-3" : "p-3"}>
                        <div className="d-flex align-items-center">
                            <RAFButtonComponent btnContent={`View ${rafObject.first_name}'s details`} isPrimary className="w-100 me-2"
                                onClick={() => setSelectedRecipientRightContent(CareEsioEntity.CareRecipient.EntityName)}
                            ></RAFButtonComponent>
                            <RAFButtonComponent btnContent={`Go to ${rafObject.first_name}'s profile`} isPrimary className="w-100"
                                onClick={() => navigate(`/RAF/View/care_recipient/${shiftRafObject.RelatedToUID}`)}
                            ></RAFButtonComponent>
                        </div>
                    </div> */}
        </div>
      </div>
    );
  };

  const getLeftHeaderBreadcrumb = () => {
    const { selectedServiceTransactionRow } = state;
    let selectedRecipientRightContentText;

    if (
      selectedRecipientRightContent === CareEsioEntity.CareRecipient.EntityName
    ) {
      // selectedRecipientRightContentText = "About Me"
      selectedRecipientRightContentText = "Additional info";
    } else if (
      selectedRecipientRightContent ===
      CareEsioEntity.CareServiceContract.EntityName
    ) {
      selectedRecipientRightContentText = "Additional info";
      // selectedRecipientRightContentText = CareEsioEntity.CareServiceContract.CollectionName
    } else if (
      selectedRecipientRightContent === CareEsioEntity.CarePlan.EntityName
    ) {
      selectedRecipientRightContentText = "Additional info";
      // selectedRecipientRightContentText = "Care Details"
    } else if (selectedRecipientRightContent === RAFEntityName.ContentLibrary) {
      selectedRecipientRightContentText = "Additional info";
      // selectedRecipientRightContentText = "Documents"
    } else if (selectedRecipientRightContent === CareEsioTab.ShiftLog.Value) {
      selectedRecipientRightContentText = "Shift Details";
    }

    const viewDisplayTitle =
      selectedServiceTransactionRow.Type === RAFServiceTransactionType.Booking
        ? "Booking"
        : currentViewName === "all_shift"
          ? "Roster"
          : currentViewName === "my_shift"
            ? `My ${getServiceTransactionCollectionNameFromStorage()}`
            : null;

    return (
      <div className={`body_3 p-3${BrowserIsDevice ? "" : " pb-0"}`}>
        <div className="row gx-2">
          <div className="col-auto">
            <span
              className="body_3 medium content_neutral_dark pointer"
              onClick={() => navigate("/")}
            >
              Home
            </span>
          </div>
          <div className="col-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M3.71289 8.3002L6.42956 5.58353C6.75039 5.2627 6.75039 4.7377 6.42956 4.41686L3.71289 1.7002"
                stroke="#0C0C0E"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="col-auto">
            <span
              className="body_3 medium content_neutral_dark pointer"
              onClick={() => navigate(-1)}
            >
              {viewDisplayTitle}
            </span>
          </div>
          <div className="col-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M3.71289 8.3002L6.42956 5.58353C6.75039 5.2627 6.75039 4.7377 6.42956 4.41686L3.71289 1.7002"
                stroke="#0C0C0E"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="col-auto">
            <span
              className={`body_3${selectedRecipientRightContent !== null
                  ? " medium content_neutral_dark pointer"
                  : ""
                }`}
              onClick={() => setSelectedRecipientRightContent(null)}
            >
              {`${viewDisplayTitle} Details`}
              {/* {shiftRafObject.Title} */}
            </span>
          </div>
          {selectedRecipientRightContent !== null ? (
            <>
              <div className="col-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    d="M3.71289 8.3002L6.42956 5.58353C6.75039 5.2627 6.75039 4.7377 6.42956 4.41686L3.71289 1.7002"
                    stroke="#0C0C0E"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="col-auto">
                <span className="body_3">
                  {selectedRecipientRightContentText}
                </span>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  if (state.isLoading === false) {
    if (state.noContent === false) {
      const { selectedServiceTransactionRow } = state;

      return (
        <div className="detailspage-flex-container body_bg_light">
          <div id="detailSecondHeaderDiv" className="detailspage-second-header">
            <RAFDetailsPageHeading
              headerValue={
                selectedServiceTransactionRow.Type ===
                  RAFServiceTransactionType.Booking
                  ? "Booking Details"
                  : "Shift Details"
              }
              customHeader={
                <div>
                  <div className="header_5 semi_bold">
                    <span>
                      {selectedServiceTransactionRow.Type ===
                        RAFServiceTransactionType.Booking
                        ? "Booking Details"
                        : "Shift Details"}
                    </span>
                  </div>
                </div>
              }
              // secondaryHeaderValue={"Client"}
              // detailsBtn={getRightHeaderBtn(careRecipient)}
              moduleName={RAFEntityName.ServiceTransaction}
              showToggleBtn={false}
              {...(BrowserIsDevice
                ? {
                  onBackArrowClicked: () => {
                    if (selectedRecipientRightContent === null) {
                      navigate(-1);
                      // serviceTransactionTabDetails.current.onDetailsBackClicked();
                    } else {
                      setSelectedRecipientRightContent(null);
                    }
                  },
                }
                : {})}
            />
          </div>
          {getLeftHeaderBreadcrumb()}
          <div
            className={
              "detailspage-flex-content p-md-3 p-sm-2 pt-0 position-relative"
            }
          >
            <div
              className="client_details_collapse_btn hidden"
              id="client_details_collapse_btn"
            >
              <RAFButtonComponent
                className="e-round e-outline bg-white"
                action={RAFButtonConstant.Next}
                iconBtn
                onClick={toggleLeftSection}
              ></RAFButtonComponent>
            </div>
            <div className={"h-100 w-100"} id="clientDetailsOuterContent">
              {BrowserIsDevice ? (
                ""
              ) : (
                // selectedRecipientRightContent === null && (
                //                                                         (isNullOrUndefined(selectedRecipientRightContent) &&
                //                                                             <div className="mb-3">
                //                                                                 {profileInfoDivHeader(shiftRafObject)}
                //                                                                 {/* // <SidebarComponent
                //   //   id="schedule-filter-sidebar"
                //   //   ref={(Sidebar) => (sidebarShiftLeftObj = Sidebar)}
                //   //   enableGestures={false}
                //   //   width={`85%`}
                //   //   created={onCreate}
                //   //   type={"Over"}
                //   // >
                //   //   <div
                //   //     className="client_details_expanded_btn"
                //   //     id="client_details_expanded_btn"
                //   //   >
                //   //     <RAFButtonComponent
                //   //       className="e-round bg-white border-0"
                //   //       action={RAFButtonConstant.Previous} iconBtn
                //   //       onClick={toggleLeftSection}
                //   //     ></RAFButtonComponent>
                //   //   </div>
                //   //   <div className="section__container">
                //   //     {profileInfoDivHeader()}
                //   //   </div>
                //   // </SidebarComponent> */}
                //                                                             </div>)
                // )
                <SidebarComponent
                  id="schedule-filter-sidebar"
                  enableGestures={false}
                  isOpen={true}
                  target={"#clientDetailsOuterContent"}
                  ref={sidebarShiftLeftObj}
                  width={370}
                  created={onCreate}
                  type={"Auto"}
                  style={{
                    visibility: "hidden",
                  }}
                  className={"pe-3 bg-transparent"}
                  change={() => checkScrollable("onchange")}
                >
                  <div
                    className="client_details_expanded_btn"
                    id="client_details_expanded_btn"
                  >
                    <RAFButtonComponent
                      className="e-round bg-white border-0"
                      action={RAFButtonConstant.Previous}
                      iconBtn
                      onClick={toggleLeftSection}
                    ></RAFButtonComponent>
                  </div>
                  {/* {profileInfoDivHeader(shiftRafObject, rafObject)} */}
                  <ServiceTransactionProfileInfoHeader
                    careRecipientUID={
                      state.selectedServiceTransactionRow.RelatedToUID
                    }
                    displayType="ProfileInfoHeader"
                    careRecipientRow={state.careRecipientRow}
                    careRecipientModule={careRecipientModule}
                  />
                </SidebarComponent>
              )}
              <div className={BrowserIsDevice ? "" : "h-100"}>
                {isNotNullAndUndefined(selectedRecipientRightContent) &&
                  selectedRecipientRightContent !== CareEsioTab.ShiftLog.Value ? (
                  <ServiceTransactionProfileInfoHeader
                    careRecipientUID={
                      state.selectedServiceTransactionRow.RelatedToUID
                    }
                    displayType="About"
                    careRecipientRow={state.careRecipientRow}
                    careRecipientModule={careRecipientModule}
                  />
                ) : (
                  <CustomCardWidget
                    cardClassName="border-0 h-100"
                    cardContentClassName={`h-100`}
                    removeContentPadding
                  >
                    <CareTaskListDetails
                      // careRecipientUID={careRecipient.RelatedToUID}
                      // careRecipientName={careRecipient.RelatedTo}
                      allowAdd={true}
                      // scheduleFilterRow={
                      //   isNotNullAndUndefined(state.selectedScheduleObjectFilter)
                      //     ? state.selectedScheduleObjectFilter
                      //     : state.scheduleFilterRow
                      // }
                      isDashBoard={true}
                      taskStatus={taskStatus}
                      currentViewName={currentViewName}
                      // emptyStateDisplayStyle={props.emptyStateDisplayStyle}
                      // serviceTransactionAddBtn={getServiceTransactionAddBtn()}
                      // onUpdateRelatedServiceTransactionItem={
                      //   props.onUpdateRelatedServiceTransactionItem
                      // }
                      selectedServiceTransactionId={careShiftUID}
                      selectedServiceTransactionRow={
                        state.selectedServiceTransactionRow
                      }
                      islistView={false}
                      relatedToType={careRecipientModule}
                      onDeleteServiceTransaction={relatedClientUpdated}
                      relatedClientUpdated={relatedClientUpdated}
                      careRecipientRow={state.careRecipientRow}
                    />
                  </CustomCardWidget>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-fluid px-0">
          <RAFDeletedRecordState
            title="This item is either deleted or You do not have sufficient privileges to view this item."
            showBackBtn
          />
        </div>
      );
    }
  } else {
    return (
      <div className="container-fluid px-0">
        <ACLoadingPanel loadingText="Preparing Data..." />
      </div>
    );
  }
}

export default React.memo(ServiceTransactionDetailsPage);
