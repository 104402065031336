import React, {
    PropsWithChildren,
    Reducer,
    useEffect,
    useReducer,
} from 'react';
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFEmptyState from '../../../../RAFComponents/Navigation/RAFEmptyState';
import {
    isNotEmptyArray,
    isNotNullAndUndefined,
    propertyOf
} from '../../../../RAFComponents/helpers/utils';
import { FormLibraryRow } from '../../../../RAFComponents/models/Common/FormLibraryRow';
import ACLoadingPanel from '../../../../components/shared/ACLoadingPanel';
import { CareEsioEntity } from '../../../../constants/CareESIO/CareEsioConstants';
import {
    RAFActionStatus,
    RAFButtonConstant
} from '../../../../constants/Common/Constants';
import { NavigateParamsProps } from '../../../../router';
import {
    ScheduleFilterRow,
    getAllServiceTransactionList
} from '../../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper';
import { ServiceTransactionRow } from '../../../ActiveContacts/ServiceTransaction/ServiceTransactionRow';
import CareSupportLogActivities from "../CareSupportLogActivities";
import {
    getSupportLogRelatedRecords
} from '../SupportLogHelper';

const defaultShiftStatus = [
    RAFActionStatus.Completed,
    RAFActionStatus.Approved,
    RAFActionStatus.Rejected,
    RAFActionStatus.Paid,
    //RAFActionStatus.Inprocess,
];

interface IProps {
    serviceTransactionUID: string;
    careRecipientUID: string;
    careRecipientName: string;
    allForms: FormLibraryRow[];

    scheduleFilterRow: ScheduleFilterRow;


    isDashBoard?: boolean;
    defaultServiceTransactionType?: string;
}

interface IState {
    isLoading: boolean;
    allRelatedItems: any[];
    allRelatedServiceTransactions: ServiceTransactionRow[];
    showExpandCollapseBtn: 'Expand' | 'Collapse';
    activeIndex: number;
}

function ServiceTransactionPreviousLog({
    ...props
}: PropsWithChildren<IProps & NavigateParamsProps>) {
    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isLoading: true,
            allRelatedItems: [],
            allRelatedServiceTransactions: [],
            showExpandCollapseBtn: 'Collapse',
            activeIndex: 0
        }
    );

    useEffect(() => {
        refresh();
    }, [props.careRecipientUID, props.scheduleFilterRow]);

    const refresh = async () => {
        setState({
            isLoading: true,
        });

        const sortQuery: string[] = [`${propertyOf<ServiceTransactionRow>('StartDate')} desc`];

        const [allRelatedItems, previousServiceTransactionLogItems] = await Promise.all([
            getSupportLogRelatedRecords(
                CareEsioEntity.CareShiftLog.EntityName,
                props.careRecipientUID,
                props.scheduleFilterRow
            ),
            getAllServiceTransactionList(
                props.careRecipientUID,
                0,
                0,
                null,
                props.scheduleFilterRow,
                null,
                defaultShiftStatus,
                null,
                props.defaultServiceTransactionType,
                sortQuery
            ),
        ]);

        const filteredRelatedItems = allRelatedItems;
        // if (props.selectedActivityFormType === CareEsioEntity.CareShiftLog.EntityName) {
        //   filteredRelatedItems = allRelatedItems;
        // } else {
        //   if (isNotEmptyArray(allRelatedItems)) {
        //     filteredRelatedItems = allRelatedItems.filter(
        //       (x) => x.log_type === props.selectedActivityFormType
        //     );
        //   }
        // }

        const allRelatedServiceTransactions: ServiceTransactionRow[] = (isNotEmptyArray(previousServiceTransactionLogItems) && isNotNullAndUndefined(props.serviceTransactionUID))
            ? previousServiceTransactionLogItems.filter(x => x.UID !== props.serviceTransactionUID) : previousServiceTransactionLogItems;

        setState({
            isLoading: false,
            allRelatedItems: filteredRelatedItems,
            allRelatedServiceTransactions,
            activeIndex: 0
        });
    };

    const getLogActivitiesContent = () => {
        if (state.isLoading === false) {
            const { activeIndex, allRelatedServiceTransactions } = state;

            const activeRelatedServiceTransaction = isNotEmptyArray(allRelatedServiceTransactions) ? allRelatedServiceTransactions[activeIndex] : null;

            const activeRelatedServiceTransactions = isNotNullAndUndefined(activeRelatedServiceTransaction) ? [activeRelatedServiceTransaction] : [];

            return (
                <div
                    className={'h-100 p-md-3 p-2'}
                    // className={props.isDashBoard ? 'h-100' : 'h-100 p-md-3 p-2'}
                    id='shift_realted_object_outerDiv'
                >
                    {isNotEmptyArray(state.allRelatedItems) &&
                        isNotEmptyArray(activeRelatedServiceTransactions) ? (
                        <CareSupportLogActivities
                            careShiftLogs={state.allRelatedItems}
                            allRelatedServiceTransactions={
                                activeRelatedServiceTransactions
                            }
                            allForms={props.allForms}
                            isDashBoard={props.isDashBoard}
                            careRecipientUID={props.careRecipientUID}
                            onSave={null}
                        />
                    ) : (
                        <RAFEmptyState
                            body="Try adjusting your search or changing your filter to find what you're looking for!"
                            title="We're sorry. We cannot find any matches for your search criteria"
                        />
                    )}
                </div>
            );
        } else {
            return (
                <div className='container-fluid px-0'>
                    <ACLoadingPanel loadingText='Loading...' />
                </div>
            );
        }
    };

    const onClickShowPreviousBtn = () => {
        const activeIndex = state.activeIndex - 1;
        setState({ activeIndex });
    };

    const onClickShowNextBtn = () => {
        const activeIndex = state.activeIndex + 1;
        setState({ activeIndex });
    };

    return (
        <div className='section__container bg-white'>
            <div className='section__primary__header raf_sm'>
                <div className='w-100'>
                    <div className='row gx-2 align-items-center'>
                        {isNotEmptyArray(state.allRelatedServiceTransactions) && (
                            <div className='col-auto'>
                                <span className="subtitle_1">
                                    {`Showing item ${state.activeIndex + 1} of ${state.allRelatedServiceTransactions.length}.`}
                                </span>
                            </div>
                        )}
                        <div className='col-auto me-auto'></div>
                        {isNotEmptyArray(state.allRelatedServiceTransactions) &&
                            isNotEmptyArray(state.allRelatedItems) && (
                                <>
                                    <div className='col-auto'>
                                        <RAFButtonComponent
                                            action={RAFButtonConstant.Previous}
                                            isPrimary
                                            className='position-relative e-outline'
                                            disabled={state.activeIndex <= 0}
                                            onClick={onClickShowPreviousBtn}
                                        />
                                    </div>
                                    <div className='col-auto'>
                                        <RAFButtonComponent
                                            action={RAFButtonConstant.Next}
                                            isPrimary
                                            className='position-relative e-outline'
                                            disabled={state.activeIndex >= state.allRelatedServiceTransactions.length - 1}
                                            onClick={onClickShowNextBtn}
                                        />
                                    </div>
                                </>
                            )}
                    </div>
                </div>
            </div>
            <div className='section__div section__div__maxHeight'>
                {getLogActivitiesContent()}
            </div>
        </div>
    );
}

export default React.memo(ServiceTransactionPreviousLog);
