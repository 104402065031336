import moment from "moment";
import * as R from "ramda";
import React, {
    PropsWithChildren,
    Reducer,
    useEffect,
    useMemo,
    useReducer
} from "react";
import { getAllFormsByCategoryTypes } from "../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import {
    isNotEmptyArray,
    isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../../RAFComponents/models/Common/FormLibraryRow";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { ServiceTransactionSupportLogPreviousLogPermissionConstants } from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import {
    DateRangeTypes,
    ScheduleFilterRow,
} from "../../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import { ShiftActivityTitle } from "../../CareShiftLog/CareShiftLogHelper";
import ServiceTransactionPreviousLog from "./ServiceTransactionPreviousLog";

const getDefaultSupportLogFilter = (
    entityDropdownItems: { Text: string; Value: string; }[],
    defaultShiftEndDate: Date
) => {
    const endDate = isNotNullAndUndefined(defaultShiftEndDate) ? new Date(defaultShiftEndDate) : new Date();
    const shiftEndDate: Date = new Date(endDate.toDateString());

    const last30Days = moment(shiftEndDate).subtract(30, "days").toDate();

    const endDateTimeValue = new Date(shiftEndDate.setHours(23, 59, 59, 999));

    const defaultSupportLogFilter: ScheduleFilterRow = {
        ActivityFormType: isNotEmptyArray(entityDropdownItems)
            ? entityDropdownItems.map((x) => x.Value)
            : null, // CareEsioEntity.CareShiftLog.EntityName,
        RelatedToType: null,
        Status: "AllStatus",
        DateRange: DateRangeTypes.Custom,

        SelectedDate: shiftEndDate,

        StartDate: last30Days,
        StartDateTimeValue: last30Days,

        EndDate: endDateTimeValue,
        EndDateTimeValue: endDateTimeValue,
    };
    return defaultSupportLogFilter;

};

interface IProps {
    serviceTransactionUID: string;
    careRecipientUID: string;
    careRecipientName: string;
    isDashBoard: boolean;
    defaultShiftEndDate: Date;
    defaultServiceTransactionType: string;
}

interface IState {
    isLoading: boolean;
    allForms: FormLibraryRow[];
    supportLogAppliedFilterValue: ScheduleFilterRow;
    entityDropdownItems: { Text: string; Value: string; }[];
}

function ServiceTransactionPreviousLogPage({ ...props }: PropsWithChildren<IProps>) {
    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isLoading: true,
            allForms: [],
            supportLogAppliedFilterValue: null,
            entityDropdownItems: null,
        }
    );

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        setState({ isLoading: true });
        const allForms = await getAllFormsByCategoryTypes(
            [
                CareEsioEntity.CareShiftLog.DisplayName,
                CareEsioEntity.CareRecipientCQIRegister.DisplayName,
            ],
            CareEsioEntity.CareShiftLog.EntityName
        );

        const filteredForms = isNotEmptyArray(allForms)
            ? allForms.filter(
                (x) =>
                    x.Entity !== CareEsioEntity.ClientNote.EntityName &&
                    x.Entity !== CareEsioEntity.CareShiftActivity.EntityName
                //  x.Entity !== "shift_log_form" &&
                // x.Entity !== "suggestion_form" &&
                //x.Entity !== "concern_form"
            )
            : [];

        const entityDropdownItems: { Text: string; Value: string; }[] = [
            // {
            //     Text: "All Shifts",
            //     Value: CareEsioEntity.CareShiftLog.EntityName,
            // },
            {
                Text: ShiftActivityTitle.StartedShift,
                Value: ShiftActivityTitle.StartedShift,
            },
            {
                Text: "Activity",
                Value: CareEsioEntity.CareShiftActivity.EntityName,
            },
        ];

        if (isNotEmptyArray(filteredForms)) {
            filteredForms.map((x) => {
                entityDropdownItems.push({ Text: x.Title, Value: x.Entity });
            });
        }

        const supportLogAppliedFilterValue =
            getDefaultSupportLogFilter(entityDropdownItems, props.defaultShiftEndDate);

        setState({
            isLoading: false,
            entityDropdownItems,
            supportLogAppliedFilterValue,
            allForms,
        });
    };

    const getBodyContent = useMemo(() => {
        if (isNotNullAndUndefined(state.supportLogAppliedFilterValue)) {
            const scheduleFilterRow: ScheduleFilterRow = R.clone(
                state.supportLogAppliedFilterValue
            );

            return (
                <ServiceTransactionPreviousLog
                    serviceTransactionUID={props.serviceTransactionUID}
                    careRecipientName={props.careRecipientName}
                    careRecipientUID={props.careRecipientUID}
                    scheduleFilterRow={scheduleFilterRow}
                    allForms={state.allForms}
                    isDashBoard={props.isDashBoard}
                    defaultServiceTransactionType={props.defaultServiceTransactionType}
                />
            );
        } else {
            return <></>;
        }
    }, [state.supportLogAppliedFilterValue]);

    if (state.isLoading === false) {
        return (
            <RAFPermissionRender
                permissionName={ServiceTransactionSupportLogPreviousLogPermissionConstants.ServiceTransactionSupportLogPreviousLog}
                showDefaultNoPermissionContent
            >
                <div className="h-100" id="scheduleFilterAndContentContainer">
                    <div className="h-100">
                        {getBodyContent}
                    </div>
                </div>
            </RAFPermissionRender>
        );
    } else {
        return (
            <div className="container-fluid px-0">
                <ACLoadingPanel loadingText="Preparing Data..." />
            </div>
        );
    }
}

export default React.memo(ServiceTransactionPreviousLogPage);
